import { FormattedMessage, useIntl } from "react-intl";
import { ConfirmationDialog } from "../../components/dialogs/confirmation-dialog";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { FormControl, TextField } from "@mui/material";
import { loginValidators } from "../../services/login-validators";
import { useMutation } from "@apollo/client";
import { CONFIRM_RESET_USER_PASSWORD } from "../../apollo-client/mutations";
import { showErrorToast, showSuccessToast } from "../../toast/toast-service";
import { StringParam, useQueryParam } from "use-query-params";
import { useHistory } from "react-router-dom";

export const ResetPwDialog = ({ isOpen, toggleOpen }) => {
  const [pw1, setPw1] = useState("");
  const [pw2, setPw2] = useState("");
  const [inputError, setInputError] = useState(false);

  const pw1Ref = useRef(null);
  const pw2Ref = useRef(null);

  const [token] = useQueryParam("token", StringParam);

  const history = useHistory();
  const intl = useIntl();

  const [confirmResetUserPassword, { error }] = useMutation(
    CONFIRM_RESET_USER_PASSWORD
  );

  const handlePW1InputChange = (event) => {
    const pw1Input = event.target.value;

    setPw1(pw1Input);
    setInputError(false);
  };

  const handlePW2InputChange = (event) => {
    const pw2Input = event.target.value;

    setPw2(pw2Input);
    setInputError(false);
  };

  const sendResetPW = async () => {
    if (!token) {
      showErrorToast(
        <FormattedMessage id="landing-page.reset-password.error.no-token" />
      );
      setInputError(true);
      return;
    }

    if (pw1 !== pw2) {
      setInputError(() => (
        <FormattedMessage id="landing-page.reset-password.error.same-pws" />
      ));
      pw1Ref.current.focus();
      return;
    }

    try {
      loginValidators.validatePassword(pw1, intl);
    } catch (err) {
      setInputError(err.message);
      pw2Ref.current.focus();
      return;
    }

    try {
      await confirmResetUserPassword({
        variables: {
          newPassword: pw1,
          token: token,
        },
      });
      showSuccessToast(
        <FormattedMessage id="landing-page.reset-password.success" />
      );
      history.push("/");
    } catch (err) {
      showErrorToast(error?.message);
    }
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      toggle={toggleOpen}
      action={sendResetPW}
      title={<FormattedMessage id="landing-page.reset-password.title" />}
      content={
        <FormControl>
          <InputTextField
            label={<FormattedMessage id={"general.new-password"} />}
            size="small"
            variant="outlined"
            type="password"
            inputRef={pw1Ref}
            value={pw1}
            error={inputError}
            onChange={handlePW1InputChange}
          />
          <InputTextField
            label={<FormattedMessage id={"general.new-password-repeat"} />}
            size="small"
            variant="outlined"
            type="password"
            inputRef={pw2Ref}
            value={pw2}
            error={inputError}
            helperText={inputError}
            onChange={handlePW2InputChange}
          />
        </FormControl>
      }
    />
  );
};

const InputTextField = styled(TextField)`
  && {
    margin-top: 10px;
    font-size: 10px;
    width: 100%;
  }
`;
