import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  margin-top: 10px;
`;

export const NoResults = () => {
  return <Container>Keine Treffer</Container>;
};
