import {
  Checkbox,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React, { useState } from "react";
import styled from "styled-components";
import Autocomplete from "@mui/material/Autocomplete";
import { FormattedMessage, useIntl } from "react-intl";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_DEGREE_PROGRAMS,
  GET_UNIVERSITIES,
} from "../../../apollo-client/queries";
import { handlePressEnter } from "../../../helpers";
import { Link } from "react-router-dom";

export const RegisterFields = ({
  formValues,
  setFormValues,
  formValueErrors,
  setFormValueErrors,
  refs,
  register,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [universityOptions, setUniversityOptions] = useState([]);
  const [degreeProgramOptions, setDegreeProgramOptions] = useState([]);
  const [, setCanFormBeSent] = useState(true);

  const intl = useIntl();

  const { emailRef, nameRef, passwordRef, universityRef, degreeProgramRef } =
    refs;

  const { data: universitiesData, loading: loadingUniversites } = useQuery(
    GET_UNIVERSITIES,
    {
      variables: { limit: 999 },
      onCompleted: (data) => {
        setUniversityOptions(data.universities);
      },
      fetchPolicy: "network-only",
    }
  );

  const [
    getDegreePrograms,
    { data: degreeProgramsData, loading: loadingDegreePrograms },
  ] = useLazyQuery(GET_DEGREE_PROGRAMS, {
    variables: {
      university: formValues.university,
      limit: 999,
    },
    onCompleted: (data) => {
      setDegreeProgramOptions(data.degreePrograms);
    },
    fetchPolicy: "cache-and-network",
  });

  const resetLoginFormErrors = () => {
    setFormValueErrors({
      email: false,
      password: false,
      name: false,
      university: false,
      degreeProgram: false,
    });
    setCanFormBeSent(true);
  };

  const handleEmailInputChange = (event) => {
    const emailInput = event.target.value;

    setFormValues({
      ...formValues,
      email: emailInput,
    });

    resetLoginFormErrors();
  };

  const handleNameInputChange = (event) => {
    const nameInput = event.target.value;

    setFormValues({
      ...formValues,
      name: nameInput,
    });

    resetLoginFormErrors();
  };

  const handleUniversityInputChange = async (event, value) => {
    const universityInput = value && value.name;

    setFormValues({
      ...formValues,
      university: universityInput,
    });
    resetLoginFormErrors();
    await getDegreePrograms();
  };

  const handleDegreeProgramInputChange = async (event, value) => {
    const selectedDegreeProgram = value && value.name;

    setFormValues({
      ...formValues,
      degreeProgram: selectedDegreeProgram,
    });

    resetLoginFormErrors();
    await getDegreePrograms();
  };

  const handlePasswordInputChange = (event) => {
    const passportInputText = event.target.value;

    setFormValues({
      ...formValues,
      password: passportInputText,
    });
    resetLoginFormErrors();
  };

  const handleAGBCheckboxChange = (event) => {
    const isCheckboxChecked = event.target.checked;

    setFormValues({
      ...formValues,
      areAGBsChecked: isCheckboxChecked,
    });
    resetLoginFormErrors();
  };

  return (
    <FormInputs>
      <InputTextField
        label="E-mail"
        size="small"
        variant="outlined"
        inputRef={emailRef}
        type="email"
        value={formValues.email}
        error={Boolean(formValueErrors.email)}
        helperText={formValueErrors.email}
        onChange={handleEmailInputChange}
        onKeyDown={(event) => handlePressEnter(event, () => register())}
      />
      <InputTextField
        label="Name"
        size="small"
        variant="outlined"
        inputRef={nameRef}
        type=""
        value={formValues.name}
        error={Boolean(formValueErrors.name)}
        helperText={formValueErrors.name}
        onChange={handleNameInputChange}
        onKeyDown={(event) => handlePressEnter(event, () => register())}
      />
      <InputAutocomplete
        id="unis-autocomplete"
        options={universityOptions}
        loading={loadingUniversites}
        onChange={handleUniversityInputChange}
        onKeyDown={(event) => handlePressEnter(event, () => register())}
        size={"small"}
        type="email"
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={intl.formatMessage({ id: "general.university" })}
            inputRef={universityRef}
            variant="outlined"
            error={Boolean(formValueErrors.university)}
            helperText={formValueErrors.university}
          />
        )}
      />
      <InputAutocomplete
        id="degree-program-autocomplete"
        options={degreeProgramOptions}
        loading={loadingDegreePrograms}
        onChange={handleDegreeProgramInputChange}
        onKeyDown={(event) => handlePressEnter(event, () => register())}
        size={"small"}
        type="degree-program"
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={intl.formatMessage({
              id: "registration.your-degree-program",
            })}
            variant="outlined"
            inputRef={degreeProgramRef}
            error={Boolean(formValueErrors.degreeProgram)}
            helperText={formValueErrors.degreeProgram}
          />
        )}
      />
      <InputTextField
        label={intl.formatMessage({
          id: "general.placeholder-password",
        })}
        variant="outlined"
        size="small"
        inputRef={passwordRef}
        value={formValues.password}
        error={Boolean(formValueErrors.password)}
        helperText={formValueErrors.password}
        type={showPassword ? "text" : "password"}
        onChange={handlePasswordInputChange}
        onKeyDown={(event) => handlePressEnter(event, () => register())}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                size={"small"}
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                onMouseDown={() => {}}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <CheckboxLabelWrapper>
        <InputCheckbox
          // label={"I agree to the terms and conditions"}
          label={{ inputProps: { "aria-label": "Checkbox demo" } }}
          required
          size="small"
          variant="outlined"
          // inputRef={nameRef}
          checked={Boolean(formValues.areAGBsChecked)}
          // error={Boolean(formValueErrors.areAGBsChecked)}
          // helperText={formValueErrors.areAGBsChecked}
          onChange={handleAGBCheckboxChange}
        />
        <CheckboxLabel>
          <FormattedMessage id={"registration.AGBs-checked.1"} />
          <StyledLink to={"/AGBs"}>
            <FormattedMessage id={"registration.AGBs-checked.2"} />
          </StyledLink>
          <FormattedMessage id={"registration.AGBs-checked.3"} />
          <StyledLink to={"/AGBs"}>
            <FormattedMessage id={"registration.AGBs-checked.4"} />
          </StyledLink>
          <FormattedMessage id={"registration.AGBs-checked.5"} />
          <CheckboxLabelErrorWrapper>
            {formValueErrors.areAGBsChecked && (
              <FormHelperText>
                <FormattedMessage id="registration.AGBs-checked.error" />
              </FormHelperText>
            )}
          </CheckboxLabelErrorWrapper>
        </CheckboxLabel>
      </CheckboxLabelWrapper>
    </FormInputs>
  );
};

const CheckboxLabelWrapper = styled.div`
  display: flex;
  //align-items: center;
  justify-content: start;
  flex-direction: row;
  //width: 100%;
  //background-color: red;
  width: 85%;
`;

const CheckboxLabelErrorWrapper = styled.div`
  display: flex;
  //align-items: center;
  //justify-content: start;
  flex-direction: column;
  //width: 100%;
  //background-color: red;
  width: 100%;
`;

const InputCheckbox = styled(Checkbox)`
  && {
    //margin-top: 10px;
    font-size: 10px;
    //width: 85%;
  }
`;

const CheckboxLabel = styled.p``;

const FormInputs = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InputAutocomplete = styled(Autocomplete)`
  && {
    margin-top: 10px;
    font-size: 10px;
    width: 85%;
  }
`;

const InputTextField = styled(TextField)`
  && {
    margin-top: 10px;
    font-size: 10px;
    width: 85%;
  }
`;

const StyledLink = styled(Link)`
  display: inline;
  text-decoration: none;
  color: #f86b6b;
`;
