import { FormattedMessage, useIntl } from "react-intl";
import { ConfirmationDialog } from "../../components/dialogs/confirmation-dialog";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { FormControl, TextField } from "@mui/material";
import { loginValidators } from "../../services/login-validators";
import { useMutation } from "@apollo/client";
import { RESET_USER_PASSWORD } from "../../apollo-client/mutations";
import { showErrorToast, showSuccessToast } from "../../toast/toast-service";
import { getGraphQLErrorMessage } from "../../helpers/network-helpers";

export const ForgotPwDialog = ({ isOpen, toggleOpen }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const intl = useIntl();
  const emailRef = useRef(null);

  const [resetPwMutation, { data: mutationData, error }] =
    useMutation(RESET_USER_PASSWORD);

  const handleEmailInputChange = (event) => {
    const emailInput = event.target.value;

    setEmail(emailInput);
    setEmailError(false);
  };

  const sendForgotMail = async () => {
    try {
      loginValidators.validateEmail(email, intl);
    } catch (err) {
      setEmailError(err.message);
      emailRef.current.focus();
      return;
    }

    try {
      const res = await resetPwMutation({
        variables: {
          email,
        },
      });

      if (res?.errors) {
        showErrorToast(getGraphQLErrorMessage(res.errors));
      } else {
        showSuccessToast(
          <FormattedMessage id="landing-page.forgot-password.email-was-send" />
        );
      }
    } catch (err) {
      switch (getGraphQLErrorMessage(err)) {
        case "user not found":
          showErrorToast(
            <FormattedMessage id="landing-page.forgot-password.user-not-found" />
          );
          break;
        default:
          showErrorToast(getGraphQLErrorMessage(err));
      }
    }
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      toggle={toggleOpen}
      action={sendForgotMail}
      title={<FormattedMessage id="landing-page.forgot-password.title" />}
      content={
        <>
          {mutationData && (
            <WasEmailSendText>
              <FormattedMessage id="landing-page.forgot-password.email-was-send" />
            </WasEmailSendText>
          )}
          {!mutationData && (
            <FormattedMessage id="landing-page.forgot-password.description" />
          )}

          <InputTextField
            label="E-mail"
            type="email"
            size="small"
            variant="outlined"
            inputRef={emailRef}
            value={email}
            error={emailError}
            helperText={emailError}
            onChange={handleEmailInputChange}
          />
        </>
      }
    />
  );
};

const WasEmailSendText = styled.div`
  color: #68c548;
`;

const InputTextField = styled(TextField)`
  && {
    margin-top: 10px;
    font-size: 10px;
    width: 100%;
  }
`;
