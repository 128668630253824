import { gql } from "@apollo/client";

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription newNotification {
    newNotification {
      _id
      contentId
      notificationType
      course {
        _id
        title
        university
      }
      wasSeen
      createdAt
    }
  }
`;
