import React from "react";
import styled, { keyframes } from "styled-components";

const BaseAnimation = styled.div`
  animation: rotate 1.5s linear infinite;
`;

const Rotate = keyframes`  
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const SpinnerBorder = "4px solid lightgrey";

const RotateAnimation = styled(BaseAnimation)`
  animation-name: ${Rotate};
  border: 4px solid transparent;
  border-radius: 50%;
  border-top: ${SpinnerBorder};
  border-left: ${SpinnerBorder};
  border-bottom: ${SpinnerBorder};
  width: 20px;
  height: 20px;
  box-sizing: border-box;
`;

export const PrimarySpinner = ({ className }) => {
  return <RotateAnimation className={className} />;
};
