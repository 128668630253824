import React, { useState } from "react";
import { ConfirmationDialog } from "../../../components/dialogs/confirmation-dialog";
import styled from "styled-components";
import { Button } from "@mui/material";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useQuery } from "@apollo/client";
import { GET_USER } from "../../../apollo-client/queries";
import { EditProfilePhoto } from "../../edit-profile/edit-profile-photo";

export const ChangePictureDialog = () => {
  const history = useHistory();
  const routeMatch = useRouteMatch();

  const [isEditPhotoInProgress, setIsEditPhotoInProgress] = useState(false);

  const profileSubdomain = routeMatch.path.split("/");

  const goToChangeProfilePhotoPage = () => {
    history.push("/profile/edit/add-photo");
  };

  const { data: userData } = useQuery(GET_USER);

  return (
    <ConfirmationDialog
      title={<FormattedMessage id="edit-user-data-profile.picture" />}
      isOpen={profileSubdomain?.[4] === "picture-change"}
      toggle={() => {
        setIsEditPhotoInProgress(false);
        history.goBack();
      }}
      hideOkButton={true}
      action={() => history.goBack()}
      content={
        <ContentWrapper>
          <ProfilePictureHoverTextWrapper>
            <EditProfilePhoto
              username={userData?.user.username}
              userPicture={userData?.user.profilePicture}
              style={{ width: 300, height: 300 }}
              setIsEditPhotoInProgress={setIsEditPhotoInProgress}
            />
            <HoverText>
              {<FormattedMessage id="upload-image-component.add-new-picture" />}
            </HoverText>
          </ProfilePictureHoverTextWrapper>
          <ActionButton onClick={goToChangeProfilePhotoPage}>
            Blag-Avatare
          </ActionButton>
        </ContentWrapper>
      }
    />
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ActionButton = styled(Button)`
  && {
    color: #5b5b5b;
    width: 158px;
    margin-top: 20px;
    align-self: center;
  }
`;

const ProfilePictureHoverTextWrapper = styled.div`
  outline: none;
  position: relative;
  width: 300px;
  margin-top: 20px;

  &:hover ${HoverText} {
    display: flex;
  }
  
  &${(props) => !props.isDragActive} ${HoverText} {
    display: flex;
`;
const ProfilePicture = styled("img")`
  border-radius: 100px;
`;

const HoverText = styled.div`
  position: absolute;
  display: ${(props) =>
    props.isDragAccept || props.isDragReject || props.isDragActive
      ? "flex"
      : "none"};
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 100px;
  outline: none;
  cursor: pointer;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
`;
