import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Link, useRouteMatch } from "react-router-dom";
import { showInfoToast } from "../../../toast/toast-service";
import { theme } from "../../../theme";
import { getSelectedArea } from "../../../services/url-helpers";

export const CentralNavigationBar = () => {
  const match = useRouteMatch();

  const isAreaSelected = (areaName) => {
    const selectedArea = getSelectedArea(match.path).split("/")[0];

    if (areaName === "teachers") {
      return selectedArea === "teachers" || selectedArea === "home";
    }

    return selectedArea === areaName;
  };

  return (
    <Wrapper>
      <IconTextWrapper
        onClick={() =>
          showInfoToast(<FormattedMessage id={"feature-coming-soon"} />)
        }
      >
        <IconText isSelected={isAreaSelected("content")}>
          <FormattedMessage id={"header.navigation-content"} />
        </IconText>
        {isAreaSelected("content") && <Line />}
      </IconTextWrapper>

      <StyledLink to={`/`}>
        <IconTextWrapper>
          <IconText isSelected={isAreaSelected("teachers")}>
            <FormattedMessage id={"general.private-tutoring"} />
          </IconText>
          {isAreaSelected("teachers") && <Line />}
        </IconTextWrapper>
      </StyledLink>
      <IconTextWrapper
        onClick={() =>
          showInfoToast(<FormattedMessage id={"feature-coming-soon"} />)
        }
      >
        <IconText isSelected={isAreaSelected("groups")}>
          <FormattedMessage id={"general.study-groups"} />
        </IconText>
        {isAreaSelected("groups") && <Line />}
      </IconTextWrapper>
    </Wrapper>
  );
};

const Line = styled.div`
  background: ${theme.mainColor};
  height: 2px;
  width: 57%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

const Wrapper = styled.div`
  max-width: 800px;
  width: 80%;
  height: 50%;
  justify-content: space-around;
  align-items: center;
  margin-left: 50px;
  display: flex;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    margin-left: 0px;
  }
`;

const IconTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    margin: 11px 11px;
  }
`;

const IconText = styled.div`
  font-weight: 600;
  font-size: 24px;
  color: ${(props) => (props.isSelected ? theme.mainColor : "#D2CECE")};
  cursor: pointer;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    font-size: 16px;
  }
`;
