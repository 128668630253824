import React from "react";
import clock from "../../img/fancy_clock.svg";
import styled from "styled-components";

export const AvgResponseTimeComponent = ({ avgResponseTime }) => {
  if (avgResponseTime === 0) {
    return <ClockResponseTime>Neu</ClockResponseTime>;
  }
  return (
    <ClockTextWrapper>
      <ClockImage src={clock} />
      <ClockResponseTime>Ø {avgResponseTime} h</ClockResponseTime>
    </ClockTextWrapper>
  );
};

const ClockTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ClockImage = styled("img")`
  height: 23px;
  width: 23px;
`;

const ClockResponseTime = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-family: ${(props) => props.theme.secondaryColor};
  color: #001b3c;
  white-space: nowrap;
`;
