import { FormattedMessage } from "react-intl";
import { QuestionMarkTooltip } from "../../../../common/tooltip/question-mark-tooltip";
import dropdown_icon from "../../../../../img/dropdown_icon.svg";
import React, { useRef, useState, Fragment } from "react";
import styled from "styled-components";
import { useOutsideMenuCloserWithFunction } from "../../../../../helpers";
import { ChatDeleteDialog } from "./chat-delete-dialog";
import { useOpenClose } from "../../../../../hooks/use-open-close";
import { ChatBlockDialog } from "./chat-block-dialog";

export const ChatHeader = ({ chosenChat, setChosenChat }) => {
  const [toggleChatOptions, setToggleChatOptions] = useState(false);

  const chatOptionsRef = useRef(null);

  useOutsideMenuCloserWithFunction(chatOptionsRef, () =>
    setToggleChatOptions(false)
  );
  const { value: isDeleteChatOpen, toggle: toggleDeleteChat } =
    useOpenClose(false);

  const { value: isBlockChatDialogOpen, toggle: toggleBlockChatDialog } =
    useOpenClose(false);

  const haveIBlocked =
    chosenChat?.isBlockedBy?.length === 2 ||
    (chosenChat?.isBlockedBy?.length === 1 &&
      !chosenChat?.isBlockedBy
        ?.map((user) => user._id)
        .includes(chosenChat?.receiverId));

  return (
    <Fragment>
      <Container ref={chatOptionsRef}>
        {chosenChat.isOnVacation && (
          <IsOnVacationWrapper>
            <OnVacationText>
              <FormattedMessage id="your-tutoring.vacation-mode-chat.tooltip" />
            </OnVacationText>
            <QuestionMarkTooltip tooltipId={"chat.vacation.hint"} />
          </IsOnVacationWrapper>
        )}
        <UsernameIconWrapper
          onClick={() => setToggleChatOptions(!toggleChatOptions)}
        >
          <ChosenUsername title={chosenChat.chatTitle}>
            {chosenChat.chatTitle}
          </ChosenUsername>
          <DropdownIcon src={dropdown_icon} />
        </UsernameIconWrapper>
        {toggleChatOptions && (
          <ChatOptionBox>
            <ChatOption>
              <FormattedMessage id="chat.mute-chat" />
            </ChatOption>
            <ChatOption onClick={toggleBlockChatDialog}>
              {haveIBlocked ? (
                <FormattedMessage id="chat.unblock-chat" />
              ) : (
                <FormattedMessage id="chat.block-chat" />
              )}
            </ChatOption>
            <ChatOption onClick={toggleDeleteChat}>
              <FormattedMessage id="chat.delete-chat" />
            </ChatOption>
          </ChatOptionBox>
        )}
      </Container>
      <ChatDeleteDialog
        isOpen={isDeleteChatOpen}
        toggle={toggleDeleteChat}
        chosenChat={chosenChat}
        setChosenChat={setChosenChat}
      />
      <ChatBlockDialog
        isOpen={isBlockChatDialogOpen}
        toggle={toggleBlockChatDialog}
        chosenChat={chosenChat}
        haveIBlocked={haveIBlocked}
      />
    </Fragment>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 30px;
  z-index: 1;
`;

const UsernameIconWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
`;

const ChosenUsername = styled.div`
  width: 250px;
  font-weight: 600;
  font-size: 20px;
  color: #575757;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const DropdownIcon = styled("img")`
  width: 24px;
  height: 24px;
  margin: 0 5px;
`;

const ChatOptionBox = styled.div`
  position: absolute;
  top: 24px;
  right: 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: white;
  padding: 15px;
  box-sizing: border-box;
`;

const ChatOption = styled.div`
  margin-top: 15px;
  color: #808080;
  border-radius: 3px;
  cursor: default;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    color: #262626;
  }
`;

const IsOnVacationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OnVacationText = styled.div`
  color: #f19191;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  margin-right: 2px;
`;
