import React from "react";
import styled from "styled-components";
import HelpIcon from "@mui/icons-material/Help";
import { Tooltip } from "./tooltip";

export const QuestionMarkTooltip = ({ tooltipText, tooltipId }) => {
  return (
    <Tooltip tooltipId={tooltipId} tooltipText={tooltipText}>
      <StyledHelpIcon />
    </Tooltip>
  );
};

const StyledHelpIcon = styled(HelpIcon)`
  color: #979797;
  background-color: white;
`;
