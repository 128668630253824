import React from "react";
import styled from "styled-components";

export const Line = ({ fullWidth }) => {
  return (
    <LineContainer>
      <SLine fullWidth={fullWidth} />
    </LineContainer>
  );
};

const LineContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const SLine = styled.div`
  width: ${(props) => (props.fullWidth ? "100%" : "90%")};
  border-bottom: 0.5px solid #666666;
`;
