import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { Redirect, useLocation } from "react-router-dom";
import { CONFIRM_RESET_USER_PASSWORD } from "../../../apollo-client/mutations";
import { SpinningHubertus } from "../../../components/spinner/spinning-hubertus";
import { InputCheckIcon } from "./input-check-icon";
import { MainLayout } from "../../../components/layout/main-layout";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(190.74deg, #c23d3e -8%, #ffffff 53.5%);
`;

const Wrapper = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Headline = styled.div`
  font-size: 20px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 20px;
  color: #656565;
`;

const ToggleInputType = styled.div`
  margin-left: auto;
  margin-top: 5px;
  font-family: Work Sans;
  font-size: 12px;
  align-self: flex-end;
  cursor: pointer;
`;

const Button = styled.div`
  width: 320px;
  height: 30px;
  font-size: 12px;
  font-family: Work Sans;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid grey;
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
  background-color: ${(props) => props.theme.primaryColor};
`;

// A custom hook that builds on useLocation to parse
// the query string for you.
function useLocationQuery() {
  return new URLSearchParams(useLocation().search);
}

//mit container um thumbnail mit min height usw und in thumbnail width und height auf 100% hat sich das dem Bild automatisch angepasst! gut oder sclecht?
export const ResetPasswordPage = (props) => {
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [iconColorNewPassword, setIconColorNewPassword] = useState("lightgrey");
  const [iconColorRepeatNewPassword, setIconColorRepeatNewPassword] =
    useState("lightgrey");
  const [checkIconColor, setCheckIconColor] = useState({
    newPassword: "lightgrey",
    repeatNewPassword: "lightgrey",
  });
  const [toggleInputType, setToggleInputType] = useState("password");
  const [showPasswordErrorDialog, setShowPasswordErrorDialog] = useState(false);

  const query = useLocationQuery();

  useEffect(() => {
    handleNewPasswordIconColor();
    handleRepeatNewPasswordIconColor();
  }, [newPassword, repeatNewPassword]);

  const handleNewPasswordIconColor = () => {
    if (newPassword !== "" && newPassword.length < 4)
      setIconColorNewPassword("red");
    else if (newPassword !== "") setIconColorNewPassword("green");
    else if (newPassword === "") setIconColorNewPassword("lightgrey");
  };

  const handleRepeatNewPasswordIconColor = () => {
    if (repeatNewPassword === newPassword && newPassword !== "")
      setIconColorRepeatNewPassword("green");
    else if (repeatNewPassword !== "") setIconColorRepeatNewPassword("red");
    else setIconColorRepeatNewPassword("lightgrey");
  };

  const [confirmResetUserPassword, { data, loading, error }] = useMutation(
    CONFIRM_RESET_USER_PASSWORD
  );
  const confirmResetPassword = () => {
    if (
      iconColorNewPassword === "green" &&
      iconColorRepeatNewPassword === "green"
    )
      confirmResetUserPassword({
        variables: { token: query.get("token"), newPassword: newPassword },
      }).then(({ data }) => {
        const jwt = data.confirmResetUserPassword.jwt;
        if (jwt) {
          localStorage.setItem("token", jwt);
          window.location.reload();
        }
      });
    else setShowPasswordErrorDialog(true);
  };

  if (localStorage.getItem("token")) return <Redirect to={`/university`} />;
  else if (loading) {
    return <SpinningHubertus />;
  } else if (error) {
    return (
      <div>
        Etwas ist schief gelaufen. Versuchen Sie es erneut oder lassen Sie sich
        die E-Mail erneut senden
      </div>
    );
  } else if (!localStorage.getItem("token"))
    return (
      <MainLayout
        hideNavigation={true}
        backgroundColor={"none"}
        hideChat={true}
        hideCourseNav={true}
        showFooter={true}
        props={props}
      >
        <Container>
          <Wrapper>
            <Headline>Neues Passwort erstellen</Headline>
            <InputCheckIcon
              type={toggleInputType}
              placeholder={"Neues Passwort"}
              onChange={(event) => setNewPassword(event.target.value)}
              checkIconColor={iconColorNewPassword}
            />
            <InputCheckIcon
              type={toggleInputType}
              placeholder={"Neues Passwort wiederholen"}
              onChange={(event) => setRepeatNewPassword(event.target.value)}
              checkIconColor={iconColorRepeatNewPassword}
            />
            {toggleInputType === "password" ? (
              <ToggleInputType onClick={() => setToggleInputType("text")}>
                Passwort anzeigen
              </ToggleInputType>
            ) : (
              <ToggleInputType onClick={() => setToggleInputType("password")}>
                Passwort ausblenden
              </ToggleInputType>
            )}
            <Button onClick={confirmResetPassword}>Passwort erstellen</Button>
          </Wrapper>
        </Container>
      </MainLayout>
    );
};
