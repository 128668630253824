import React, { useRef, useState } from "react";
import styled from "styled-components";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { FormattedMessage, useIntl } from "react-intl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { loginValidators } from "../../services/login-validators";
import { CHANGE_PASSWORD } from "../../apollo-client/mutations";
import { showErrorToast, showSuccessToast } from "../../toast/toast-service";
import { useHistory } from "react-router-dom";

export const EditProfilePasswordForm = ({ user }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [formValueErrors, setFormValueErrors] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const history = useHistory();
  const oldPasswordRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const intl = useIntl();

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    update(cache, addContentLinkResponse) {},
  });

  const handleOldPasswordInputChange = (event) => {
    const passportInputText = event.target.value;

    setFormValues({
      ...formValues,
      oldPassword: passportInputText,
    });
    resetFormErrors();
  };

  const handlePasswordInputChange = (event) => {
    const passportInputText = event.target.value;

    setFormValues({
      ...formValues,
      password: passportInputText,
    });
    resetFormErrors();
  };

  const handleConfirmPasswordInputChange = (event) => {
    const passportInputText = event.target.value;

    setFormValues({
      ...formValues,
      confirmPassword: passportInputText,
    });
    resetFormErrors();
  };

  const resetForm = () => {
    setFormValues({
      oldPassword: "",
      password: "",
      confirmPassword: "",
    });
  };

  const resetFormErrors = () => {
    setFormValueErrors({
      oldPassword: "",
      password: "",
      confirmPassword: "",
    });
  };

  const handleSaveData = async (e) => {
    // must be set for Button.onSubmit method, because otherwise it refreshes the page without waiting for the mutation to succeed
    e.preventDefault();

    let oldPwFieldError = "";
    let pwFieldError = "";
    let confirmPwFieldError = "";

    if (formValues.password !== "") {
      try {
        loginValidators.validatePassword(formValues.password, intl);
      } catch (err) {
        pwFieldError = err.message;
        passwordRef.current.focus();
      }
    }

    if (formValues.password !== "") {
      if (formValues.confirmPassword !== formValues.password) {
        pwFieldError = true;
        confirmPwFieldError = intl.formatMessage({
          id: "edit-user-data.confirm-pw-error",
        });
      }
    }

    try {
      await changePassword({
        variables: {
          oldPassword: formValues.oldPassword,
          newPassword: formValues.confirmPassword,
        },
      });
      showSuccessToast(
        <FormattedMessage id={"edit-user-data-change-password-successfully"} />
      );
      resetForm();
      history.push(`/profile/${user._id}/edit`);
    } catch (err) {
      const graphQLErrorMessage = err.graphQLErrors[0].message;
      const errorMessage = intl.formatMessage(
        {
          id: "edit-user-data-backend-errors",
        },
        { error: graphQLErrorMessage }
      );

      showErrorToast(errorMessage);

      oldPwFieldError = errorMessage;

      setFormValueErrors({
        oldPassword: oldPwFieldError,
        password: pwFieldError,
        confirmPassword: confirmPwFieldError,
      });
    }
  };

  return (
    <UserData>
      <InputTextField
        label={intl.formatMessage({
          id: "edit-user-data.old-password-placeholder",
        })}
        variant="outlined"
        size="small"
        inputRef={oldPasswordRef}
        value={formValues.oldPassword}
        error={formValueErrors.oldPassword}
        helperText={formValueErrors.oldPassword}
        type={showPassword ? "text" : "password"}
        onChange={handleOldPasswordInputChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                size={"small"}
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                onMouseDown={() => {}}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <InputTextField
        label={intl.formatMessage({
          id: "edit-user-data.change-password",
        })}
        variant="outlined"
        size="small"
        inputRef={passwordRef}
        value={formValues.password}
        error={formValueErrors.password}
        helperText={formValueErrors.password}
        type={showPassword ? "text" : "password"}
        onChange={handlePasswordInputChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                size={"small"}
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                onMouseDown={() => {}}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <InputTextField
        label={intl.formatMessage({
          id: "edit-user-data.approve-confirm",
        })}
        variant="outlined"
        size="small"
        inputRef={confirmPasswordRef}
        value={formValues.confirmPassword}
        error={formValueErrors.confirmPassword}
        helperText={formValueErrors.confirmPassword}
        type={showPassword ? "text" : "password"}
        onChange={handleConfirmPasswordInputChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                size={"small"}
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                onMouseDown={() => {}}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <SaveButton onClick={handleSaveData}>
        {" "}
        <FormattedMessage id={"edit-user-data.save"} />
      </SaveButton>
    </UserData>
  );
};

const UserData = styled.div`
  margin-left: 10%;

  margin-top: 60px;
  width: 50%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    margin-left: 0%;
    margin-top: 50px;
    width: 80%;
  }
`;

const InputTextField = styled(TextField)`
  && {
    margin-top: 10px;
    font-size: 10px;
    width: 100%;
  }
`;

const SaveButton = styled(Button)`
  && {
    color: #ffffff;
    background: #c23d3e;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    width: 158px;
    margin-top: 15px;
    align-self: flex-end;
  }
`;
