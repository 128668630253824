export function getSelectedArea(path) {
  const splitted = path.split("/");

  if (splitted[1] === "profile") {
    switch (splitted[3]) {
      case "my-teachings":
        return "profile/my-teachings";
      case "my-groups":
        return "profile/my-groups";
      case "my-content":
        return "profile/my-content";
      case "edit":
        return "profile/edit";
      default:
        return "profile";
    }
  }

  if (splitted.length === 2) {
    if (splitted[1] === "home") {
      return "home";
    }
  }

  if (
    splitted.length === 4 ||
    (splitted.length === 5 && splitted[3] === "teachers")
  ) {
    return splitted[3];
  }
  // returns e.g. content/videos
  else return `${splitted[3]}/${splitted[4]}`;
}
