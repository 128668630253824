import _ from "lodash";

export function normalizeErrorMessage(errorMessage) {
  // need to slice because response looks like: "value" must be a number
  return _.snakeCase(errorMessage.slice(8));
}

export function translateAndThrowError(err, intlId, intl) {
  const errorMessage = normalizeErrorMessage(err.message);

  err.message = intl.formatMessage(
    {
      id: intlId
    },
    {
      error: errorMessage
    }
  );

  throw err;
}
