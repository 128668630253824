import styled from "styled-components";

export const BlagButton = (props) => {
  return <CustomButton {...props}>{props.children}</CustomButton>;
};

const CustomButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  cursor: pointer;
  background-color: ${(props) =>
    props.disabled ? "#cccccc" : props.theme.mainColor};
  background-size: 200% 100%;
  background-position: right bottom;
  font-size: 16px;
  font-weight: 600;
  color: white;
  padding: 13px 20px;
  width: fit-content;
`;
