import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import my_courses_icon from "../../../../img_new/My_Courses_Icon.svg";
import search_courses_icon from "../../../../img_new/Search_Courses_Icon.svg";
import mobile_chat_icon from "../../../../img_new/Chat_Icon.svg";
import { ArrowBackIcon } from "../../../icons/icons-svg/arrow-back-icon";
import { useMatchMediaEvent } from "../../../../helpers";
import { NavbarContent } from "../../../navbar_content";

//TODO search course png better quality!!!
//TODO search course toggle
//TODO Text oder so, wenn keine Kurse vorhanden
export const SmallCourseNav = ({
  showContentNav,
  toggleMyCourses,
  toggleSearchCourses,
  toggleMobileChat,
  props,
}) => {
  const [renderCoursesNumber, setRenderCoursesNumber] = useState(3);

  const handleThreeRenders = useCallback(() => {
    setRenderCoursesNumber(3);
  }, [renderCoursesNumber]);

  const handleTwoRenders = useCallback(() => {
    setRenderCoursesNumber(2);
  }, [renderCoursesNumber]);

  const handleOneRender = useCallback(() => {
    setRenderCoursesNumber(1);
  }, [renderCoursesNumber]);

  const handleZeroRenders = useCallback(() => {
    setRenderCoursesNumber(0);
  }, [renderCoursesNumber]);

  //TODO memory leak here!!!
  useMatchMediaEvent("(min-height: 700px)", handleThreeRenders);
  useMatchMediaEvent("(max-height: 690px)", handleTwoRenders);
  useMatchMediaEvent("(max-height: 590px)", handleOneRender);
  useMatchMediaEvent("(max-height: 550px)", handleZeroRenders);

  return (
    <Container>
      <WebContainer id={"small_course_nav_container"}>
        <MyCoursesButton onClick={toggleMyCourses}>
          <ImgTextWrapper>
            <NavIcon src={my_courses_icon} />
            <MyCoursesText>
              <FormattedMessage id={"courses-nav.title"} />
            </MyCoursesText>
          </ImgTextWrapper>
          <ArrowIconWrapper>
            <ArrowBackIcon color={"#383838"} width={20} height={20} />
          </ArrowIconWrapper>
        </MyCoursesButton>

        <SearchCourseButton onClick={toggleSearchCourses}>
          <ImgTextWrapper>
            <NavIcon src={search_courses_icon} />

            <SearchCourseText>
              <FormattedMessage id={"courses-nav.search-course"} />
            </SearchCourseText>
          </ImgTextWrapper>
          <ArrowIconWrapper>
            <ArrowBackIcon color={"#383838"} width={20} height={20} />
          </ArrowIconWrapper>
        </SearchCourseButton>

        {showContentNav && <NavbarContent {...props} />}
      </WebContainer>

      <MobileButtonsContainer>
        <MobileMyCoursesButton onClick={toggleMyCourses}>
          <NavIcon src={my_courses_icon} />
          {/*<FormattedMessage id={"courses-nav.title"} />*/}
          <ArrowUpIcon />
        </MobileMyCoursesButton>

        <MobileSearchCourseButton onClick={toggleSearchCourses}>
          {/*<SearchCourseIcon />*/}
          <NavIcon src={search_courses_icon} />
          {/*<FormattedMessage id={"courses-nav.search-course"} />*/}
          <ArrowUpIcon />
        </MobileSearchCourseButton>

        <MobileChatButton onClick={toggleMobileChat}>
          <MobileChatIcon src={mobile_chat_icon} />
        </MobileChatButton>
      </MobileButtonsContainer>
    </Container>
  );
};

const Container = styled.div``;

const WebContainer = styled.div`
  position: fixed;

  top: 278px;
  left: 0;
  width: 15%;

  height: calc(100% - 270px);

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    display: none;
  }
`;

const MyCoursesButton = styled.div`
  display: flex;
  align-items: center;
  width: 180px;
  min-width: 210px;
  height: 58px;
  margin-top: 20px;
  justify-content: space-between;
  cursor: pointer;
`;

const MyCoursesText = styled.div`
  //font-weight: bold;
  font-size: 21px;
  line-height: 29px;
  margin-left: 15px;
  padding-right: 15px;
`;

const SearchCourseButton = styled.div`
  display: flex;
  align-items: center;
  width: 80px;
  min-width: 210px;
  height: 58px;
  margin-top: 10px;
  justify-content: space-between;
  cursor: pointer;
`;

const ImgTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchCourseText = styled.div`
  //font-weight: bold;
  font-size: 21px;
  line-height: 29px;
  margin-left: 15px;
  padding-right: 15px;
`;

const NavIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 10px;
`;

const ArrowIconWrapper = styled.div`
  //margin-left: 15px;
`;

const MobileButtonsContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  background: white;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.15);

  @media only screen and (min-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    display: none;
  }
`;

const MobileButton = styled.div`
  width: 33%;
  height: 39px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  color: #dddddd;
  margin: 0 5px 0 5px;
  padding-top: 0;
  box-sizing: border-box;
`;

const MobileMyCoursesButton = styled(MobileButton)`
  background-color: #ffffff;
  color: #383838;
  margin-bottom: 5px;
  justify-content: center;
`;

const ArrowUpIcon = styled(ArrowBackIcon)`
  transform: rotate(-90deg);
  height: 15px;
  margin-left: 8px;
`;

const MobileSearchCourseButton = styled(MobileButton)`
  background-color: #ffffff;
  color: #383838;
  margin-bottom: 5px;
  padding: 3px;
  justify-content: center;
`;

const MobileChatButton = styled(MobileButton)`
  background-color: #ffffff;
  color: #383838;
  margin-bottom: 5px;

  justify-content: center;
`;

const MobileChatIcon = styled.img`
  width: 40px;
`;
