import { FormattedMessage } from "react-intl";
import { Message } from "./message";
import React from "react";
import styled from "styled-components";
import { CHAT_USERNAME_COLORS } from "../../../../../constants";
import {
  convertUnixTimeStamp,
  getCurrentDate,
  isYesterday,
} from "../../../../../helpers";

const resolveUsernameColor = (username, members = []) => {
  const memberIndex = members.findIndex(
    (member) => member.username === username
  );

  return CHAT_USERNAME_COLORS[memberIndex % CHAT_USERNAME_COLORS.length];
};

//set message dates (green dates in message container)
const getMessageDateIfNeeded = (createdAt, i, arr) => {
  let oldTimestamp = arr[i - 1] ? arr[i - 1].createdAt : arr[i].createdAt;
  let messageDate = convertUnixTimeStamp(createdAt);
  let oldMessageDate = convertUnixTimeStamp(oldTimestamp);
  //brauche eigentlich immer nur die letzte Nachricht des Tages
  if (messageDate !== oldMessageDate || i === 0) {
    let date;
    let currentDate = getCurrentDate();
    if (messageDate === currentDate) return (date = "Heute");
    else if (isYesterday(createdAt)) return (date = "Gestern");
    else return (date = messageDate);
  } else return null;
};

export const MessageWithTime = ({
  messageObject,
  messageIndex,
  dataMessages,
  chatType,
  messages,
  postingMessage,
  isFirstUnseenMessage,
}) => {
  const { _id: messageId, author } = messageObject;

  // const isFirstUnseenMessage = messageId === firstNewMessageId;

  const date = getMessageDateIfNeeded(
    messageObject.createdAt,
    messageIndex,
    messages
  );

  return (
    <TimeMessageWrapper key={"time_message_wrapper_" + messageIndex}>
      {date && <Time key={"time_" + messageIndex}>{date}</Time>}

      {isFirstUnseenMessage && (
        <NewMessagesMarker
          id={"new_message_marker_" + messageId}
          key={"new_message_marker_" + messageId}
        >
          <FormattedMessage id="chat.new-messages" />
        </NewMessagesMarker>
      )}

      <Message
        key={"message_" + messageIndex}
        id={"message_" + messageIndex}
        authorColor={resolveUsernameColor(
          author.username,
          dataMessages.user.chats.chats[0].members
        )}
        lastMessage={messages[messageIndex - 1]}
        chatType={chatType}
        messageObject={messageObject}
        postingMessage={postingMessage}
      />
    </TimeMessageWrapper>
  );
};

const TimeMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Time = styled.div`
  position: ${(props) => (props.fixed ? "fixed" : "static")}
  margin: 10px 0;
  align-self: center;
  font-size: 14px;
  padding: 0.2em 1.2em;
  border-radius: 40px;
  color: white;
  background-color: #72deaa;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NewMessagesMarker = styled.div`
  margin: 10px 0;
  align-self: center;
  font-size: 16px;
  padding: 0.2rem 1.2rem;
  border-radius: 5px;
  background-color: lightgrey;
`;
