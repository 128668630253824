import React, { useLayoutEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div``;

const EditableText = styled.div`
  border: transparent;
  border-bottom: 1px solid #787878;
  background-color: transparent;
  outline: none;
  cursor: text;
  padding-bottom: 5px;
  font-size: ${props => props.theme.mediumFontSize};

  &[contentEditable="true"]:empty:before {
    content: attr(placeholder);
    color: #787878;
    font-size: ${props => props.theme.mediumFontSize};
  }

  &[contentEditable]:focus {
    outline: 0 solid transparent;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 30px;
  border: 1px solid black;
  margin-left: ${props => (props.rightButton ? "10px" : 0)};
  text-align: center;
  cursor: pointer;
`;

export const EditText = ({
  id,
  onKeyUp,
  text,
  toggleEdit,
  setToggleEdit,
  editMutation
}) => {
  useLayoutEffect(() => {
    if (document.getElementById("edit_text_" + id)) {
      if (document.getElementById("edit_text_" + id).innerText === "") {
        document.getElementById("edit_text_" + id).innerText = text;
      }
    }
  }, [toggleEdit]);

  if (toggleEdit)
    return (
      <Container>
        <EditableText
          id={"edit_text_" + id}
          contentEditable={true}
          placeholder={"Bearbeiten"}
          onKeyUp={onKeyUp}
        />
        <ButtonContainer>
          <Button
            onClick={() => {
              setToggleEdit();
              document.getElementById("edit_text_" + id).innerText = text;
            }}
          >
            Abbrechen
          </Button>
          <Button
            rightButton
            onClick={() => {
              editMutation();
              setToggleEdit();
            }}
          >
            Bearbeiten
          </Button>
        </ButtonContainer>
      </Container>
    );
  else return null;
};

EditText.defaultProps = {};

EditText.propTypes = {
  id: PropTypes.string.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  toggleEdit: PropTypes.bool.isRequired,
  setToggleEdit: PropTypes.func.isRequired,
  editMutation: PropTypes.func.isRequired
};
