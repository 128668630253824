import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  cutString,
  getBasicPath,
  useOutsideMenuCloserWithFunction,
} from "../../../../helpers";
import { IoMdMore } from "react-icons/io";
import { showSuccessToast } from "../../../../toast/toast-service";
import { useIsMobile } from "../../../../hooks/state-checkers/use-is-mobile";

const DotIconDeleteWrapper = styled.div``;

const DotIcon = styled(IoMdMore)`
  margin-right: 10px;
  visibility: ${(props) => props.visibility};
  cursor: pointer;
  color: #989898;
`;

const CourseWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  padding-left: 18px;
  margin-left: 10px;
  margin-top: 14px;
  box-sizing: border-box;
  width: 95%;
  border-radius: 10px;
  cursor: pointer;

  @media only screen and (min-width: ${(props) =>
      props.theme.firstBreakpoint + 1}px) {
    &:hover {
      background: #e4e6e9;
    }

    /*
       This effect prevents the Link to take effect in mobile
       Instead of going to the course page, the hover effect will be shown. The user has to click one more time to go to the course, which is counterintuitive
      
       */
    &:hover ${DotIcon} {
      visibility: visible;
    }
  }
`;

const StyledLink = styled(Link)`
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

const CourseText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #383838;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const DeleteTextWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100px;
  right: 0;
  margin-right: 20px;
  box-sizing: border-box;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.4);
  border-radius: 7px;
  cursor: pointer;
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1;
`;

const DeleteText = styled.div``;

export default function MyCourse({
  courseId,
  course,
  userId,
  userCourses,
  isCourseNotAdded,
  removeMyCourses,
  closeCourseNav,
}) {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const deleteSuccessMessage =
    cutString(course.title, 20) +
    " " +
    intl.formatMessage({
      id: "general.delete-success",
    });

  const [isDeleteMenuOpen, setIsDeleteMenuOpen] = useState(false);
  const location = useLocation();

  const handleMenuIconClick = () => {
    setIsDeleteMenuOpen(!isDeleteMenuOpen);
  };

  const deleteTextWrapperRef = useRef(null);
  useOutsideMenuCloserWithFunction(deleteTextWrapperRef, () =>
    setIsDeleteMenuOpen(false)
  );

  return (
    <CourseWrapper id={"wrapper_" + course._id}>
      <StyledLink
        title={course.title}
        to={`/courses/${course._id}/${getBasicPath(location.pathname)}${
          isMobile ? "" : "?mycourseopen=1"
        }`}
        style={{ textDecoration: "none" }}
      >
        <CourseText>{course.title}</CourseText>
      </StyledLink>
      <DotIconDeleteWrapper ref={deleteTextWrapperRef}>
        <DotIcon
          size={24}
          visibility={isDeleteMenuOpen ? "visible" : "hidden"}
          onClick={handleMenuIconClick}
        />
        {!isCourseNotAdded && isDeleteMenuOpen && (
          <DeleteTextWrapper>
            <DeleteText
              onClick={() => {
                removeMyCourses({
                  variables: { ids: [course._id] },
                  optimisticResponse: {
                    __typename: "Mutation",
                    removeMyCourses: {
                      _id: userId,
                      __typename: "User",
                      courses: {
                        __typename: "Courses",
                        courses: [
                          ...userCourses.filter(
                            (course) => course._id !== courseId
                          ),
                        ],
                      },
                    },
                  },
                });
                showSuccessToast(deleteSuccessMessage);
                setIsDeleteMenuOpen(false);
              }}
            >
              {intl.formatMessage({ id: "general.delete" })}
            </DeleteText>
          </DeleteTextWrapper>
        )}
      </DotIconDeleteWrapper>
    </CourseWrapper>
  );
}
