import { useEffect, useRef } from "react";

export function useOutsideEvent(callback, opts) {
  const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener(
      opts?.eventType || "mousedown",
      handleClickOutside
    );
    return () => {
      document.removeEventListener(
        opts?.eventType || "mousedown",
        handleClickOutside
      );
    };
  }, [ref]);
  return ref;
}
