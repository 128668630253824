import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { useQuery } from "@apollo/client";
import { GET_TEACHINGS } from "../../apollo-client/queries";
import { TeacherSmallComponent } from "../teachers-page/teacher-small-component";
import React from "react";
import { Link } from "react-router-dom";
import { theme } from "../../theme";
import { PrimarySpinner } from "../../components/spinner/primary-spinner";

export const HomePageTeachingsBox = () => {
  const {
    data: teachingsData,
    error,
    loading: loadingTeachings,
    fetchMore,
  } = useQuery(GET_TEACHINGS, {
    variables: {
      limit: 15,
      isActive: true,
      onHoliday: false,
    },
    pollInterval: 60000 * 5, // every 60 seconds x 5
    fetchPolicy: "cache-and-network",
  });

  const teachings = teachingsData?.courses?.courses?.[0]?.teachings?.teachings;
  const teachingsCount =
    teachingsData?.courses?.courses?.[0]?.teachings?.teachingsCount;

  const shouldShowCourseTitle = (teaching, i, arr) => {
    return (
      i === 0 || (i !== 0 && arr[i - 1].course.title !== arr[i].course.title)
    );
  };

  return (
    <>
      <FurtherTeachingsText>
        <FormattedMessage id="home-page.further-teachings" />
      </FurtherTeachingsText>
      {!error &&
        teachings?.map((teaching, i, arr) => {
          // eventuell array nach IN_MODULE filtern und ab einer length von x oben das nicht mehr anzeigen (StartTutoringMotivation
          // funktion eventuell aus dem conditional machen => handleIsInModule(teachingScope, i, arr) oder so

          return (
            <div key={i}>
              {shouldShowCourseTitle(teaching, i, arr) && (
                <OtherModuleText>{teaching.course.title}</OtherModuleText>
              )}
              <TeacherLink
                key={"link_" + i}
                to={`/courses/${teachingsData.courses.courses[0]._id}/teachers/${teaching._id}`}
              >
                <TeacherSmallComponent
                  key={"TeacherComponent_" + i}
                  teaching={teaching}
                  showSmallTeacherComponent={true}
                  courseTitle={teachingsData.courses.courses[0].title}
                  hideLine={i === arr.length - 1}
                />
              </TeacherLink>
            </div>
          );
        })}
      {loadingTeachings && <StyledPrimarySpinner />}
    </>
  );
};

const FurtherTeachingsText = styled.div`
  font-size: ${(props) => props.theme.smallHeaderFontSize};
  padding-top: 30px;
  margin-top: 60px;
  border-top: 1px solid #acacac;
  text-align: start;
  width: 100%;
  color: #e87975;
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    font-size: 16px;
    line-height: 22px;
    box-sizing: border-box;
    padding-left: 10px;
  }
`;

const TeacherLink = styled(Link)`
  text-decoration: none;
  color: ${theme.mainTextColor};
  margin: 0;
  width: 100%;
`;

const OtherModuleText = styled.div`
  font-size: ${(props) => props.theme.listHeaderFontSize};
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: start;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    box-sizing: border-box;
    padding-left: 10px;
    font-size: 16px;
    line-height: 22px;
  }
`;

const StyledPrimarySpinner = styled(PrimarySpinner)`
  height: 100px;
  width: 100px;
  margin: 20px auto;
`;
