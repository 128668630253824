import Joi from "joi";
import { translateAndThrowError } from "./joi-validation-helpers";

export const loginValidators = {
  validateEmail: (email, intl) => {
    try {
      Joi.attempt(
        email,
        Joi.string()
          .email({ tlds: { allow: false } })
          .required()
      );
    } catch (err) {
      translateAndThrowError(err, "login-errors-local-email", intl);
    }
  },
  validatePassword: (password, intl) => {
    try {
      Joi.attempt(password, Joi.string().min(5).max(50).required());
    } catch (err) {
      translateAndThrowError(err, "login-errors-local-password", intl);
    }
  },
  validateName: (name, intl) => {
    try {
      Joi.attempt(name, Joi.string().alphanum().min(3).max(30).required());
    } catch (err) {
      translateAndThrowError(err, "login-name-local-password", intl);
    }
  },
};
