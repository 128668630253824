import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { BlagoomStandardLogo } from "../../components/icons/icons-svg/blagoom-standard-logo";
import { useHistory } from "react-router-dom";

export const CopyrightAboutUs = ({ className }) => {
  const history = useHistory();

  return (
    <LogoMoreInformationContainer className={className}>
      <UpperBox>
        <LogoCopyrightWrapper>
          <StyledBlagoomStandardLogo />
          <CopyRight>
            <FormattedMessage id={"footer.copyright"} />
          </CopyRight>
        </LogoCopyrightWrapper>
        <MoreInformationWrapper>
          <MoreInformationText>
            <FormattedMessage id={"footer.learn-more"} />
          </MoreInformationText>
          <MoreInformationLinksWrapper>
            <MoreInformationLink onClick={() => history.push(`/imprint`)}>
              <FormattedMessage id={"footer.about-us"} />
            </MoreInformationLink>
            <MoreInformationLink onClick={() => history.push(`/help`)}>
              <FormattedMessage id={"footer.faq"} />
            </MoreInformationLink>
            <MoreInformationLink onClick={() => history.push(`/imprint`)}>
              <FormattedMessage id={"footer.contact"} />
            </MoreInformationLink>
          </MoreInformationLinksWrapper>
        </MoreInformationWrapper>
      </UpperBox>
      <BottomBox>
        <BottomLink onClick={() => history.push(`/agb`)}>
          <FormattedMessage id="footer.general-terms" />
        </BottomLink>
        <BottomLink onClick={() => history.push(`/imprint`)}>
          <FormattedMessage id="footer.data-protection" />
        </BottomLink>
        <BottomLink onClick={() => history.push(`/imprint`)}>
          <FormattedMessage id="footer.imprint" />
        </BottomLink>
      </BottomBox>
    </LogoMoreInformationContainer>
  );
};

const LogoMoreInformationContainer = styled.div`
  max-width: 100vw;
  display: flex;
  flex-direction: column;
`;

const LogoCopyrightWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledBlagoomStandardLogo = styled(BlagoomStandardLogo)`
  height: 30px;
`;

const CopyRight = styled.div`
  margin-top: 20px;
  white-space: pre-line;
  margin-left: 30px;

  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;

  color: #656565;
`;

const MoreInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  @media only screen and (max-width: 360px) {
    margin-top: 30px;
  }
`;

const MoreInformationText = styled.div`
  font-weight: 500;
  font-size: 18px;
`;

const MoreInformationLinksWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const MoreInformationLink = styled.div`
  align-self: flex-start;

  cursor: pointer;
  flex-shrink: 1;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  display: flex;
  align-items: flex-end;

  color: ${(props) => props.theme.zweitFarbe};

  &:hover {
    color: ${(props) => props.theme.hoverColor};
  }
`;

const UpperBox = styled.div`
  width: 100%;
  display: flex;

  @media only screen and (max-width: 360px) {
    flex-direction: column;
  }
`;

const BottomBox = styled.div`
  width: 100%;
  height: 80px;
  background-color: #cacaca;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const BottomLink = styled.p`
  color: white;
  margin-left: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
`;
