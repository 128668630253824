import React from "react";
import PropTypes from "prop-types";

export const AddIcon = ({ width, height, color, fill, className, onClick }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 41C32.0457 41 41 32.0457 41 21C41 9.95431 32.0457 1 21 1C9.95431 1 1 9.95431 1 21C1 32.0457 9.95431 41 21 41Z"
        fill={fill}
        stroke={color}
        strokeWidth="0.4"
      />
      <line
        x1="21.2695"
        y1="9.88916"
        x2="21.2695"
        y2="32.1114"
        stroke={color}
        strokeWidth="2"
      />
      <line
        x1="9.88672"
        y1="20.7402"
        x2="32.1089"
        y2="20.7402"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

AddIcon.defaultProps = {
  width: "40",
  height: "40",
  color: "#C23D3E",
  fill: "#ffffff"
};

AddIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  fill: PropTypes.string
};
