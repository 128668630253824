import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

//width and height in component because it accepts number and string
const ProfilePictureContainer = styled("img")`
  border-radius: 100%;

  width: 110px;
  height: 110px;

  @media only screen and (max-width: 980px) {
    width: 82px !important;
    height: 82px !important;
  }
`;

export const ProfilePicture = ({
  src,
  width,
  height,
  margin,
  className,
  onClick,
}) => {
  return (
    <ProfilePictureContainer
      className={className}
      src={src}
      style={{
        minWidth: width,
        maxWidth: width,
        minHeight: height,
        maxHeight: height,
        margin: margin,
      }}
      onClick={onClick}
      alt={"profile picture"}
    />
  );
};

ProfilePicture.defaultProps = {
  src: "https://storage.googleapis.com/blag-profile-pictures/default-profile-picture.png",
};

ProfilePicture.propTypes = {
  src: PropTypes.string.isRequired,
};
