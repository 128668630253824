import React, { useState } from "react";
import styled from "styled-components";
import { ChatHeader } from "./ChatHeader/chatHeader";
import { MessageContainer } from "./MessageContainer/messageContainer";
import { useMutation } from "@apollo/client";
import { MARK_CHAT_MESSAGES_AS_READ } from "../../../../apollo-client/mutations";
import { ChatInputBar } from "./MessageContainer/chat-input-bar";
import { useIntlId } from "../../../../hooks/use-intl-id";

export const MainChatWindow = ({
  chosenChat,
  setChosenChat,
  setSearchContact,
}) => {
  const [postingMessage, setPostingMessage] = useState("");

  const searchPlaceholder = useIntlId("chat.search-for-contact-placeholder");

  const [markChatMessagesAsRead] = useMutation(MARK_CHAT_MESSAGES_AS_READ);
  const markMessagesAsRead = async (chosenChatId) => {
    await markChatMessagesAsRead({
      variables: {
        id: chosenChatId || chosenChat.chatId,
      },
    });
  };

  return (
    <MainChatWrapper
      onClick={async () => {
        if (chosenChat.unreadMessagesCount > 0) {
          await markMessagesAsRead(chosenChat._id);
        }
      }}
    >
      <SearchBar
        placeholder={searchPlaceholder}
        onChange={(event) => setSearchContact(event.target.value)}
      />
      <ChatHeader chosenChat={chosenChat} setChosenChat={setChosenChat} />
      <MessageContainer
        chosenChat={chosenChat}
        postingMessage={postingMessage}
      />

      <ChatInputBar
        chosenChat={chosenChat}
        setChosenChat={setChosenChat}
        setPostingMessage={setPostingMessage}
      />
    </MainChatWrapper>
  );
};

const MainChatWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
`;

const SearchBar = styled.input`
  width: 80%;
  outline: none;
  border: none;
  border-bottom: 1px solid lightgrey;
  margin-top: 23px;
  margin-left: 10px;
  font-size: 16px;
`;
