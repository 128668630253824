import React from "react";
import PropTypes from "prop-types";
import { Header } from "./header/header";
import CourseNavControl from "./courseNav/course-nav";
import { Report } from "./report";
import { Footer } from "../../pages/landing-page/footer";
import { Chat } from "./chat/chat";
import { LoggedInSidebar } from "../sidebars/logged-in-sidebar/logged-in-sidebar";
import { LoginSidebar } from "../sidebars/login-sidebar/login-sidebar";

export const MainLayout = ({
  title,
  university,
  subscriberCount,
  loading,
  error,
  hideNavigation,
  backgroundColor,
  hideCourseNav,
  hideChat,
  showContentNav,
  showFooter,
  children,
  startChat,
  makeGroupVisibleInCourses,
  visibleCourses,
  setMakeGroupVisibleInCourses,
  setVisibleCourses,
  course,
  props,
}) => {
  const isLoggedIn = Boolean(localStorage.getItem("token"));

  return (
    <div>
      {children}

      {!hideChat && isLoggedIn && <Chat startChat={startChat} />}

      <Header
        title={title}
        university={university}
        course={course}
        subscriberCount={subscriberCount}
        loading={loading}
        error={error}
        hideNavigation={hideNavigation}
        backgroundColor={backgroundColor}
        props={props}
      />

      {!hideCourseNav && (
        <CourseNavControl
          showContentNav={showContentNav}
          makeGroupVisibleInCourses={makeGroupVisibleInCourses}
          setMakeGroupVisibleInCourses={setMakeGroupVisibleInCourses}
          visibleCourses={visibleCourses}
          setVisibleCourses={setVisibleCourses}
          props={props}
        />
      )}

      <Report />
      <LoggedInSidebar />
      <LoginSidebar />

      {showFooter && <Footer />}
    </div>
  );
};

MainLayout.defaultProps = {
  checkIconColor: "lightgrey",
  type: "password",
};

MainLayout.propTypes = {
  props: PropTypes.object.isRequired,
  title: PropTypes.string,
  university: PropTypes.string,
  backgroundColor: PropTypes.string,
  hideNavigation: PropTypes.bool,
  hideChat: PropTypes.bool,
  hideCourseNav: PropTypes.bool,
  showFooter: PropTypes.bool,
};
