import { FormattedMessage } from "react-intl";
import React from "react";

import { ConfirmationDialog } from "../../../components/dialogs/confirmation-dialog";
import { gql, useMutation } from "@apollo/client";
import { GET_TEACHINGS } from "../../../apollo-client/queries";

export const EDIT_USER_HOLIDAYS = gql`
  mutation editUserHolidays($onHoliday: HolidayOptions) {
    editUserProfile(onHoliday: $onHoliday) {
      _id
      onHoliday
    }
  }
`;

export const VacationActivationDialog = ({ isDialogOpen, toggleDialog }) => {
  const [editUserHolidays] = useMutation(EDIT_USER_HOLIDAYS, {
    variables: {
      onHoliday: {
        goOnHoliday: true,
      },
    },
    refetchQueries: [GET_TEACHINGS],
    // TODO: refetch teachings query on teachers-page
  });

  const activateHoliday = async () => {
    await editUserHolidays();
    toggleDialog();
  };

  return (
    <ConfirmationDialog
      title={<FormattedMessage id="your-tutoring.vacation-activate-title" />}
      content={
        <FormattedMessage id="your-tutoring.vacation-activate-description" />
      }
      isOpen={isDialogOpen}
      toggle={toggleDialog}
      action={activateHoliday}
    />
  );
};
