import React from "react";
import PropTypes from "prop-types";

export const MemberIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7956 27.0493C28.2033 27.0493 34.2085 20.9941 34.2085 13.5246C34.2085 6.05519 28.2033 0 20.7956 0C13.3879 0 7.38281 6.05519 7.38281 13.5246C7.38281 20.9941 13.3879 27.0493 20.7956 27.0493Z"
        fill={color}
      />
      <path
        d="M26.8255 33.6094H14.9645C7.11487 33.6094 0.75 40.0257 0.75 47.9435V67.8117H41.04V47.9435C41.04 40.0257 34.6751 33.6094 26.8255 33.6094Z"
        fill={color}
      />
    </svg>
  );
};

MemberIcon.defaultProps = {
  width: "12",
  height: "20",
  color: "#fff",
};

MemberIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};
