import React from "react";
import { ConfirmationDialog } from "../../../../dialogs/confirmation-dialog";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { GET_USER_CHATS } from "../../../../../apollo-client/queries";
import { showSuccessToast } from "../../../../../toast/toast-service";
import { BLOCK_CHAT } from "./chat-header-mutations";

export const ChatBlockDialog = ({
  isOpen,
  toggle,
  chosenChat,
  haveIBlocked,
}) => {
  const [blockChat] = useMutation(BLOCK_CHAT, {
    variables: { chatId: chosenChat.chatId, block: !haveIBlocked },
    refetchQueries: [GET_USER_CHATS, "getUserChats"],
    onCompleted: () => {
      showSuccessToast(
        haveIBlocked ? (
          <FormattedMessage id="chat.unblock-chat.successful" />
        ) : (
          <FormattedMessage id="chat.block-chat.successful" />
        )
      );
      toggle();
    },
  });
  return (
    <ConfirmationDialog
      title={
        haveIBlocked ? (
          <FormattedMessage id="chat.unblock-chat" />
        ) : (
          <FormattedMessage id="chat.block-chat" />
        )
      }
      isOpen={isOpen}
      toggle={toggle}
      content={
        haveIBlocked ? (
          <FormattedMessage id="chat.unblock-chat.text" />
        ) : (
          <FormattedMessage id="chat.block-chat.text" />
        )
      }
      action={blockChat}
    />
  );
};
