export const mergeNewData = (existing, incoming, fieldName) => {
  if (!incoming) return existing;
  if (!existing) return incoming;

  // Ich hab keine Ahnung wieso ich dieses Spreading hier gemacht habe ..
  const { teachings, ...rest } = incoming;
  let result = rest;

  result[fieldName] = [...existing[fieldName]];
  incoming[fieldName].forEach((newData) => {
    if (
      !existing[fieldName]
        .map((existingData) => existingData.__ref)
        .includes(newData.__ref)
    ) {
      result[fieldName].push(newData);
    }
  });

  return result;
};

export const mergeArrays = (existing, incoming) => {
  if (!incoming) return existing;
  if (!existing) return incoming;

  return incoming.filter((object) => !existing.includes(object));
};
