import React, { useState } from "react";
import styled from "styled-components";
import { ProfilePicture } from "../../../components/common/profile-picture";
import { Date } from "../../../components/common/date";
import { TextWithToggle } from "./TextWithToggle";
import { EditText } from "./edit-text";
import { TeacherSettings } from "./TeacherSettings";

export const Comment = ({ editMutation, deleteComment, commentObject }) => {
  const [toggleEdit, setToggleEdit] = useState(false);
  const [editComment, setEditComment] = useState(comment);

  const { _id, comment, createdAt, isContributor } = commentObject;

  const { profilePicture, username } = commentObject.contributor;

  const handleKeyUp = () =>
    setEditComment(document.getElementById("edit_text_" + _id).innerText);
  const setToggle = () => setToggleEdit(!toggleEdit);

  return (
    <Container>
      <HeaderWrapper>
        <IconUsernameWrapper>
          <ProfilePicture src={profilePicture} width={40} height={40} />
          <Username>{username}</Username>
        </IconUsernameWrapper>
        <RightSettingsWrapper>
          <Date unixTimeStamp={createdAt} />
          <SettingsWrapper>
            <TeacherSettings
              toggleEdit={() => setToggleEdit(!toggleEdit)}
              deleteMutation={deleteComment}
              isContributor={isContributor}
              pointIcon={!isContributor}
              reportId={_id}
              contentType={"comment"}
              mirrorSite
            />
          </SettingsWrapper>
        </RightSettingsWrapper>
      </HeaderWrapper>

      <TextWithToggleWrapper>
        {toggleEdit ? (
          <EditText
            id={_id}
            onKeyUp={handleKeyUp}
            text={comment}
            toggleEdit={toggleEdit}
            setToggleEdit={setToggle}
            editMutation={() =>
              editMutation({ variables: { _id, comment: editComment } })
            }
          />
        ) : (
          <TextWithToggle text={comment} cutPoint={200} />
        )}
      </TextWithToggleWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const Username = styled.div`
  font-weight: 600;
  font-size: ${(props) => props.theme.smallHeaderFontSize};
  margin-left: 10px;
  color: ${(props) => props.theme.secondaryHeadlineColor};
`;

const IconUsernameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const RightSettingsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const SettingsWrapper = styled.div`
  margin-left: 10px;
`;

const TextWithToggleWrapper = styled.div`
  margin-left: 50px;
`;
