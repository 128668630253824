import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Tooltip as MuiTooltip } from "@mui/material";
import { ClickAwayListener } from "@mui/material";
import { useOpenClose } from "../../../hooks/use-open-close";
import { useIsMobile } from "../../../hooks/state-checkers/use-is-mobile";
import { useIntl } from "react-intl";

export const Tooltip = ({ tooltipText, tooltipId, children }) => {
  const { toggle, close, value: isOpen } = useOpenClose(false);
  const isMobile = useIsMobile();
  const intl = useIntl();

  let tooltip = "";

  if (tooltipId) {
    tooltip = intl.formatMessage({ id: tooltipId });
  }

  if (tooltipText) {
    tooltip = tooltipText;
  }

  if (isMobile) {
    return (
      <ClickAwayListener onClickAway={close}>
        <div>
          <StyledTooltip
            title={tooltip}
            arrow
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={isOpen}
            onClose={close}
          >
            <div onClick={toggle}>{children}</div>
          </StyledTooltip>
        </div>
      </ClickAwayListener>
    );
  } else {
    return (
      <StyledTooltip title={tooltip} arrow>
        {children}
      </StyledTooltip>
    );
  }
};

const StyledTooltip = withStyles({
  tooltip: {
    maxWidth: 150,
    fontSize: "14px",
    textAlign: "center",
  },
})(MuiTooltip);
