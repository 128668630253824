import React, { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { getSelectedContent } from "../helpers";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  margin-top: 20px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

const Text = styled.div`
  margin: 10px;
  max-height: 25px;
  min-height: 25px;
  max-width: 65px;
  min-width: 65px;
  font-size: ${(props) => props.standardFontSize + "px"};
  transition: font-size 0.1s ease-in;

  &:hover {
    font-size: 24px;
  }
`;

const Hr = styled.hr`
  height: 1px;
  width: 100%;
`;

export const NavbarContent = (props) => {
  //könnte es so umschreiben, dass nur prop active übergeben wird und mit switch case dann die Werte bestimmt werden
  //z.B. active={"videos"} => bei case "videos" const questions = 20; const links = 20; const videos = 24; const books = 20;
  const location = useLocation();

  const handleStartFontSize = (contentType) => {
    if (getSelectedContent(location.pathname) === contentType) return 24;
    else return 15;
  };

  const [questions, setQuestions] = useState(handleStartFontSize("questions"));
  const [links, setLinks] = useState(handleStartFontSize("links"));
  const [videos, setVideos] = useState(handleStartFontSize("videos"));
  const [books, setBooks] = useState(handleStartFontSize("books"));
  let biggerFontSize = 24;

  useLayoutEffect(() => {
    if (getSelectedContent(location.pathname) === "videos") setVideos(24);
    else if (getSelectedContent(location.pathname) === "questions")
      setQuestions(24);
    else setLinks(24);
  }, []);

  return (
    <Container id="navbar_content_container">
      <StyledLink
        to={"/courses/" + props.match.params.id + "/content/questions"}
      >
        <Text standardFontSize={questions}>Fragen</Text>
      </StyledLink>
      <Hr />
      <StyledLink
        to={"/courses/" + props.match.params.id + "/content/links"}
        onClick={() => setLinks(24)}
      >
        <Text standardFontSize={links}>Links</Text>
      </StyledLink>
      <Hr />
      {/*
      <Link
        to={"/courses/" + props.match.params.id + "/content/literature"}
        style={style.link}
      >
        <div

        >
          Literatur
        </div>
      </Link>
      <hr style={style.hr} />
      */}
      <StyledLink to={"/courses/" + props.match.params.id + "/content/videos"}>
        <Text standardFontSize={videos}>Videos</Text>
      </StyledLink>
    </Container>
  );
};
