import React, { useState, useEffect, Fragment } from "react";
import { useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";
import { IoMdArrowDropdown } from "react-icons/io";
import magnifying_glass from "../../../../img/search.png";
import UniIcon from "../../../../img_new/Uni_Icon.svg";
import {
  GET_COURSE_SUBSCRIPTION,
  GET_TEACHINGS,
  GET_USER_COURSES,
  GET_USER_HEADER,
} from "../../../../apollo-client/queries";
import { REMOVE_MY_COURSES } from "../../../../apollo-client/mutations";
import FuzzySearch from "fuzzy-search";
import MyCourse from "./my-course";
import { useIntl } from "react-intl";
import MyCourseSearchCourseHeadline from "./my-course-search-course-headline";
import Skeleton from "@mui/material/Skeleton";
import my_courses_icon from "../../../../img_new/My_Courses_Icon.svg";
import { useStore } from "../../../../store/store";
import { ArrowDown, ArrowLeft } from "../../../common/arrow";
import { useIsMobile } from "../../../../hooks/state-checkers/use-is-mobile";
import { useOutsideEvent } from "../../../../helpers/useOutsideEvent";

export default function MyCoursesNav({ toggle }) {
  const [universityList, setUniversityList] = useState([]);
  const selectedUniversity = useStore((state) => state.myCourseNavSelection);
  const [courseTitleFilter, setCourseTitleFilter] = useState("");
  const [isUniversityDropdownOpen, setIsUniversityDropdownOpen] =
    useState(false);

  const intl = useIntl();
  const placeholderSearch = intl.formatMessage({
    id: "general.placeholder-search",
  });
  const myCoursesHeadline = intl.formatMessage({ id: "courses-nav.title" });

  const isMobile = useIsMobile();

  const ref = useOutsideEvent(toggle);

  const universityDropdownRef = useOutsideEvent(() => {
    setIsUniversityDropdownOpen(false);
  });

  const { data: userCoursesData, loading, error } = useQuery(GET_USER_COURSES);

  const [removeMyCourses] = useMutation(REMOVE_MY_COURSES, {
    refetchQueries: [
      GET_COURSE_SUBSCRIPTION,
      GET_USER_HEADER,
      // for refetching subsriber count
      GET_TEACHINGS,
    ],
  });

  const fillUnisToDropdown = () => {
    let universityArray = [];
    userCoursesData.user.universities.forEach((university) =>
      universityArray.push(university.name)
    );
    userCoursesData.user.courses.courses.forEach((course) => {
      if (!universityArray.includes(course?.university)) {
        universityArray.push(course?.university);
      }
    });
    setUniversityList(universityArray);
  };

  const SetUniCourses = (university) => {
    let selectedUniversityCourses = [];
    userCoursesData.user.courses.courses
      .filter((course) => course?.university === university)
      .forEach((course) => selectedUniversityCourses.push(course));

    useStore.setState({
      myCourseNavSelection: {
        university: university,
        courses: selectedUniversityCourses,
      },
    });
  };

  useEffect(() => {
    if (userCoursesData && userCoursesData.user.universities[0]) {
      SetUniCourses(
        selectedUniversity.university === ""
          ? userCoursesData.user.universities[0].name
          : selectedUniversity.university
      );
      fillUnisToDropdown();
    }
  }, [userCoursesData]);

  let searcher;
  if (userCoursesData !== "")
    searcher = new FuzzySearch(selectedUniversity.courses, ["title"]);

  return (
    <Container id={"my_courses_nav_container"} ref={ref}>
      <Header>
        {isMobile && <ArrowLeft onClick={toggle} />}
        <MyCourseSearchCourseHeadline
          headlineText={myCoursesHeadline}
          imgSrc={my_courses_icon}
        />
        {!isMobile && (
          <RightHeaderWrapper>
            <ArrowDown onClick={toggle} />
          </RightHeaderWrapper>
        )}
      </Header>
      {loading && (
        <MyCoursesContentLoadingWrapper>
          <UniversitySkeleton variant="rect" height={66} animation="wave" />
          <MyCoursesSkeleton variant="rect" height={700} animation="wave" />
        </MyCoursesContentLoadingWrapper>
      )}
      {!loading && (
        <Fragment>
          <UniversityDropdownContainer ref={universityDropdownRef}>
            <UniversityDropdown>
              <UniverityRow
                onClick={() =>
                  setIsUniversityDropdownOpen(!isUniversityDropdownOpen)
                }
              >
                <UniversityImageNameWrapper>
                  <UniversityImage src={UniIcon} />
                  <UniversityName>
                    {selectedUniversity.university}
                  </UniversityName>
                </UniversityImageNameWrapper>
                <IoMdArrowDropdown
                  size={32}
                  style={{ marginRight: 5 }}
                  color={"#666666"}
                />
              </UniverityRow>
              <SearchBarWrapper>
                <MagnifyingGlass src={magnifying_glass} />
                <SearchInput
                  placeholder={placeholderSearch}
                  onChange={(event) => setCourseTitleFilter(event.target.value)}
                  // safari still suggest to autofill with password
                  type={"text"}
                  autoComplete={"off"}
                  name="notASearchField"
                />
              </SearchBarWrapper>

              {isUniversityDropdownOpen && (
                <UniversityDropdownBox>
                  {universityList.map((university, i) => (
                    <UniversityDropdownItem
                      key={university + "_item_" + i}
                      onClick={() => {
                        SetUniCourses(university);
                        setIsUniversityDropdownOpen(false);
                      }}
                    >
                      <UniversityDropdownItemImage
                        key={university + "_image_" + i}
                        src={UniIcon}
                      />
                      <UniversityDropdownItemText
                        key={university + "_text_" + i}
                      >
                        {university}
                      </UniversityDropdownItemText>
                    </UniversityDropdownItem>
                  ))}
                </UniversityDropdownBox>
              )}
            </UniversityDropdown>
          </UniversityDropdownContainer>
          <SemesterCourseWrapper>
            <Semester>Wintersemester 22/23</Semester>
            {!loading &&
              !error &&
              userCoursesData &&
              searcher
                .search(courseTitleFilter)
                .map((course, i) => (
                  <MyCourse
                    key={"my_course_" + i}
                    courseId={course._id}
                    course={course}
                    userId={userCoursesData.user._id}
                    userCourses={userCoursesData.user.courses.courses}
                    isCourseNotAdded={course.isCourseNotAdded}
                    i={i}
                    removeMyCourses={removeMyCourses}
                    closeCourseNav={toggle}
                  />
                ))}
            <Placeholder />
          </SemesterCourseWrapper>
        </Fragment>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 42%;
  min-width: 530px;
  max-width: 640px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: -1px 4px 12px rgba(0, 0, 0, 0.5);
  z-index: 13;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    min-width: 100%;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    justify-content: flex-start;
    width: 100%;
    padding-left: 10px;
  }
`;

const RightHeaderWrapper = styled.div`
  align-self: flex-start;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 30px;
  margin-top: 15px;
`;

const MagnifyingGlass = styled("img")`
  height: 16px;
  width: 16px;
  opacity: 0.6;
`;

const SearchInput = styled.input`
  width: 100%;
  border: transparent;
  background-color: transparent;
  margin-left: 5px;
  outline: none;
  color: #565656;

  &::placeholder {
    color: #565656;
  }

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}) {
    margin-left: 0;
  }
`;

const MyCoursesContentLoadingWrapper = styled.div`
  margin-top: 10px;
  padding: 10px;
`;

const UniversitySkeleton = styled(Skeleton)`
  margin-top: 10px;
  width: 91%;
  border-radius: 20px;
`;

const MyCoursesSkeleton = styled(Skeleton)`
  margin-top: 20px;
  border-radius: 20px;
  width: 91%;
`;

const UniversityDropdownContainer = styled.div`
  position: relative;
  min-height: 70px;
  margin-top: 10px;
  display: flex;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    justify-content: center;
    width: 100%;
  }
`;

const UniversityDropdown = styled.div`
  position: absolute;
  display: flex;
  flex-basis: content;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  z-index: 2;
  width: 310px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

const SearchBarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0 0.5px 0 0 ${(props) => props.theme.thirdColor};
  padding-bottom: 5px;
  color: #565656;
  font-weight: normal;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 40px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    width: 80%;
    margin-left: 0;
  }
`;

const UniversityImageNameWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const UniversityImage = styled("img")`
  height: 16px;
  width: 16px;
  margin-left: 10px;
`;

const UniversityName = styled.div`
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  margin-left: 7px;
  margin-right: 5px;
  margin-top: 2px;
`;

const UniversityDropdownBox = styled.div`
  position: absolute;
  width: 300px;
  max-height: 160px;

  top: 35px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.4);
  border-radius: 7px;
  background-color: white;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: transparent;
      border-radius: 0px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      -webkit-box-shadow: transparent;
      background-color: ${(props) => props.theme.scrollBarColor};
    }
  }
`;

const UniversityDropdownItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 20px;
  }
`;

const UniversityDropdownItemImage = styled("img")`
  height: 16px;
  width: 16px;
  margin-left: 10px;
`;

const UniversityDropdownItemText = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: #666666;
  margin-left: 5px;
  margin-top: 2px;
`;

const SemesterCourseWrapper = styled.div`
  overflow-y: scroll;
  //overflow-x: hidden;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent;
  }
  &:hover {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: transparent;
      border-radius: 0px;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      -webkit-box-shadow: transparent;
      background-color: #7f7f7f;
    }
  }
`;

const Semester = styled.div`
  box-sizing: border-box;
  width: 95%;
  border-radius: 12px;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  background-color: #eae1d1;
  margin-left: 5px;
  padding-left: 26px;
  color: #373737;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const Placeholder = styled.div`
  height: 50px;
`;

const UniverityRow = styled.div`
  display: flex;
  flex-direction: row;
`;
