import React from "react";
import { useRecoilState } from "recoil";
import { loginSidebarState } from "../store/atoms";
import { showInfoToast } from "../toast/toast-service";
import { FormattedMessage } from "react-intl";

export const usePleaseLoginInfo = () => {
  const [, setIsLoginSidebarOpen] = useRecoilState(loginSidebarState);

  const openPleaseLoginInfo = () => {
    showInfoToast(<FormattedMessage id="login.please-login" />);
    setIsLoginSidebarOpen(true);
  };

  const isLoggedIn = Boolean(localStorage.getItem("token"));

  return { openPleaseLoginInfo, isLoggedIn };
};
