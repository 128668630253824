import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import styled from "styled-components";
import { GET_USER_EDIT } from "../../../apollo-client/queries";
import { EDIT_USER_NAME } from "../../../apollo-client/mutations";
import { EditProfileUserData } from "../../edit-profile/edit-profile-user-data";
import { EditProfilePasswordForm } from "../../edit-profile/edit-profile-password-form";
import { useRouteMatch } from "react-router-dom";
import { MobileNavHeader } from "./mobile-nav-header";
import { FormattedMessage } from "react-intl";

//mit container um thumbnail mit min height usw und in thumbnail width und height auf 100% hat sich das dem Bild automatisch angepasst! gut oder sclecht?
export const EditProfileFields = (props) => {
  //QUERIES
  const { data, error, loading } = useQuery(GET_USER_EDIT);

  const [newUsername, setNewUsername] = useState(data && data.user.username);

  //MUTATIONS
  const [changeName] = useMutation(EDIT_USER_NAME, {
    update(cache, addContentLinkResponse) {
      setNewUsername("");
      document.getElementById("editable_div_new_username").innerText = "";
    },
  });

  const routeMatch = useRouteMatch();

  const isChangePwInUrl = (props) => {
    const splittedPath = routeMatch.path.split("/");
    return splittedPath[splittedPath.length - 1] === "change-pw";
  };

  return (
    <div>
      <MobileNavHeader
        text={
          isChangePwInUrl(props) ? (
            <FormattedMessage id={"edit-user-data.change-password"} />
          ) : (
            <FormattedMessage id={"edit-user-data-profile"} />
          )
        }
      />
      {!loading && data && (
        <Container>
          {!isChangePwInUrl(props) && (
            <EditProfileUserData user={data.user} props={props} />
          )}
          {isChangePwInUrl(props) && (
            <EditProfilePasswordForm
              user={data.user}
              props={props}
              newUsername={newUsername}
            />
          )}
        </Container>
      )}
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15%;
  margin-top: 120px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    margin-top: 0px;
    justify-content: center;
    width: 100%;
  }
`;
