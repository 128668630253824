import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { IntlProvider } from "react-intl";
import { RecoilRoot } from "recoil";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

import "./index.css";
import Routes from "./Routes";
import * as serviceWorker from "./serviceWorker";
import messages_de from "./locales/de";
import messages_en from "./locales/en";
import { ToastContainer, Flip } from "react-toastify";
import { muiTheme, theme } from "./theme";
import { mergeArrays, mergeNewData } from "./helpers/apollo-helpers";

const httpLink = new HttpLink({
  uri: "https://blagoom.de/graphql",
  // uri: "http://localhost:4000/graphql",
  headers: {
    authorization: localStorage.getItem("token"),
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    // url: "ws://localhost:4000/graphql",
    url: "wss://blagoom.de/graphql",
    connectionParams: () => ({
      authToken: localStorage.getItem("token"),
    }),
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Course: {
        fields: {
          teachings: {
            keyArgs: ["_id", "course"],
            merge(existing, incoming) {
              return mergeNewData(existing, incoming, "teachings");
            },
          },
        },
      },
      Teaching: {
        fields: {
          comments: {
            keyArgs: false,
            merge(existing, incoming) {
              return mergeNewData(existing, incoming, "comments");
            },
          },
        },
      },
      User: {
        fields: {
          notifications: {
            keyArgs: false,
            merge(existing, incoming) {
              if (!existing) {
                return incoming;
              }
              return [...existing, ...incoming];
            },
          },
          chats: {
            keyArgs: false,
            merge(existing, incoming) {
              return mergeNewData(existing, incoming, "chats");
            },
          },
        },
      },
      Chat: {
        fields: {
          messages: {
            keyArgs: false,
            merge(existing, incoming) {
              return mergeArrays(existing, incoming);
            },
          },
        },
      },
    },
  }),
});

const messages = {
  de: messages_de,
  en: messages_en,
};

const language = navigator.language.split(/[-_]/)[0];

ReactDOM.render(
  <ApolloProvider client={client}>
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={muiTheme}>
        <StyledComponentsThemeProvider theme={theme}>
          <IntlProvider
            locale={localStorage.getItem("language") || language}
            messages={messages[localStorage.getItem("language") || language]}
          >
            <RecoilRoot>
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                limit={2}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                transition={Flip}
              />
              <Routes />
            </RecoilRoot>
          </IntlProvider>
        </StyledComponentsThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
