import React from "react";
import styled from "styled-components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import arrow_back from "../../../img/Arrow_Vector.svg";
import { theme } from "../../../theme";
import { FormattedMessage } from "react-intl";

export const FAQStudentsAccordionSection = () => {
  return (
    <>
      <FaqSection>FAQs Student*innen</FaqSection>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowBack src={arrow_back} />}>
          <FormattedMessage id={"faq.students.find-teacher.title"} />
        </AccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.students.find-teacher.subtitle1"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.students.find-teacher.text1"} />
          </AccordionDetailsText>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.students.find-teacher.subtitle2"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.students.find-teacher.text2"} />
          </AccordionDetailsText>
        </StyledAccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowBack src={arrow_back} />}>
          <FormattedMessage id={"faq.students.find-courses.title"} />
        </AccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.students.find-courses.subtitle1"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.students.find-courses.text1"} />
          </AccordionDetailsText>
        </StyledAccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowBack src={arrow_back} />}>
          <FormattedMessage id={"faq.students.communication.title"} />
        </AccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.students.communication.subtitle1"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.students.communication.text1"} />
          </AccordionDetailsText>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.students.communication.subtitle2"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.students.communication.text2"} />
          </AccordionDetailsText>
        </StyledAccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowBack src={arrow_back} />}>
          <FormattedMessage id={"faq.students.manage-courses.title"} />
        </AccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.students.manage-courses.subtitle1"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.students.manage-courses.text1"} />
          </AccordionDetailsText>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.students.manage-courses.subtitle2"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.students.manage-courses.text2"} />
          </AccordionDetailsText>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.students.manage-courses.subtitle3"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.students.manage-courses.text3"} />
          </AccordionDetailsText>
        </StyledAccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowBack src={arrow_back} />}>
          <FormattedMessage id={"faq.students.payment.title"} />
        </AccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.students.payment.subtitle1"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.students.payment.text1"} />
          </AccordionDetailsText>
        </StyledAccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowBack src={arrow_back} />}>
          <FormattedMessage id={"faq.students.manage-profile.title"} />
        </AccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.students.manage-profile.subtitle1"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.students.manage-profile.text1"} />
          </AccordionDetailsText>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.students.manage-profile.subtitle2"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.students.manage-profile.text2"} />
          </AccordionDetailsText>
        </StyledAccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowBack src={arrow_back} />}>
          <FormattedMessage id={"faq.students.vacation-mode.title"} />
        </AccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.students.vacation-mode.subtitle1"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.students.vacation-mode.text1"} />
          </AccordionDetailsText>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.students.vacation-mode.subtitle2"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.students.vacation-mode.text2"} />
          </AccordionDetailsText>
        </StyledAccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowBack src={arrow_back} />}>
          <FormattedMessage id={"faq.students.subscribe.title"} />
        </AccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.students.subscribe.subtitle1"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.students.subscribe.text1"} />
          </AccordionDetailsText>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.students.subscribe.subtitle2"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.students.subscribe.text2"} />
          </AccordionDetailsText>
        </StyledAccordionDetails>
      </Accordion>
    </>
  );
};

const StyledAccordionDetails = styled(AccordionDetails)`
  background: #f7a6a7 !important;
  //border-radius: 20px !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
`;

const AccordionDetailsTitle = styled.div`
  color: white;
  font-weight: bold;
`;

const AccordionDetailsText = styled.div`
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ArrowBack = styled("img")`
  margin-top: 20px;
  transform: rotate(-90deg);
`;

const FaqSection = styled.p`
  font-weight: 700;
  font-size: 37px;
  color: ${theme.mainColor};
`;
