import Joi from "joi";
import { translateAndThrowError } from "../../services/joi-validation-helpers";

export const teachingValidators = {
  validatePrice: (price, intl) => {
    try {
      Joi.attempt(
        price,
        Joi.number()
          .min(0)
          .max(300)
          .required()
      );
    } catch (err) {
      translateAndThrowError(err, "teacher.form.errors", intl);
    }
  }
};
