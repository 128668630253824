import React, { useRef, useState, Fragment, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import Autocomplete from "@mui/material/Autocomplete";
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { Header } from "../../components/layout/header/header";
import { useIntl } from "react-intl";
import learn_group_image from "../../img/landing_page_learning_group.png";
import landing_page_background from "../../img/landing_page_background.png";
import teaching_group_image from "../../img/landing_page_teaching.png";
import landingPageChatAppPreviewImage from "../../img/landing_page.chat_app_preview.png";
import landingPageBlagLogoSquare from "../../img/landing_page.blag_logo_square.svg";

import { TextField, Button } from "@mui/material";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_COURSES, GET_UNIVERSITIES } from "../../apollo-client/queries";
import { ForgotPwDialog } from "./forgot-pw-dialog";
import { ResetPwDialog } from "./reset-pw-dialog";
import { CopyrightAboutUs } from "./copyright-about-us";
import { LoginSidebar } from "../../components/sidebars/login-sidebar/login-sidebar";

export const LandingPage = (props) => {
  const themeContext = useContext(ThemeContext);

  const [courseOptions, setCourseOptions] = useState([]);

  const [formValues, setFormValues] = useState({
    university: "",
    course: "",
  });

  const intl = useIntl();
  const routeMatch = useRouteMatch();
  const history = useHistory();

  const chooseUniDropdownRef = useRef(null);

  const { data: universitiesData, loading: loadingUniversites } = useQuery(
    GET_UNIVERSITIES,
    {
      variables: { limit: 999 },
    }
  );

  const [getCoursesData, { data: coursesData, loading: loadingCourses }] =
    useLazyQuery(GET_COURSES, {
      variables: {
        title: formValues.course,
        university: formValues.university,
        limit: 25,
      },
      onCompleted: (data) => {
        setCourseOptions(data.courses.courses);
      },
      fetchPolicy: "network-only",
    });
  const handleUniversityInputChange = async (event, value) => {
    const universityInput = value && value.name;

    setFormValues({
      ...formValues,
      university: universityInput,
    });

    await getCoursesData();
  };

  const handleCourseInputChanged = async (event, value) => {
    setFormValues({
      ...formValues,
      course: value,
    });

    await getCoursesData();
  };

  const handleCourseSelected = async () => {
    const selectedCourse = courseOptions.find(
      (course) => course.title === formValues.course
    );

    history.push(`/courses/${selectedCourse._id}/teachers`);
  };

  if (localStorage.getItem("token")) return <Redirect to={`/home`} />;

  if (!localStorage.getItem("token"))
    return (
      <Fragment>
        <LoginSidebar />
        <ForgotPwDialog
          isOpen={routeMatch.path === "/forgot-password"}
          toggleOpen={() => history.push("/")}
        />
        <ResetPwDialog
          isOpen={routeMatch.path === "/password-reset"}
          toggleOpen={() => history.push("/")}
        />
        <Container>
          <Header
            hideNavigation
            hideBurgerMenu
            color={themeContext.brightStuffColor}
            backgroundColor={"transparent"}
            position={"absolute"}
            props={props}
          />
          <MainContainer>
            <SloganBackgroundWrapper>
              <SloganButtonsWrapper>
                <Slogan>Nachhilfe und Lerngruppen in deinem Uni-Kurs </Slogan>
                <ButtonWrapper>
                  <ChoosingButtonWrapper>
                    <StyledAutocomplete
                      options={
                        (universitiesData && universitiesData.universities) ||
                        []
                      }
                      loading={loadingUniversites}
                      getOptionLabel={(option) => option.name}
                      onChange={handleUniversityInputChange}
                      renderInput={(params) => (
                        <InputTextField
                          variant="standard"
                          {...params}
                          label={intl.formatMessage({
                            id: "landing-page.choose-uni-button",
                          })}
                          inputRef={chooseUniDropdownRef}
                        />
                      )}
                    />
                    {formValues.university && (
                      <StyledAutocomplete
                        options={courseOptions}
                        loading={loadingCourses}
                        filterOptions={(options) => options}
                        getOptionLabel={(option) => option.title}
                        onInputChange={handleCourseInputChanged}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option._id}>
                              {option.title}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <InputTextField
                            variant="standard"
                            {...params}
                            label={intl.formatMessage({
                              id: "landing-page.choose-course-button",
                            })}
                            inputRef={chooseUniDropdownRef}
                          />
                        )}
                      />
                    )}
                    {formValues.course && (
                      <ContinueToCourseButton onClick={handleCourseSelected}>
                        Weiter
                      </ContinueToCourseButton>
                    )}
                  </ChoosingButtonWrapper>
                </ButtonWrapper>
              </SloganButtonsWrapper>

              <TopBackgroundImage src={landing_page_background} />
            </SloganBackgroundWrapper>
          </MainContainer>
          <FurtherInformationContainer>
            <CenteredDescription>
              Wähle deinen Uni-Kurs und...
            </CenteredDescription>
            <ImageTextWrapper>
              <Image src={learn_group_image} />
              <DescriptonWrapper>
                <Description>
                  ...trete einer <FatRed>Lerngruppe </FatRed> bei, oder gründe
                  eine neue!
                </Description>
              </DescriptonWrapper>
            </ImageTextWrapper>
            <ImageTextWrapperWeb>
              <DescriptonWrapper>
                <Description>
                  ...nimm <FatRed>Nachhilfe</FatRed>! Oder, verdiene dir etwas
                  dazu und gebe selbst Nachhilfe! Egal ob Student, Alumni, oder
                  einfach nur top in speziellen Bereichen.
                </Description>
              </DescriptonWrapper>
              <Image src={teaching_group_image} />
            </ImageTextWrapperWeb>
            <ImageTextWrapperMobile>
              <Image src={teaching_group_image} />
              <DescriptonWrapper>
                <Description>
                  ...nimm <FatRed>Nachhilfe</FatRed>! Oder, verdiene dir etwas
                  dazu und gebe selbst Nachhilfe! Egal ob Student, Alumni, oder
                  einfach nur top in speziellen Bereichen.
                </Description>
              </DescriptonWrapper>
            </ImageTextWrapperMobile>
            <ImageTextWrapper>
              <ChatImage src={landingPageChatAppPreviewImage} />
              <DescriptonWrapper>
                <ChatAppDescription>
                  Und das alles ganz einfach über unseren <FatRed>Chat</FatRed>.
                  Auch auf deinem Smartphone!
                </ChatAppDescription>
              </DescriptonWrapper>
              <BottomBlagLogo src={landingPageBlagLogoSquare} />
            </ImageTextWrapper>
          </FurtherInformationContainer>
          <StyledCopyrightAboutUs />
        </Container>
      </Fragment>
    );
  else return <Redirect to={`/courses/5c1eb802c5faf5136fec6d9d/teachers`} />;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  //background: linear-gradient(190.74deg, #c23d3e -8%, #ffffff 53.5%);

  @media only screen and (max-width: 980px) {
    width: 100%;
  }
`;

const MainContainer = styled.div``;

const SloganBackgroundWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
  max-width: 100%;
`;

const SloganButtonsWrapper = styled.div`
  width: 40%;

  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;

  @media only screen and (max-width: 980px) {
    width: 95%;
    margin-top: 70px;
  }
`;

const Slogan = styled.div`
  font-weight: 700;
  font-size: 45px;
  line-height: 50px;
  color: white;

  z-index: 3;
  text-align: center;
  position: relative;
  top: 60px;

  @media only screen and (max-width: 980px) {
    font-size: 40px;
    line-height: 55px;
    width: 80%;
  }
`;

const TopBackgroundImage = styled.img`
  min-width: 100%;
  min-height: 100vh;
  z-index: 1;
  pointer-events: none;
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  z-index: 3;

  margin-top: 100px;
  left: -300px;

  @media only screen and (max-width: 980px) {
    //position: absolute;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    left: 0;
  }

  @media only screen and (max-width: 400px) {
    margin-top: 60px;
  }
`;

const ChoosingButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledAutocomplete = styled(Autocomplete)`
  && {
    width: 30vw;
    min-width: 200px;
    position: relative;
    z-index: 7;

    @media only screen and (max-width: 980px) {
      width: 90vw;
    }
  }
`;

const InputTextField = styled(TextField)`
  && {
    position: relative;
    z-index: 10;

    && .MuiInput-root {
      color: white;
    }

    && .MuiInput-root::before {
      border-color: white;
    }

    && .MuiInput-root::after {
      border-color: white;
    }

    && .MuiFormLabel-root {
      color: white;
    }
    && .MuiIconButton-root {
      color: white !important;
    }
  }
`;

const ContinueToCourseButton = styled(Button)`
  && {
    max-width: 150px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    margin-top: 20px;
    background-color: ${(props) => props.theme.brightStuffColor};
    font-weight: 600;
    font-size: 18px;
    padding: 5px;
    min-height: 41px;

    text-transform: none;

    &:hover {
      background-color: ${(props) => props.theme.hoverBrightStuffColor};
    }
  }
`;

const TextButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FurtherInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageTextWrapper = styled.div`
  display: flex;
  margin-top: 50px;

  width: 100%;

  &:last-child {
    margin-top: 100px;
  }

  @media only screen and (max-width: 980px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:last-child {
      margin: 0px;
    }
  }
`;

const ImageTextWrapperWeb = styled(ImageTextWrapper)`
  @media only screen and (max-width: 980px) {
    display: none;
  }
`;

const ImageTextWrapperMobile = styled(ImageTextWrapper)`
  @media only screen and (min-width: 980px) {
    display: none;
  }
`;

const Image = styled.img`
  width: 60%;

  @media only screen and (max-width: 980px) {
    width: 100%;
  }
`;

const ChatImage = styled(Image)`
  @media only screen and (max-width: 980px) {
    max-width: 450px;
  }
`;

const DescriptonWrapper = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 980px) {
    margin-left: 4%;
    margin-right: 4%;
    width: 100%;
  }

  @media only screen and (max-width: 980px) {
    margin-left: 4vw;
    margin-right: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

const Description = styled.p`
  margin-top: 50px;

  font-size: 34px;

  color: #3d3d3d;

  @media only screen and (max-width: 980px) {
    width: 80%;
  }
`;

const FatRed = styled.span`
  font-size: 34px;
  font-weight: 600;
  color: #c23d3e;
  display: inline;
`;

const BottomBlagLogo = styled.img`
  width: 10%;
  align-self: flex-start;
  margin-top: 130px;
  margin-right: 50px;

  @media only screen and (max-width: 980px) {
    display: none;
  }
`;

const ChatAppDescription = styled(Description)`
  margin-top: 200px;

  @media only screen and (max-width: 980px) {
    margin-top: 10px;
  }
`;

const CenteredDescription = styled.div`
  margin-top: 50px;
  width: 40%;
  font-size: 34px;
  align-self: center;
  color: #3d3d3d;

  @media only screen and (max-width: 980px) {
    width: 100%;

    justify-self: center;
    align-self: center;
    text-align: center;
  }
`;

const StyledCopyrightAboutUs = styled(CopyrightAboutUs)`
  margin-top: 200px;
`;
