import styled from "styled-components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import arrow_back from "../../../img/Arrow_Vector.svg";
import React from "react";
import { theme } from "../../../theme";
import { FormattedMessage } from "react-intl";

export const FAQTeachersAccordionSection = () => {
  return (
    <>
      <FaqSection>FAQs Lehrer*innen</FaqSection>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowBack src={arrow_back} />}>
          <FormattedMessage id={"faq.tutors.offering-tutoring.title"} />
        </AccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.tutors.offering-tutoring.subtitle1"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.tutors.offering-tutoring.text1"} />
          </AccordionDetailsText>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.tutors.offering-tutoring.subtitle2"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.tutors.offering-tutoring.text2"} />
          </AccordionDetailsText>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.tutors.offering-tutoring.subtitle3"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.tutors.offering-tutoring.text3"} />
          </AccordionDetailsText>
        </StyledAccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowBack src={arrow_back} />}>
          <FormattedMessage id={"faq.tutors.communication.title"} />
        </AccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.tutors.communication.subtitle1"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.tutors.communication.text1"} />
          </AccordionDetailsText>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.tutors.communication.subtitle2"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.tutors.communication.text2"} />
          </AccordionDetailsText>
        </StyledAccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowBack src={arrow_back} />}>
          <FormattedMessage id={"faq.tutors.find-courses-to-tutor.title"} />
        </AccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsTitle>
            <FormattedMessage
              id={"faq.tutors.find-courses-to-tutor.subtitle1"}
            />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.tutors.find-courses-to-tutor.text1"} />
          </AccordionDetailsText>
        </StyledAccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowBack src={arrow_back} />}>
          <FormattedMessage id={"faq.tutors.manage-my-courses.title"} />
        </AccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.tutors.manage-my-courses.subtitle1"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.tutors.manage-my-courses.text1"} />
          </AccordionDetailsText>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.tutors.manage-my-courses.subtitle2"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.tutors.manage-my-courses.text2"} />
          </AccordionDetailsText>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.tutors.manage-my-courses.subtitle3"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.tutors.manage-my-courses.text3"} />
          </AccordionDetailsText>
        </StyledAccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowBack src={arrow_back} />}>
          <FormattedMessage id={"faq.tutors.payment.title"} />
        </AccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.tutors.payment.subtitle1"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.tutors.payment.text1"} />
          </AccordionDetailsText>
        </StyledAccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowBack src={arrow_back} />}>
          <FormattedMessage id={"faq.tutors.manage-my-profile.title"} />
        </AccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.tutors.manage-my-profile.subtitle1"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.tutors.manage-my-profile.text1"} />
          </AccordionDetailsText>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.tutors.manage-my-profile.subtitle2"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.tutors.manage-my-profile.text2"} />
          </AccordionDetailsText>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.tutors.manage-my-profile.subtitle3"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.tutors.manage-my-profile.text3"} />
          </AccordionDetailsText>
        </StyledAccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowBack src={arrow_back} />}>
          <FormattedMessage id={"faq.tutors.vacation-mode.title"} />
        </AccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.tutors.vacation-mode.subtitle1"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.tutors.vacation-mode.text1"} />
          </AccordionDetailsText>
          <AccordionDetailsTitle>
            <FormattedMessage id={"faq.tutors.vacation-mode.subtitle2"} />
          </AccordionDetailsTitle>
          <AccordionDetailsText>
            <FormattedMessage id={"faq.tutors.vacation-mode.text2"} />
          </AccordionDetailsText>
        </StyledAccordionDetails>
      </Accordion>
    </>
  );
};

const StyledAccordionDetails = styled(AccordionDetails)`
  background: #f7a6a7 !important;
  //border-radius: 20px !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
`;

const AccordionDetailsTitle = styled.div`
  color: white;
  font-weight: bold;
`;

const AccordionDetailsText = styled.div`
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ArrowBack = styled("img")`
  margin-top: 20px;
  transform: rotate(-90deg);
`;

const FaqSection = styled.p`
  font-weight: 700;
  font-size: 37px;
  color: ${theme.mainColor};
`;
