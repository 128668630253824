import React from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NameSettingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: #545454;
  font-weight: 500;
  font-size: 22px;
`;

const ProfileText = styled.div`
  font-weight: 500;
  font-size: 28px;
  color: #545454;
`;

const HoverText = styled.div`
  position: absolute;
  display: ${props =>
    props.isDragAccept || props.isDragReject || props.isDragActive
      ? "flex"
      : "none"};
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 100px;
  outline: none;
  cursor: pointer;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
`;

const ProfilePictureHoverTextWrapper = styled.div`
  outline: none;
  position: relative;
  height: 140px;
  width: 140px;
  margin-top: 20px;

  &:hover ${HoverText} {
    display: flex;
  }
  
  &${props => !props.isDragActive} ${HoverText} {
    display: flex;
`;

const ProfilePicture = styled("img")`
  border-radius: 100px;
`;

const NameWrapper = styled.div`
  min-height: 50px;
  width: 190px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  margin-top: 20px;
`;

const Name = styled.div`
  padding: 10px 0;
  cursor: text;
  min-width: 30px;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #545454;

  &[contentEditable="true"]:empty:before {
    content: attr(placeholder);
  }

  &[contentEditable]:focus {
    outline: 0 solid transparent;
  }
`;

export const ProfilePhotoWithName = ({
  username,
  newUsername,
  setNewUsername,
  changeName,
  userPicture,
  hidePhoto,
  hideName,
  props
}) => {
  const intl = useIntl();
  const addNewPictureText = intl.formatMessage({
    id: "upload-image-component.add-new-picture"
  });

  const goToChangeProfilePhotoPage = () => {
    props.history.push("/profile/edit/add-photo");
  };

  return (
    <Container>
      <NameSettingsWrapper>
        <ProfileText>
          <FormattedMessage id={"edit-user-data-profile"} />
        </ProfileText>
      </NameSettingsWrapper>
      {!hidePhoto && (
        <ProfilePictureHoverTextWrapper onClick={goToChangeProfilePhotoPage}>
          <ProfilePicture
            src={
              userPicture ||
              "https://storage.googleapis.com/blag-profile-pictures/default-profile-picture.png"
            }
            style={{ width: 140, height: 140 }}
          />
          <HoverText>{addNewPictureText}</HoverText>
        </ProfilePictureHoverTextWrapper>
      )}

      {!hideName && (
        <NameWrapper>
          <Name
            id={"editable_div_new_username"}
            contentEditable={true}
            onKeyDown={event => {
              if (event.keyCode === 13) {
                event.preventDefault();
                changeName({
                  variables: { username: newUsername }
                });
              }
            }}
            onKeyUp={event => {
              setNewUsername(
                document.getElementById("editable_div_new_username").innerText
              );
            }}
          >
            {username}
          </Name>
        </NameWrapper>
      )}
    </Container>
  );
};
