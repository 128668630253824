import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../../theme";

export const BlagoomStandardLogo = ({
  width,
  height,
  color,
  bigBColor,
  fill,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 152 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.9985 13.0045C50.8957 13.2103 50.8614 18.6627 50.8957 25.0753L50.9985 36.7347L51.7873 36.8376L52.5417 36.9404V24.7667C52.5417 12.7302 52.5417 12.5587 51.8559 12.5587C51.4786 12.5587 51.0671 12.7645 50.9985 13.0045Z"
        fill={color}
      />
      <path
        d="M88.0175 22.7126L86.9545 23.5699L84.9998 23.0212C79.6845 21.4781 75.2266 24.7358 75.2266 30.1197C75.2609 34.7834 78.3129 37.4239 83.3881 37.1839C85.8228 37.081 85.9943 37.1153 86.6458 38.0069C87.0231 38.5212 87.4003 39.6529 87.4689 40.5788C87.7432 43.6651 85.6857 45.8255 82.4965 45.8255C79.5474 45.8255 77.627 43.9051 77.627 40.9217C77.627 39.5843 77.5241 39.31 76.9755 39.31C75.7067 39.31 75.5352 41.7447 76.6325 44.008C77.6613 46.1684 79.6502 47.2314 82.5651 47.2314C84.9998 47.2314 86.2 46.7856 87.6403 45.2425C88.7377 44.0766 89.2863 42.7392 89.2863 41.1617C89.2863 39.8243 88.5319 37.7668 87.6746 36.8409C87.1602 36.258 87.1602 36.1551 87.8461 35.435C89.7664 33.4117 90.3151 28.8852 88.9777 26.2104L88.2919 24.8044L89.1492 23.6728C89.6293 23.0555 89.9379 22.3696 89.8007 22.1639C89.4921 21.6838 89.252 21.7524 88.0175 22.7126ZM85.377 24.7701C87.9147 25.9703 88.9777 29.9825 87.5031 32.7602C86.4401 34.7834 85.3427 35.5378 83.0795 35.7436C79.3759 36.1208 76.9412 33.8575 76.9412 30.0511C76.9412 26.3818 78.9644 24.2214 82.325 24.2214C83.3538 24.2214 84.7255 24.4615 85.377 24.7701Z"
        fill={color}
      />
      <path
        d="M61.3741 23.0163C56.2989 24.8338 55.0644 32.1037 59.2137 35.7729C61.4427 37.7276 65.6606 37.8305 67.9925 35.9444C68.7469 35.3271 69.4328 34.8471 69.5357 34.8471C69.6385 34.8471 69.7414 35.2929 69.7414 35.8758C69.7414 36.6645 69.8786 36.9046 70.4273 36.9046C71.0788 36.9046 71.1131 36.6645 71.1131 32.3437C71.1131 28.6745 70.9759 27.5428 70.4615 26.4112C69.0213 23.2906 64.9405 21.7475 61.3741 23.0163ZM66.9295 24.868C68.7469 25.7939 69.5699 27.3714 69.5699 29.8747C69.5699 33.5097 67.7525 35.5672 64.3575 35.7729C60.6197 36.013 58.425 33.8183 58.425 29.8404C58.425 27.3714 59.3166 25.7596 61.1684 24.8338C62.7458 24.0107 65.3177 24.045 66.9295 24.868Z"
        fill={color}
      />
      <path
        d="M98.6747 22.8215C95.5541 23.6102 93.3594 26.5251 93.3594 29.92C93.3594 31.6689 94.3196 34.3094 95.314 35.2696C98.5032 38.3216 104.401 37.8415 106.802 34.3437C107.934 32.6977 108.345 29.8857 107.796 27.7253C107.351 26.1136 105.773 24.0903 104.299 23.3702C103.133 22.7529 100.046 22.4443 98.6747 22.8215ZM103.921 24.9133C105.567 25.9421 106.528 28.274 106.288 30.7087C106.082 32.6977 105.43 33.8293 103.853 34.9952C102.515 36.024 98.949 36.024 97.6116 34.9952C95.1769 33.212 94.4224 30.0915 95.6912 27.2109C96.6514 25.0505 98.0574 24.2275 100.732 24.2275C102.207 24.2275 103.133 24.4333 103.921 24.9133Z"
        fill={color}
      />
      <path
        d="M116.524 22.8138C114.981 23.1911 114.158 23.7397 112.992 25.0428C110.729 27.649 110.66 31.9013 112.82 34.8161C115.152 37.8681 121.016 38.1081 124 35.2619C125.886 33.4787 126.434 28.8493 125.097 26.3116C124.583 25.2829 123.04 23.8083 121.976 23.2253C120.982 22.7453 117.793 22.4709 116.524 22.8138ZM121.736 24.9057C123.417 25.9344 124.343 28.0262 124.171 30.461C124 32.5871 123.382 33.753 121.702 34.9876C120.365 36.0163 116.798 36.0163 115.461 34.9876C113.781 33.753 113.163 32.5871 112.992 30.4953C112.855 27.9577 113.884 25.6944 115.667 24.8371C117.313 23.9798 120.33 24.0141 121.736 24.9057Z"
        fill={color}
      />
      <path
        d="M133.655 22.8827C132.351 23.2599 130.603 25.0774 130.088 26.5863C129.882 27.2378 129.711 29.8097 129.711 32.3131V36.9082H130.568H131.426V32.5188C131.426 27.5122 131.768 25.9004 133.037 24.906C134.135 24.0487 137.084 23.9458 138.215 24.7688C139.724 25.8318 139.999 26.9292 139.999 32.1073V36.9082H140.856H141.713V32.2788C141.713 25.6261 142.468 24.2201 146.068 24.2201C147.577 24.2201 148.057 24.3916 148.983 25.2146L150.115 26.2091L150.286 31.49C150.458 36.5996 150.492 36.7367 151.246 36.8396C152.001 36.9425 152.001 36.9425 152.001 32.0387C151.967 25.3518 151.246 23.7743 147.749 22.8141C145.76 22.2655 143.599 22.7113 142.228 23.9458L141.027 24.9745L139.999 24.0829C138.284 22.677 135.918 22.1969 133.655 22.8827Z"
        fill={color}
      />
      <rect x="1" y="2.4458" width="43" height="43" rx="12" fill={fill} />
      <path
        d="M12.2344 16.415C12.2344 33.1426 12.5033 34.4873 16.2684 37.9834C18.8501 40.4038 20.9478 40.9955 25.4659 40.619C28.8544 40.35 32.2968 38.2523 33.9104 35.5092C35.255 33.1426 35.6853 28.4632 34.8247 25.3436C32.7808 18.1362 22.9379 15.1779 17.129 20.0187C16.2684 20.7717 15.4078 21.3634 15.2464 21.3634C15.0851 21.3634 14.9237 17.0067 14.9237 11.6818V2.00021H13.579H12.2344V16.415ZM28.5855 21.3096C31.221 22.8156 32.3505 24.8057 32.5657 28.5708C32.9422 34.3797 30.2529 37.7683 24.7666 38.3599C21.4857 38.7364 17.8282 36.9615 16.3222 34.3259C14.9775 32.0131 14.8699 26.6882 16.1608 24.2678C18.2585 20.2338 24.175 18.7816 28.5855 21.3096Z"
        fill={bigBColor}
      />
    </svg>
  );
};

BlagoomStandardLogo.defaultProps = {
  width: "152",
  height: "48",
  color: "#666666",
  bigBColor: "white",
  fill: theme.mainColor,
};

BlagoomStandardLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  bigBColor: PropTypes.string,
  fill: PropTypes.string,
};
