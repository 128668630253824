import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { cutString } from "../../../../helpers";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PictureCountWrapper = styled.div`
  position: relative;
`;

const Picture = styled("img")`
  border-radius: 100px;
`;

const MessageCount = styled.div`
  font-size: 0.7em;
  color: #ffffff;
  width: 20px;
  height: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid black;
  border-radius: 100%;
  top: -5px;
  right: -5px;
  cursor: default;
  background-color: #db3737;
`;

const Username = styled.div`
  opacity: ${(props) => (props.chosenChatIsOpen ? 0 : 1)};
  transition: opacity 300ms ease-in;
  font-weight: 600;
  font-size: 15px;
  color: #383838;
  width: 80%;
  text-align: center;
  cursor: default;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-word;

  ${Container}:hover & {
    visibility: visible;
  }
`;

export const ChatProfilePicture = ({
  src,
  width,
  height,
  chatTitle,
  chosenChatIsOpen,
  onClick,
  unreadMessagesCount,
  isBlocked,
}) => {
  const pictureOpacity = isBlocked ? 0.25 : 1;

  return (
    <Container onClick={onClick}>
      <PictureCountWrapper>
        <Picture
          src={
            src ||
            "https://storage.googleapis.com/blag-profile-pictures/default-profile-picture.png"
          }
          style={{ width: width, height: height, opacity: pictureOpacity }}
        />
        {unreadMessagesCount > 0 && unreadMessagesCount < 100 && (
          <MessageCount>{unreadMessagesCount}</MessageCount>
        )}
        {unreadMessagesCount > 99 && <MessageCount>99+</MessageCount>}
      </PictureCountWrapper>
      <Username chosenChatIsOpen={chosenChatIsOpen}>{chatTitle}</Username>
    </Container>
  );
};

ChatProfilePicture.defaultProps = {
  width: "39px",
  height: "39px",
  src: "https://storage.googleapis.com/blag-profile-pictures/default-profile-picture.png",
  unreadMessagesCount: 0,
};

ChatProfilePicture.propTypes = {
  src: PropTypes.string,
  chatTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  unreadMessagesCount: PropTypes.number,
};
