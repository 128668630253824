import { gql, useMutation } from "@apollo/client";
import { ConfirmationDialog } from "../../components/dialogs/confirmation-dialog";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useRef, useState } from "react";
import { showErrorToast, showSuccessToast } from "../../toast/toast-service";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import styled from "styled-components";
import { errorConstants } from "../../error-constants";
import { useHistory } from "react-router-dom";

export const DELETE_USER = gql`
  mutation DeleteUserMutation($deleteUserPassword: String!) {
    deleteUser(password: $deleteUserPassword)
  }
`;

export const DeleteProfileDialog = ({ isOpen, toggle }) => {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [deleteAccountMutation] = useMutation(DELETE_USER, {
    variables: { deleteUserPassword: password },
  });

  const passwordRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();

  const handlePasswordInputChange = (event) => {
    const passportInputText = event.target.value;
    setPassword(passportInputText);
  };

  const deleteAccount = async () => {
    try {
      await deleteAccountMutation();
      localStorage.clear();
      showSuccessToast(
        <FormattedMessage id={"edit-user-data-delete-account-successfully"} />
      );
      history.push("/");
    } catch (err) {
      const graphQLErrorMessage = err?.graphQLErrors?.[0].message;
      const errorMessage = intl.formatMessage(
        {
          id: "login.error",
        },
        { error: graphQLErrorMessage }
      );

      if (graphQLErrorMessage === errorConstants.wrongPassword) {
        setPasswordError(errorMessage);
        passwordRef.current.focus();
      }

      showErrorToast(errorMessage);
    }
  };

  return (
    <ConfirmationDialog
      title={<FormattedMessage id="edit-user-data.delete-account" />}
      content={
        <>
          <FormattedMessage id="edit-user-data.delete-account.description" />
          <InputTextField
            label={intl.formatMessage({
              id: "general.placeholder-password",
            })}
            variant="outlined"
            size="small"
            inputRef={passwordRef}
            value={password}
            error={passwordError}
            helperText={passwordError}
            type={showPassword ? "text" : "password"}
            onChange={handlePasswordInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    size={"small"}
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    onMouseDown={() => {}}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </>
      }
      isOpen={isOpen}
      toggle={toggle}
      action={deleteAccount}
    />
  );
};

const InputTextField = styled(TextField)`
  && {
    margin-top: 10px;
    font-size: 10px;
    width: 100%;
  }
`;
