//name folder recoil???
import { atom } from "recoil";

export const reportState = atom({
  key: "reportState",
  default: { isReportOpen: false, contentId: "", contentType: "" },
});

export const isTeachingRequestState = atom({
  key: "isTeachingRequest",
  default: false,
});

export const loginSidebarState = atom({
  key: "loginSidebarState",
  default: false,
});

export const loggedInSidebarState = atom({
  key: "loggedInSidebarState",
  default: false,
});

export const isChatOpenState = atom({
  key: "isChatOpen",
  default: null,
});