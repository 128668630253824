import React, { useRef, useState } from "react";

import styled, { css } from "styled-components";
import {
  getCurrentDate,
  useOutsideMenuCloserWithFunction,
} from "../../helpers";
import { REPORT_ABUSE } from "../../apollo-client/mutations";
import { useRecoilState } from "recoil/dist";

import { useMutation } from "@apollo/client";
import { reportState } from "../../store/atoms";
import Dialog from "@mui/material/Dialog";
import { useIsMobile } from "../../hooks/state-checkers/use-is-mobile";
import { FormattedMessage } from "react-intl";

const ContainerWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  border-radius: 15px 15px 0 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0 100px;
  box-sizing: border-box;
`;

const TitleDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  font-size: ${(props) => (props.subtitle ? "16px" : "24px")};
  margin-top: ${(props) => (props.subtitle ? "0px" : "20px")};
  margin-bottom: 10px;
  ${(props) =>
    props.borderLeft &&
    css`
      border-left: 1px solid black;
      margin-left: 20px;
      padding-left: 5px;
    `}
`;

const Date = styled.div`
  opacity: 0.8;
  font-size: 12px;
`;

const CheckboxTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const Input = styled.input.attrs(() => ({
  type: "radio",
}))``;

const EditableDiv = styled.div`
  border-radius: 15px;
  height: 200px;
  width: 100%;
  background-color: white;
  padding: 10px;
  font-size: 13px;
  border: 1px solid black;
  overflow-y: scroll;

  &[contentEditable="true"]:empty:before {
    content: attr(placeholder);
  }

  &[contentEditable]:focus {
    outline: 0px solid transparent;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: transparent;
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: lightgrey;
  }
`;

const ButtonContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: 20px 100px;
`;

const Button = styled.div`
  padding: 0.25em 1em;
  font-size: 1em;
  border: ${(props) => (props.rightButton ? props.borderColor : "white")} solid
    2px;
  border-radius: 25px;
  background-color: ${(props) =>
    props.rightButton ? props.boxColor : "white"};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => (props.rightButton ? "10px" : "0px")};
`;

const TextLabel = styled.label``;

const BaseAnimation = styled.div`
  animation-duration: ${(props) => props.duration};
  animation-timing-function: ${(props) => props.timingFunction};
  animation-delay: ${(props) => props.delay};
  animation-iteration-count: ${(props) => props.iterationCount};
  animation-direction: ${(props) => props.direction};
  animation-fill-mode: ${(props) => props.fillMode};
  animation-play-state: ${(props) => props.playState};
  display: ${(props) => props.display};
`;
BaseAnimation.defaultProps = {
  duration: "2s",
  timingFunction: "ease",
  delay: "0s",
  iterationCount: "1",
  direction: "normal",
  fillMode: "both",
  playState: "running",
  display: "block",
};

//TODO überall settings reportId und contentType übergeben
export const Report = (props) => {
  const [report, setReport] = useRecoilState(reportState);
  const { isReportOpen } = report;
  const [toggleReason, setToggleReason] = useState(true);
  const [toggleSubreason, setToggleSubreason] = useState(false);
  const [toggleDescription, setToggleDescription] = useState(false);
  const [firstItemSelected, setFirstItemSelected] = useState(false);
  const [everythingSelected, setEverythingSelected] = useState(false);
  const [description, setDescription] = useState("");
  const [toggleFinished, setToggleFinished] = useState(false);
  const [borderColor, setBorderColor] = useState("white");
  const [boxColor, setBoxColor] = useState("white");
  const [subreason, setSubreason] = useState([]);
  const [reasonResult, setReasonResult] = useState("");
  const [subreasonResult, setsubreasonResult] = useState("");
  const [reason, setReason] = useState([
    { name: "Pornografische Inhalte", checked: false, key: "porn_content" },
    {
      name: "Gewaltsame oder abstoßende Inhalte",
      checked: false,
      key: "violent_or_repulsive_content",
    },
    {
      name: "Hasserfüllte oder beleidigende Inhalte",
      checked: false,
      key: "hateful_or_offensive_content",
    },
    {
      name: "Gefährliche, potenziell schädliche Handlungen",
      checked: false,
      key: "dangerous_potentially_harmful_acts",
    },
    { name: "Kindesmissbrauch", checked: false, key: "child_abuse" },
    {
      name: "Unterstützung von Terrorismus",
      checked: false,
      key: "support_for_terrorism",
    },
    {
      name: "Spam oder irreführende Inhalte",
      checked: false,
      key: "spam_or_misleading_content",
    },
    {
      name: "Verletzt meine Rechte",
      checked: false,
      key: "violations_my_rights",
    },
  ]);
  const porn = [
    {
      name: "Eindeutige sexuelle Inhalte",
      checked: false,
      key: "sexual_content",
    },
    { name: "Nacktheit", checked: false, key: "nudes" },
    {
      name: "Anzügliches Material ohne Nacktheit",
      checked: false,
      key: "Indecent_suggestive_material_without_nudity",
    },
    {
      name: "Video mit Minderjährigen",
      checked: false,
      key: "Video_with_minors",
    },
    {
      name: "Titel oder Beschreibung ist unangemessen",
      checked: false,
      key: "title_or_description_is_inappriote",
    },
    {
      name: "Pornografische Inhalte anderer Art",
      checked: false,
      key: "porn_content_of_another_kind",
    },
  ];
  const violence = [
    {
      name: "Gewalt zwischen Erwachsenen",
      checked: false,
      key: "violence_between_adults",
    },
    { name: "Tätlicher Angriff", checked: false, key: "physical_attack" },
    { name: "Jugendgewalt", checked: false, key: "youth_violence" },
    { name: "Missbrauch von Tieren", checked: false, key: "abuse_of_animals" },
  ];
  const hatred = [
    {
      name: "Verherrlichung von Hass und Gewalt",
      checked: false,
      key: "glorification_of_hatred_and_violence",
    },
    {
      name: "Missbrauch  wehrloser Personen",
      checked: false,
      key: "abuse_of_defenseless_persons",
    },
    { name: "Mobbing", checked: false, key: "mobbing" },
    {
      name: "Titel oder Beschreibung ist unangemessen",
      checked: false,
      key: "title_or_description_is_inappropriate",
    },
  ];
  const dangerousActions = [
    {
      name: "Missbrauch von Medikamenten oder Drogen",
      checked: false,
      key: "abuse_of_drugs_or_drugs",
    },
    {
      name: "Missbrauch von Feuer oder Sprengstoff",
      checked: false,
      key: "abuse_of_fire_or_explosives",
    },
    {
      name: "Selbstmord oder Selbstverletzung",
      checked: false,
      key: "suicide_or_self-injury",
    },
    {
      name: "Gefährliche Handlungen anderer Art",
      checked: false,
      key: "dangerous_actions_of_another_kind",
    },
  ];
  const children = [{ name: "in progress", checked: false, key: "" }];
  const terrorism = [{ name: "in progress", checked: false, key: "" }];
  const spam = [
    { name: "Massenwerbung", checked: false, key: "mass_advertising" },
    {
      name: "Verkauf von Arzneimitteln",
      checked: false,
      key: "sales_of_medicines",
    },
    { name: "Irreführende Texte", checked: false, key: "misleading_texts" },
    {
      name: "Irreführende Thumbnail",
      checked: false,
      key: "misleading_thumbnail",
    },
    { name: "Betrug", checked: false, key: "scam" },
  ];
  const rights = [
    {
      name: "Verletzt meine Urheberrechte",
      checked: false,
      key: "violation_of_my_copyrights",
    },
    {
      name: "Verletzung meiner Privatsphäre",
      checked: false,
      key: "violation_of_my_privacy",
    },
    {
      name: "Anderer Rechtsanspruch",
      checked: false,
      key: "other_legal_claim",
    },
  ];
  const options = [
    porn,
    violence,
    hatred,
    dangerousActions,
    children,
    terrorism,
    spam,
    rights,
  ];

  const handleOptionCheckbox = (event, reportItem) => {
    let name = event.target.name;
    reportItem.checked = event.target.checked;
    //event.target.checked === true ? setBoxColor("green") : setBoxColor("white");
    setEverythingSelected(event.target.checked);
    let newReport = [];
    subreason.forEach((item) => {
      if (item.name === name) {
        newReport.push({
          name: item.name,
          checked: item.checked,
          key: item.key,
        });
        setsubreasonResult(item.key);
      } else newReport.push({ name: item.name, checked: false, key: item.key });
    });
    setSubreason(newReport);
    //console.log(chooseOptionReport);
  };

  const handleCheckbox = (event, reportItem, i) => {
    let name = event.target.name;
    reportItem.checked = event.target.checked;
    event.target.checked === true
      ? setBorderColor("green")
      : setBorderColor("white");
    setFirstItemSelected(event.target.checked);
    setSubreason(options[i]);
    reason.forEach((item) => {
      if (item.name !== name) {
        item.checked = false;
      }
    });
    let newReport = [];
    reason.forEach((item) => {
      if (item.name === name) {
        newReport.push({
          name: item.name,
          checked: item.checked,
          key: item.key,
        });
        setReasonResult(item.key);
      } else newReport.push({ name: item.name, checked: false, key: item.key });
    });
    setReason(newReport);
  };

  const [reportAbuse] = useMutation(REPORT_ABUSE, {
    variables: {
      contentId: report.contentId,
      contentType: report.contentType,
      reason: reasonResult,
      subreason: subreasonResult,
      description: description,
    },
    update: (proxy, response) => {
      setToggleDescription(false);
      setToggleFinished(true);
    },
  });

  const getCheckedItem = (array) => {
    return array.filter((item) => item.checked).reduce((item) => item);
  };

  const contentContainerRef = useRef(null);
  useOutsideMenuCloserWithFunction(contentContainerRef, () =>
    setReport({ isReportOpen: false, contentId: "", contentType: "" })
  );

  const isMobile = useIsMobile();

  if (isReportOpen)
    return (
      <Dialog
        open={isReportOpen}
        onClose={() =>
          setReport({ isReportOpen: false, contentId: "", contentType: "" })
        }
        fullWidth={true}
        fullScreen={isMobile}
        maxWidth={"md"}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            background: "#FFFFFF",
            borderRadius: isMobile ? "0px" : "12px",
            width: isMobile ? null : "60%",
          },
        }}
      >
        <ContainerWrapper ref={contentContainerRef}>
          {toggleReason && (
            <Container>
              <Title>
                <FormattedMessage id={"general.report"} />
              </Title>
              {reason.map((reportItem, i) => {
                return (
                  <CheckboxTextWrapper
                    id={"checkbox_text_wrapper_" + reportItem.name}
                  >
                    <Input
                      key={reportItem.name}
                      id={reportItem.name}
                      name={reportItem.name}
                      checked={reportItem.checked}
                      onChange={(event) => {
                        handleCheckbox(event, reportItem, i);
                      }}
                    />
                    <TextLabel
                      id={"text_label_" + reportItem.name}
                      for={reportItem.name}
                    >
                      {
                        <FormattedMessage
                          id={"abuse-report.category." + reportItem.key}
                        />
                      }
                    </TextLabel>
                  </CheckboxTextWrapper>
                );
              })}
            </Container>
          )}
          {toggleSubreason && (
            <Container subreason>
              {/*<Slide animation={animationRight}>*/}
              <Title>
                <FormattedMessage id={"general.report"} />
              </Title>
              <Title subtitle>
                {
                  <FormattedMessage
                    id={"abuse-report.category." + getCheckedItem(reason).key}
                  />
                }
              </Title>
              {subreason.map((reportItem) => {
                return (
                  <CheckboxTextWrapper
                    id={"checkbox_text_wrapper_" + reportItem.name}
                  >
                    <Input
                      key={reportItem.name}
                      id={reportItem.name}
                      name={reportItem.name}
                      checked={reportItem.checked}
                      onChange={(event) => {
                        handleOptionCheckbox(event, reportItem);
                      }}
                    />
                    <TextLabel
                      id={"text_label_" + reportItem.name}
                      for={reportItem.name}
                    >
                      <FormattedMessage
                        id={"abuse-report.category." + reportItem.key}
                      />
                    </TextLabel>
                  </CheckboxTextWrapper>
                );
              })}
            </Container>
          )}
          {toggleDescription && (
            <Container>
              <Title>
                <FormattedMessage id={"general.report"} />
              </Title>
              <TitleDateWrapper>
                <Title subtitle>
                  {
                    <FormattedMessage
                      id={"abuse-report.category." + getCheckedItem(reason).key}
                    />
                  }
                </Title>
                <Date>{getCurrentDate()}</Date>
              </TitleDateWrapper>
              <Title subtitle borderLeft>
                {reason[4].checked || reason[5].checked ? null : (
                  <FormattedMessage
                    id={
                      "abuse-report.category." + getCheckedItem(subreason).key
                    }
                  />
                )}
              </Title>
              <EditableDiv
                id={"editable_div_report_description"}
                contentEditable={true}
                placeholder={"Anmerkungen..."}
                onKeyUp={() =>
                  setDescription(
                    document.getElementById("editable_div_report_description")
                      .innerText
                  )
                }
              />
            </Container>
          )}
          {toggleFinished && (
            <Container>
              <TitleDateWrapper>
                <Title>Vielen Dank,</Title>
                <Date>{getCurrentDate()}</Date>
              </TitleDateWrapper>
              <Title subtitle>
                dass du uns dein Anliegen mitgeteilt hast. Du wirst in Kürze
                eine Bestätigungsmail erhalten.
              </Title>
              <Title subtitle>
                Unsere Mitarbeiter werden so schnell wie möglich das angegebene
                Problem bearbeiten.
              </Title>
            </Container>
          )}
          {!toggleFinished ? (
            <ButtonContainer>
              <Button
                onClick={() => {
                  if (toggleReason) {
                    setReport({
                      isReportOpen: false,
                      contentId: "",
                      contentType: "",
                    });
                    setBorderColor("white");
                    let resetReason = [];
                    let resetSubreason = [];
                    reason.forEach((item) =>
                      resetReason.push({
                        name: item.name,
                        checked: false,
                        key: item.key,
                      })
                    );
                    subreason.forEach((item) =>
                      resetSubreason.push({
                        name: item.name,
                        checked: false,
                        key: item.key,
                      })
                    );
                    setReason(resetReason);
                    setSubreason(resetSubreason);
                  } else if (toggleSubreason) {
                    setToggleSubreason(false);
                    setToggleReason(true);
                    setEverythingSelected(false);
                    setBoxColor("white");
                  } else if (toggleDescription) {
                    setToggleDescription(false);
                    setBoxColor("white");
                    if (reason[4].checked || reason[5].checked) {
                      setToggleReason(true);
                      setEverythingSelected(false);
                      setToggleDescription(false);
                    } else setToggleSubreason(true);
                  }
                }}
              >
                {toggleReason ? (
                  <FormattedMessage id={"general.cancel"} />
                ) : (
                  <FormattedMessage id={"general.back"} />
                )}
              </Button>
              <Button
                rightButton
                borderColor={borderColor}
                boxColor={boxColor}
                onClick={() => {
                  //löschen, falls doch bei Terrorismus usw. Subreason ausgewählt werden soll
                  if (
                    (firstItemSelected && reason[4].checked) ||
                    reason[5].checked
                  ) {
                    setToggleReason(false);
                    setEverythingSelected(true);
                    setToggleDescription(true);
                    setBoxColor("green");
                    if (reason[4].checked) {
                      setsubreasonResult("child_abuse");
                    } else if (reason[5].checked) {
                      setsubreasonResult("support_for_terrorism");
                    }
                  } else if (firstItemSelected) {
                    subreason.forEach((item) => {
                      if (item.checked === true) {
                        setEverythingSelected(true);
                        setBoxColor("green");
                      }
                    });
                    if (!everythingSelected) {
                      setToggleReason(false);
                      setToggleSubreason(true);
                    } else if (everythingSelected && !toggleDescription) {
                      setToggleSubreason(false);
                      setToggleDescription(true);
                    } else if (toggleDescription) {
                      reportAbuse();
                    }
                  }
                }}
              >
                {!toggleDescription ? (
                  <FormattedMessage id={"general.next"} />
                ) : (
                  <FormattedMessage id={"general.send"} />
                )}
              </Button>
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <Button
                onClick={() => {
                  setToggleDescription(false);
                  setToggleFinished(false);
                  setToggleSubreason(false);
                  setEverythingSelected(false);
                  setToggleReason(true);
                  setBoxColor("white");
                  setBorderColor("white");
                  let resetReason = [];
                  let resetSubreason = [];
                  reason.forEach((item) =>
                    resetReason.push({
                      name: item.name,
                      checked: false,
                      key: item.key,
                    })
                  );
                  subreason.forEach((item) =>
                    resetSubreason.push({
                      name: item.name,
                      checked: false,
                      key: item.key,
                    })
                  );
                  setReason(resetReason);
                  setSubreason(resetSubreason);
                  setReport({
                    isReportOpen: false,
                    contentId: "",
                    contentType: "",
                  });
                }}
              >
                Zuklappen
              </Button>
            </ButtonContainer>
          )}
        </ContainerWrapper>
      </Dialog>
    );
  else return null;
};
