import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import PropTypes from "prop-types";

const InputIconWrapper = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0.52px 0.52px 0px 0px #9c9c9c, -0.52px -0.52px 0px 0px #9c9c9c;
  margin-top: 10px;
  background-color: white;

  &:first-child {
    margin-top: 20px;
  }
`;

const Input = styled.input`
  width: 90%;
  height: 90%;
  margin-left: 10px;
  margin-right: 10px;
  border: none;
  outline: none;

  &::placeholder {
    font-family: Work Sans;
    font-size: 16px;
  }
`;

const StyledCheckIcon = styled(IoIosCheckmarkCircleOutline).attrs(props => ({
  size: 24,
  color: props.color
}))`
  margin-right: 5px;
`;

export const InputCheckIcon = ({
  type,
  placeholder,
  onChange,
  checkIconColor
}) => {
  return (
    <InputIconWrapper>
      <Input type={type} placeholder={placeholder} onChange={onChange} />
      <StyledCheckIcon color={checkIconColor} />
    </InputIconWrapper>
  );
};

InputCheckIcon.defaultProps = {
  checkIconColor: "lightgrey",
  type: "password"
};

InputCheckIcon.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  checkIconColor: PropTypes.string
};
