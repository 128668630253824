import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { ExpandIcon } from "../../icons/icons-svg/expand-icon";
import german_flag from "../../../img/flag_german.svg";
import british_flag from "../../../img/flag_british.svg";
import { useOutsideMenuCloserWithFunction } from "../../../helpers";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  overflow: hidden;
  max-height: 23px;
  transition: max-height 0.5s ease;
  z-index: 15;
  background: white;

  &:hover {
    max-height: 60px;
  }
`;

const Container = styled.div`
  height: 20px;
  margin-left: 25px;
  margin-right: 20px;
  align-self: center;
`;

const LanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => (props.chosen ? "0 10px 0 10px" : "10px 10px 5px 10px")};
`;

const StyledExpandIcon = styled(ExpandIcon)`
  width: 20px;
  height: 20px;
`;

const LanguageText = styled.div`
  font-size: 18px;
  margin: ${(props) => (props.chosen ? "0 5px" : "0 5px 0 25px")};
  color: ${(props) => props.color};

  &:hover {
    color: #343434;
  }
`;

const LanguageIcon = styled("img")`
  width: 29px;
  height: 17.26px;
`;

export const ChooseLanguageDropdown = ({ color }) => {
  const [toggleDropdownBox, setToggleDropdownBox] = useState(false);
  //wenn language mit browser language übereinstimmt => language aus local storage löschen?
  const [chosenLanguage, setChosenLanguage] = useState(
    localStorage.getItem("language") || navigator.language.split(/[-_]/)[0]
  );
  const languages = [
    { language: "de", src: german_flag },
    { language: "en", src: british_flag },
  ];

  const refDropdown = useRef(null);
  useOutsideMenuCloserWithFunction(refDropdown, () =>
    setToggleDropdownBox(false)
  );

  return (
    <Container>
      <DropdownContainer>
        <LanguageWrapper chosen>
          <StyledExpandIcon color={color} />
          <LanguageText color={color} chosen>
            <FormattedMessage
              id={"header.select-language"}
              values={{ language: chosenLanguage }}
            />
          </LanguageText>
          {chosenLanguage === "de" ? (
            <LanguageIcon src={german_flag} />
          ) : (
            <LanguageIcon src={british_flag} />
          )}
        </LanguageWrapper>
        {languages
          .filter((language) => language.language !== chosenLanguage)
          .map((language) => (
            <LanguageWrapper
              key={"languageWrapper"}
              onClick={() => {
                setChosenLanguage(language.language);
                localStorage.setItem("language", language.language);
                window.location.reload();
              }}
            >
              <LanguageText key={"languageText"} color={color}>
                <FormattedMessage
                  id={"header.select-language"}
                  values={{ language: language.language }}
                />
              </LanguageText>
              <LanguageIcon key={"languageIcon"} src={language.src} />
            </LanguageWrapper>
          ))}
      </DropdownContainer>
    </Container>
  );
};

ChooseLanguageDropdown.defaultProps = {
  backgroundColor: "#fff",
};

ChooseLanguageDropdown.propTypes = {
  backgroundColor: PropTypes.string,
};
