import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_MY_COURSES,
  SET_SUBSCRIPTION,
} from "../../../../apollo-client/mutations";
import { Switch } from "../../../common/switch";
import { showSuccessToast } from "../../../../toast/toast-service";
import { MemberIcon } from "../../../icons/icons-svg/member-icon";
import { usePleaseLoginInfo } from "../../../../hooks/use-please-login-info";
import {
  GET_COURSE_SUBSCRIPTION,
  GET_TEACHINGS,
  GET_USER_HEADER,
} from "../../../../apollo-client/queries";
import { useRouteMatch } from "react-router-dom";
import { QuestionMarkTooltip } from "../../../common/tooltip/question-mark-tooltip";
import { theme } from "../../../../theme";
import { useOutsideEvent } from "../../../../helpers/useOutsideEvent";
import { CONTENT_AREAS } from "../../../../constants";
import { getSelectedArea } from "../../../../services/url-helpers";

export const AboComponent = (props) => {
  const { subscriberCount, match } = props;

  const [isAboMenuOpen, setIsAboMenuOpen] = useState(false);
  const [checkboxLearnGroups, setCheckboxLearnGroups] = useState(false);
  const [checkboxTutoring, setCheckboxTutoring] = useState(false);
  const [checkboxVideos, setCheckboxVideos] = useState(false);
  const [checkboxQuestions, setCheckboxQuestions] = useState(false);
  const [checkboxLinks, setCheckboxLinks] = useState(false);

  const isLoggedIn = Boolean(localStorage.getItem("token"));

  const routeMatch = useRouteMatch();

  const { openPleaseLoginInfo } = usePleaseLoginInfo();

  const [addMyCourses] = useMutation(ADD_MY_COURSES);
  const [setSubscription] = useMutation(SET_SUBSCRIPTION, {
    refetchQueries: [
      GET_COURSE_SUBSCRIPTION,
      GET_USER_HEADER,
      // for refetching subsriber count
      GET_TEACHINGS,
    ],
  });

  const { data: userData, loading: loadingUserData } =
    useQuery(GET_USER_HEADER);

  const { data: courseSubscriptionsData, loading: loadingSubscriptiondata } =
    useQuery(GET_COURSE_SUBSCRIPTION, {
      variables: { course: props?.match?.params?.id },
    });

  /*
    necessary because refetch doesnt trigger the onCompleted method again (Need to wait until apollo solve the bug)
    https://github.com/apollographql/react-apollo/issues/3709
  */
  useEffect(() => {
    setAboCheckboxes(courseSubscriptionsData?.user?.courseSubscriptions?.[0]);
  }, [courseSubscriptionsData?.user?.courseSubscriptions]);

  const isCourseSaved = Boolean(
    userData &&
      userData.user &&
      userData.user.courses.courses.find(
        (course) => course?._id.toString() === props.match?.params?.id
      )
  );

  const isContentAreaSubscribed = (courseSubscription, selectedContentArea) => {
    if (
      selectedContentArea === CONTENT_AREAS.TEACHERS &&
      courseSubscription?.newTeachings
    )
      return true;
    if (
      selectedContentArea === CONTENT_AREAS.GROUPS &&
      courseSubscription?.newGroups
    )
      return true;
    if (
      selectedContentArea === CONTENT_AREAS.CONTENT_VIDEOS &&
      courseSubscription?.newVideos
    )
      return true;
    if (
      selectedContentArea === CONTENT_AREAS.CONTENT_QUESTIONS &&
      courseSubscription?.newQuestions
    )
      return true;
    if (
      selectedContentArea === CONTENT_AREAS.CONTENT_LINKS &&
      courseSubscription?.newLinks
    )
      return true;

    return false;
  };

  const selectedContentArea = getSelectedArea(routeMatch.path);

  const isSubscribed =
    courseSubscriptionsData?.user?.courseSubscriptions?.[0] &&
    isContentAreaSubscribed(
      courseSubscriptionsData?.user?.courseSubscriptions[0],
      selectedContentArea
    );

  const intl = useIntl();
  const addCourseText = intl.formatMessage({
    id: "abo-component.add-course",
  });
  const subscribeText = intl.formatMessage({
    id: "abo-component.subscribe",
  });
  const subscribedText = intl.formatMessage({
    id: "abo-component.subscribed",
  });
  const studyGroupsText = intl.formatMessage({
    id: "general.study-groups",
  });
  const privateTutoringText = intl.formatMessage({
    id: "general.private-tutoring",
  });
  const videosText = intl.formatMessage({
    id: "general.videos",
  });
  const questionsText = intl.formatMessage({
    id: "general.questions",
  });
  const linksText = intl.formatMessage({
    id: "general.links",
  });
  const subscribeToSelectionText = intl.formatMessage({
    id: "abo-component.subscribe-to-selection",
  });

  const ref = useOutsideEvent(() => setIsAboMenuOpen(false));

  const setAboCheckboxes = (courseSubscriptions) => {
    setCheckboxTutoring(courseSubscriptions?.newTeachings);
    setCheckboxVideos(courseSubscriptions?.newVideos);
    setCheckboxLinks(courseSubscriptions?.newLinks);
    setCheckboxLearnGroups(courseSubscriptions?.newGroups);
    setCheckboxQuestions(courseSubscriptions?.newQuestions);
  };

  const handleAboButtonClick = () => {
    if (!isLoggedIn) {
      openPleaseLoginInfo();
      return;
    }

    if (!isCourseSaved) {
      addMyCourses({
        variables: { ids: match.params.id },
      });
    } else {
      setIsAboMenuOpen(!isAboMenuOpen);
    }
  };

  const handleCheckboxToggle = async () => {
    await setSubscription({
      variables: {
        course: match.params.id,
        newTeachings: !checkboxTutoring,
      },
    });

    showSuccessToast(
      intl.formatMessage({
        id: checkboxTutoring
          ? "abo-toast-success-deactivated"
          : "abo-toast-success",
      })
    );
  };

  // TODO addCourseText anzahl wie viele im kurs sind muss anders sein als anzahl die es abonniert haben!!!

  if (loadingUserData || loadingSubscriptiondata) {
    return <></>;
  }
  return (
    <AboContainer id={"abo_buttons_container"} ref={ref}>
      {!isCourseSaved && (
        <AddCourseButton
          color={"#000"}
          backgroundcolor={"#DFC4AD"}
          onClick={handleAboButtonClick}
        >
          <AboText>{addCourseText}</AboText>
          <AboMemberWrapper>
            <AboMemberCounter>{subscriberCount}</AboMemberCounter>
            <MemberIcon color={"#000"} />
          </AboMemberWrapper>
        </AddCourseButton>
      )}
      {isCourseSaved && !isSubscribed && (
        <AboButton
          color={"white"}
          backgroundcolor={theme.mainColor}
          onClick={handleAboButtonClick}
        >
          <AboText>{subscribeText} </AboText>
          <AboMemberWrapper>
            <AboMemberCounter>{subscriberCount}</AboMemberCounter>
            <MemberIcon />
          </AboMemberWrapper>
        </AboButton>
      )}
      {isCourseSaved && isSubscribed && (
        <AboButton onClick={handleAboButtonClick} backgroundcolor={"#FFFFFF"}>
          <AboText>{subscribedText} </AboText>
          <AboMemberWrapper>
            <AboMemberCounter>{subscriberCount}</AboMemberCounter>
            <MemberIcon color={"#000"} />
          </AboMemberWrapper>
        </AboButton>
      )}
      {isAboMenuOpen && (
        <AboOptionMenu>
          <SwitchContainer>
            <Row>
              <SwitchTextWrapper>
                <Switch
                  checked={checkboxTutoring}
                  toggler={handleCheckboxToggle}
                />
                <SwitchText onClick={handleCheckboxToggle}>
                  {privateTutoringText}
                </SwitchText>
              </SwitchTextWrapper>
              <SQuestionMark tooltipId="abo-component.hint" />
            </Row>
          </SwitchContainer>
        </AboOptionMenu>
      )}
    </AboContainer>
  );
};

const AboContainer = styled.div`
  align-self: flex-end;
  margin-right: 90px;
  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    align-self: flex-end;
    margin-right: 2%;
  }
`;

const AboButton = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 20px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  padding-left: 10px;
  padding-top: 0px;
  align-self: center;
  cursor: pointer;

  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundcolor};
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    font-weight: 500;
    font-size: 13px;
    max-width: 160px;
  }
`;

const AddCourseButton = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 20px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  padding: 5px 20px 5px 20px;
  align-self: center;
  cursor: pointer;
  //min-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundcolor};
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    font-weight: 500;
    font-size: 13px;
    // max-width: 160px;
  }
`;

const AboText = styled.p`
  font-style: normal;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0;
  margin: 8px 8px 8px 8px;
`;
const AboMemberWrapper = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
`;

const AboMemberCounter = styled.div`
  margin-right: 5px;
  font-weight: 500;
  font-size: 18px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    font-weight: 500;
    font-size: 13px;
  }
`;

const AboOptionMenu = styled.div`
  position: fixed;
  width: 220px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: -1px 2px 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
  right: 110px;

  background-color: white;
  box-sizing: border-box;

  @media only screen and (max-width: 980px) {
    margin-left: -80px;
    right: 0px;
  }
`;

const SwitchContainer = styled.div`
  padding: 10px 10px 10px 10px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SwitchTextWrapper = styled.div`
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SwitchText = styled.div`
  margin-left: 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;

  color: #383838;
`;

const SQuestionMark = styled(QuestionMarkTooltip)`
  margin-left: 20px;
`;
