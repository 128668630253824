import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";
import user_not_logged_in_icon from "../../../img/user_not_logged_in_icon.svg";
import { RegisterSidebar } from "../../sidebars/register-sidebar/register-sidebar";
import { useRecoilState } from "recoil/dist";
import { loginSidebarState } from "../../../store/atoms";
import { createPortal } from "react-dom";

export const LoginButton = ({ position }) => {
  const match = useRouteMatch();

  const [, setIsLoginSidebarOpen] = useRecoilState(loginSidebarState);

  const [isRegisterMenuOpen, setIsRegisterMenuOpen] = useState(
    match.path === "/registration"
  );

  useEffect(() => {
    setIsRegisterMenuOpen(match.path === "/registration");
  }, [match.path]);

  return (
    <Container position={position}>
      <StyledMenuIcon
        src={user_not_logged_in_icon}
        fontSize={"large"}
        onClick={() => {
          setIsLoginSidebarOpen(true);
        }}
      />

      {createPortal(
        <RegisterSidebar
          isOpen={isRegisterMenuOpen}
          setIsOpen={setIsRegisterMenuOpen}
        />,
        document.body
      )}
    </Container>
  );
};

const Container = styled.div`
  position: ${(props) => (props.position ? props.position : "fixed")};
  right: 0;
  top: 23px;
  z-index: 13;
  user-select: none;

  @media only screen and (min-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
  }
`;

const StyledMenuIcon = styled("img")`
  margin-right: 20px;
  margin-left: 10px;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 10px;
  user-select: none;
  position: relative;
  color: black;

  @media only screen and (max-width: ${(props) =>
      props.theme.xsDevicesBreakpoint}) {
    margin-right: 10px;
  }
`;
