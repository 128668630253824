import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import send_button from "../../../../../img/send_button.svg";
import { useIntlId } from "../../../../../hooks/use-intl-id";
import { useMutation } from "@apollo/client";
import { POST_MESSAGE } from "../../../../../apollo-client/mutations";
import {
  GET_MESSAGES,
  GET_USER_CHATS,
} from "../../../../../apollo-client/queries";
import { BooleanParam, useQueryParam } from "use-query-params";
import { useRecoilState } from "recoil";
import { isTeachingRequestState } from "../../../../../store/atoms";
import { useParams } from "react-router-dom";
import { showErrorToast } from "../../../../../toast/toast-service";

const SendButton = styled("img")`
  width: 24px;
  height: 24px;
  margin: 0 10px;
`;

export const ChatInputBar = ({
  chosenChat,
  setPostingMessage,
  setChosenChat,
}) => {
  const [inputMessage, setInputMessage] = useState("");
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const [isChatOpen, setIsChatOpen] = useQueryParam("ischatopen", BooleanParam);
  const [isTeachingRequest, setIsTeachingRequest] = useRecoilState(
    isTeachingRequestState
  );
  const { id } = useParams();

  const enterMessagePlaceholder = useIntlId("chat.enter-message-placeholder");

  const [postMessage, {}] = useMutation(POST_MESSAGE, {
    refetchQueries: [GET_USER_CHATS, GET_MESSAGES],
    onCompleted: (data) => {
      setPostingMessage("");

      const chosenChat = data.postMessage;

      if (chosenChat.messagesCount === 1) {
        setChosenChat({
          type: chosenChat.type,
          chatTitle: chosenChat.title,
          profilePicture:
            chosenChat.picture ||
            chosenChat.otherPrivateChatMember.profilePicture,
          receiverId: chosenChat.otherPrivateChatMember._id,
          chatId: chosenChat._id,
          unreadMessagesCount: chosenChat.unreadMessagesCount,
          isOnVacation: chosenChat.otherPrivateChatMember.onHoliday,
        });
      }
    },
    onError: (error) => {
      showErrorToast(error.message);
    },
    // awaitRefetchQueries: true,
    ignoreResults: true,
  });

  const sendMessage = async () => {
    if (inputMessage.length > 0) {
      setIsScrolledToBottom(true);
      setPostingMessage(inputMessage);
      await postMessage({
        variables: {
          type: chosenChat.type,
          message: inputMessage,
          receiver: chosenChat.receiverId,
          isTeachingRequest,
          course: id,
        },
      });
      setInputMessage("");
      inputRef.current.innerText = "";
      setIsTeachingRequest(false);
    }
  };
  const onKeyUp = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      setInputMessage("");
      inputRef.current.innerText = "";
    } else setInputMessage(inputRef.current.innerText);
  };

  const onKeyDown = async (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      await sendMessage();
    } else setInputMessage(inputRef.current.innerText);
  };

  const inputRef = useRef(null);

  const focusInput = () => {
    if (isChatOpen) inputRef.current.focus();
  };

  // focus input when selecting a new chat
  useEffect(() => {
    focusInput();
  }, [chosenChat]);

  return (
    <Container>
      <Input
        ref={inputRef}
        contentEditable={chosenChat?.isBlockedBy?.length === 0}
        placeholder={enterMessagePlaceholder}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
      />
      <SendButton src={send_button} onClick={sendMessage} />
    </Container>
  );
};

const Container = styled.div`
  width: 80%;
  margin-bottom: 10px;
  margin-left: 10px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-self: flex-start;
  align-items: center;
  padding: 5px 10px;
  border: 0.5px solid #c4c4c4c4;
  border-radius: 20px;
  background-color: white;
`;

const Input = styled.div`
  max-height: 100px;
  display: flex;
  margin-left: 10px;
  flex-grow: 1;
  outline: none;
  border: transparent;
  font-family: Roboto;
  font-size: 1em;
  word-break: break-word;
  cursor: text;
  overflow-y: auto;

  &[contentEditable="true"]:empty:before {
    content: attr(placeholder);
    color: grey;
  }

  &[contentEditable]:focus {
    outline: 0 solid transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: transparent;
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: transparent;
      background-color: lightgrey;
    }
  }
`;
