import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useIntl, FormattedMessage } from "react-intl";
import { AdditionalTeacherTeachings } from "./additionalTeacherTeachings";

const DescriptionWrapper = styled.div`
  padding: 5px;
  border: ${(props) => "1px solid " + props.borderVisibility};
  margin-right: 40px;

  @media only screen and (max-width: ${(props) =>
      props.theme.xsDevicesBreakpoint}) {
    margin-right: 0;
  }
`;

const Description = styled.div`
  overflow: hidden;
  line-height: 25px;
  font-size: 14px;
  font-weight: 600;
  word-break: break-word;
  white-space: pre-line;
  -webkit-box-orient: vertical;
  display: ${(props) => props.display};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) =>
    props.isTextExpanded ? "none" : props.clampAtLine};
`;

const TextToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextToggleButton = styled.div`
  cursor: pointer;
  font-size: 12px;
  color: #8a8a8a;
  margin-top: 5px;
  margin-bottom: 20px;
  justify-content: flex-end;
  align-self: flex-end;
`;

const EditDescription = styled(Description)`
  outline: none;
  cursor: text;
  margin-left: auto;

  &[contentEditable="true"]:empty:before {
    content: attr(placeholder);
    color: #787878;
    font-size: ${(props) => props.theme.mediumFontSize};
  }

  &[contentEditable]:focus {
    outline: 0 solid transparent;
  }
`;

const AlsoHelpsWithText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-top: 18px;
  color: #af4445;
`;

export const TeacherDescription = ({
  toggleEdit,
  description,
  setEditDescription,
  currentCourseTitle,
  teaching,
}) => {
  const [isTextExpanded, setIsTextExpanded] = useState(false);

  // const [isDescriptionOverflown, setIsDescriptionOverflown] = useState(false);
  // const descriptionRef = useRef(null);

  // TODO: Cleanup if neseccary
  // trackIsRefOverflown(descriptionRef, setIsDescriptionOverflown);

  const additionalTeacherTeachings =
    teaching.teacher.teachings.teachings.filter(
      (teacherTeaching) => teaching._id !== teacherTeaching._id
    );

  const handleKeyUp = () => {
    setEditDescription(document.getElementById("edit_description").innerText);
  };

  const intl = useIntl();

  const showMoreText = intl.formatMessage({
    id: "toggle-text-show-more",
  });

  const showLessText = intl.formatMessage({
    id: "toggle-text-show-less",
  });

  useLayoutEffect(() => {
    if (document.getElementById("edit_description")) {
      document.getElementById("edit_description").innerText = description;
    }
  }, [toggleEdit]);

  if (!toggleEdit && additionalTeacherTeachings) {
    return (
      <DescriptionWrapper borderVisibility={"transparent"}>
        <TextToggleContainer>
          <Description
            clampAtLine={6}
            isTextExpanded={isTextExpanded}
            display={"-webkit-box"}
          >
            {description}
          </Description>
          {additionalTeacherTeachings.length > 0 && (
            <AlsoHelpsWithText>
              <FormattedMessage
                id={"teacher-detail.profile.also-helps-in"}
                values={{ teachingsCount: additionalTeacherTeachings.length }}
              />
              {isTextExpanded ? "" : "..."}
            </AlsoHelpsWithText>
          )}

          {additionalTeacherTeachings.length > 0 && !isTextExpanded && (
            <TextToggleButton onClick={() => setIsTextExpanded(true)}>
              {showMoreText}
            </TextToggleButton>
          )}
        </TextToggleContainer>
        {isTextExpanded && (
          <TextToggleContainer>
            <AdditionalTeacherTeachings
              courseTitle={currentCourseTitle}
              additionalTeacherTeachings={additionalTeacherTeachings}
            />
            <TextToggleButton onClick={() => setIsTextExpanded(false)}>
              {showLessText}
            </TextToggleButton>
          </TextToggleContainer>
        )}
      </DescriptionWrapper>
    );
  } else
    return (
      <DescriptionWrapper borderVisibility={"black"}>
        <EditDescription
          id={"edit_description"}
          contentEditable={true}
          placeholder={"Beschreibung"}
          onKeyUp={handleKeyUp}
        />
      </DescriptionWrapper>
    );
};
