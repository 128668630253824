import React from "react";
import styled from "styled-components";
import { InfoPagesLayout } from "../../components/layout/info-pages-layout";

export const AGBPage = () => {
  return (
    <InfoPagesLayout title={"Allgemeine Geschäftsbedingungen"}>
      <SubSection>1. Geltungsbereich</SubSection>
      <p>
        Die vorliegenden Allgemeinen Geschäftsbedingungen (nachfolgend "AGB")
        gelten für sämtliche Leistungen und Angebote der Internetplattform
        "Blagoom.de" (nachfolgend "Blagoom" oder "wir" genannt). Durch die
        Registrierung auf Blagoom.de stimmen Nutzer diesen AGB zu. Sollten diese
        AGB in verschiedenen Sprachen zur Verfügung gestellt werden, ist im
        Zweifel die deutsche Fassung maßgeblich.
      </p>
      <SubSection>2. Vertragspartner</SubSection>
      <p>
        Vertragspartner sind die Nutzer der Plattform, also Nachhilfegeber und
        Nachhilfenehmer, die jeweils ein rechtsverbindliches Angebot abgeben
        oder annehmen.
      </p>

      <SubSection>3. Registrierung und Nutzerkonto</SubSection>
      <p>
        Die Anmeldung und die Erstellung eines Profils auf Blagoom.de ist
        kostenlos. Jeder Nutzer darf nur ein Konto eröffnen. Bei der
        Registrierung müssen Nutzer wahre und vollständige Angaben machen. Durch
        die Nutzung der Website und/oder die Erstellung eines Benutzerkontos
        stimmt der Nutzer den hier aufgeführten AGB zu. Nutzer können ihr Konto
        jederzeit ohne Angabe von Gründen löschen. Mit dem Löschen eines Kontos
        werden alle auf Blagoom.de gespeicherten Daten des Nutzers
        unwiderruflich gelöscht, oder anonymisiert.
      </p>
      <SubSection>4. Pflichten der Nutzer:</SubSection>
      <p>
        Die Nutzer sind verpflichtet, die Kommunikation auf der Plattform
        respektvoll und gesetzestreu zu gestalten. Beleidigende,
        diskriminierende oder illegale Äußerungen sind strengstens verboten und
        führen zum sofortigen Ausschluss.
      </p>
      <SubSection>5. Leistung von Blagoom</SubSection>
      <p>
        Blagoom stellt lediglich die Plattform zur Verfügung, auf der Nachhilfe
        angeboten und nachgefragt werden kann. Wir prüfen nicht die Fähigkeiten
        der Nachhilfegeber und bieten auch keine Garantie für die Korrektheit
        und Aktualität der angegebenen Universitätsmodule. Bewertungen von
        Nachhilfegebern werden von uns nach einer bestimmten Methode
        zusammengefasst und grafisch dargestellt. Blagoom behält sich das Recht
        vor, den Umfang und die Art der auf der Plattform angebotenen Dienste
        jederzeit zu ändern, zu erweitern, oder einzuschränken.
      </p>
      <SubSection>6. Bezahlung</SubSection>
      <p>
        Die Bezahlung für die Nachhilfe erfolgt ausschließlich zwischen
        Nachhilfegeber und Nachhilfenehmer. Blagoom ist nicht für die Bezahlung
        verantwortlich und übernimmt auch keine Haftung für etwaige
        Zahlungsprobleme.
      </p>
      <SubSection>7. Bewertungen</SubSection>
      <p>
        Bewertungen sollen wahrheitsgetreu und sachlich sein. Nutzer sind allein
        für ihren Bewertungstext verantwortlich. Blagoom behält sich vor,
        Bewertungen zu löschen, die gegen diese Vorschriften verstoßen.
      </p>
      <SubSection>8. Datenschutz</SubSection>
      <p>
        Die von Nutzern bereitgestellten Daten werden von Blagoom ausschließlich
        zum Betreiben der Plattform genutzt und nicht an Dritte weitergeben oder
        verkauft. Im Rahmen der Nutzung unserer Plattform werden einige Deiner
        Informationen an andere Nutzer weitergegeben, entweder über Dein
        öffentliches Profil oder im Rahmen des Buchungsprozesses (z. B. geben
        wir Deine Telefonnummer an die Personen weiter, mit denen Du Unterricht
        wahrnehmen möchtest).
      </p>
      <SubSection>9. Haftungsausschluss</SubSection>
      <p>
        Blagoom haftet nicht für die Qualität der von Nachhilfegebern erbrachten
        Leistungen. Ebenso haftet Blagoom nicht für Schäden, die aus der Nutzung
        oder Nichtnutzung der Plattform, insbesondere durch Datenverlust oder
        nicht erhaltene Profite, entstehen. Blagoom übernimmt keine
        Gewährleistung oder Haftung für technische Fehler, Ausfälle oder
        Sicherheitslücken. Des Weiteren haftet Blagoom nicht für Inhalte auf
        extern verlinkten Seiten.
      </p>
      <SubSection>10. Benutzerkonto</SubSection>
      <p>
        Nutzer sind für die Sicherheit ihres Benutzerkontos und ihre
        Log-In-Daten verantwortlich. Bei Missbrauch oder Verlust der
        Zugangsdaten ist Blagoom unverzüglich zu informieren. Blagoom behält
        sich das Recht vor, bei verdächtigen Aktivitäten Konten zu sperren oder
        zu löschen.
      </p>
      <SubSection>11. Urheber- und Nutzungsrechte</SubSection>
      <p>
        Die Nutzer garantieren, dass sie über alle notwendigen Rechte an den von
        ihnen bereitgestellten Inhalten verfügen. Mit der Bereitstellung von
        Inhalten räumen die Nutzer Blagoom ein uneingeschränktes Nutzungsrecht
        ein. Die Nutzung der Plattform für kommerzielle Zwecke oder Werbung
        jeglicher Art ist ohne vorherige schriftliche Genehmigung durch Blagoom
        untersagt.
      </p>
      <SubSection>12. Verhaltenskodex</SubSection>
      <p>
        Jeder Nutzer hat dafür Sorge zu tragen, dass er durch sein Verhalten
        nicht die Rechte Dritter verletzt. Jede Form von Diskriminierung,
        Bedrohung, Belästigung oder Beleidigung ist strengstens untersagt.
        Blagoom behält sich das Recht vor, Nutzerprofile zu löschen, wenn diese
        gegen die AGB verstoßen, illegale Aktivitäten betreiben oder die
        Plattform in anderer Weise schädigen.
      </p>
      <SubSection>13. Konfliktlösung</SubSection>
      <p>
        Im Streitfall zwischen den Nutzern übernimmt Blagoom keine Rolle als
        Vermittler oder Schiedsrichter. Es gilt die gesetzliche Rechtsprechung.
      </p>
      <SubSection>14. Änderung der AGBs</SubSection>
      <p>
        Blagoom behält sich vor, die AGBs jederzeit zu ändern. Über Änderungen
        werden die Nutzer rechtzeitig informiert. Blagoom behält sich das Recht
        vor, den Service zu erweitern, zu ändern oder eingestellte Funktionen zu
        entfernen. Es besteht kein Anspruch auf die Verfügbarkeit bestimmter
        Funktionen.
      </p>
      <SubSection>15. Schlussbestimmungen</SubSection>
      <p>
        Konfliktlösung Im Streitfall zwischen den Nutzern übernimmt Blagoom
        keine Rolle als Vermittler oder Schiedsrichter. Es gilt die gesetzliche
        Rechtsprechung. Sollten einzelne Bestimmungen dieser AGB unwirksam sein
        oder werden, bleibt die Wirksamkeit der übrigen Bestimmungen hiervon
        unberührt. Die unwirksame Bestimmung ist durch eine wirksame zu
        ersetzen, die dem verfolgten Zweck möglichst nahekommt. Es gilt das
        Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.
        Gerichtsstand ist der Sitz von Blagoom. Nutzer sind nicht berechtigt,
        ihre gegenüber Blagoom bestehenden Ansprüche ohne die Zustimmung von
        Blagoom an Dritte abzutreten.
      </p>
    </InfoPagesLayout>
  );
};

const SubSection = styled.p`
  font-weight: 500;
  font-size: 24px;
`;
