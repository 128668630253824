import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import look_out from "../../img/look_out.png";
import { theme } from "../../theme";

export const StartTutoringMotivation = ({
  subscriberCount,
  areTeachingsAvailable,
}) => {
  const intl = useIntl();
  const startTutoringMotivationText = intl.formatMessage(
    { id: "teacher.start-tutoring-motivation.motivation" },
    {
      number: subscriberCount,
      breakLine: <br />,
      plural: subscriberCount !== 1,
    }
  );

  const emptyMotivation1 = intl.formatMessage({
    id: "teacher.start-tutoring-motivation.motivation-empty-1",
  });
  const emptyMotivation2 = intl.formatMessage({
    id: "teacher.start-tutoring-motivation.motivation-empty-2",
  });
  const emptyMotivation3 = intl.formatMessage({
    id: "teacher.start-tutoring-motivation.motivation-empty-3",
  });

  const NoTutorsMotivationText = () => {
    return (
      <>
        <p>
          {emptyMotivation1}{" "}
          <HighlightedText>{emptyMotivation2}</HighlightedText>{" "}
          {emptyMotivation3}
        </p>
      </>
    );
  };

  return (
    <Container>
      <SubscriberImageWrapper>
        <LookOutImage src={look_out} />
      </SubscriberImageWrapper>
      <TextWrapper>
        <StartTutoringMotivationText>
          {!areTeachingsAvailable ? (
            <NoTutorsMotivationText />
          ) : (
            startTutoringMotivationText
          )}
        </StartTutoringMotivationText>
      </TextWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 130%;
  font-weight: bold;
  font-size: 26px;
  line-height: 50px;
  background: #faeff0;
  border-radius: 20px 60px 60px 20px;
  margin-top: 60px;

  display: flex;
  align-items: center;
  margin-bottom: 180px;
  justify-self: flex-start;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    width: 90%;
    border-radius: 20px 40px 40px 20px;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 60px;
  padding-right: 60px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const HighlightedText = styled.span`
  color: ${theme.mainColor};
`;
const SubscriberImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LookOutImage = styled.img`
  width: 300px;
  height: 258px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    width: 120px;
    height: 120px;
  }
`;

const StartTutoringMotivationText = styled.div`
  text-align: center;

  font-weight: bold;
  font-size: 26px;
  line-height: 50px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
`;
