import React, { useRef } from "react";
import germanStrings from "react-timeago/lib/language-strings/de";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { useEffect, useState } from "react";
import { useApolloClient, useQuery } from "@apollo/client";

export function trimCourseTitle(title) {
  return title.substring(0, 25) + "...";
}

export function transformMyCourseList(courses) {
  let universities = [];

  courses.forEach((a) => {
    if (!universities.includes(a.university)) universities.push(a.university);
  });

  const response = [];

  universities.forEach((u) => {
    let c = courses
      .filter((c) => {
        return c.university === u;
      })
      .map((c) => {
        return {
          id: c._id,
          title: c.title,
        };
      });

    const res = {
      university: u,
      courses: c,
    };
    response.push(res);
  });
  return response;
}

export function getSelectedContent(path) {
  const splitted = path.split("/");
  return splitted[4];
}

//need a better name! it returns the last or last two strings of the current path
export function getBasicPath(path) {
  // const splitted = path.split("/");
  // //content area and learn together area
  // if (
  //   (splitted[4] && splitted[3] === "content") ||
  //   splitted[4] === "learn-together"
  // )
  //   return splitted[3] + "/" + splitted[4];
  // //teaching and group area
  // return splitted[3];

  /*
    The old functionality does not after implementing the home page.
    Once Content, learning groups or other areas are added, the function should be adjusted again
   */
  return "teachers";
}

export function getCourseIdFromPath(path) {
  const splittedPath = path.split("/");
  return splittedPath[2];
}

export const cutString = (string, cutPoint) => {
  if (string.length > cutPoint) return string.substring(0, cutPoint) + "...";
  else return string;
};

export const convertUnixTimeStamp = (unixTimestamp) => {
  const created = new Date(Number(unixTimestamp));
  let day = created.getDate();
  let month = created.getMonth() + 1; //January is 0
  const year = created.getFullYear();
  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;
  return day + "/" + month + "/" + year;
};

export const getTimeFromUnixTimeStamp = (unixTimestamp) => {
  const date = new Date(Number(unixTimestamp));
  let hours = date.getHours();
  let minutes = date.getMinutes();
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  return hours + ":" + minutes;
};

export const getCurrentDate = () => {
  let timeStamp = new Date().getTime();
  return convertUnixTimeStamp(timeStamp);
};

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

export function addTimeToCurrentTimeTimeStamp(hour, minute) {
  return new Date().addHours(hour + minute / 60).getTime();
}

export const calculateTimeDifferenceBetweenTimeStamps = (
  timestamp1,
  timestamp2
) => {
  let difference = timestamp1 - timestamp2;
  let daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;
  let hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;
  let minutesDifference = Math.floor(difference / 1000 / 60);
  if (hoursDifference < 10) hoursDifference = "0" + hoursDifference;
  if (minutesDifference < 10) minutesDifference = "0" + minutesDifference;
  return hoursDifference + ":" + minutesDifference;
};

//gets a date and checks if it was yesterday
export const isYesterday = (unixTimeStamp) => {
  let yesterday = new Date(Date.now() - 86400000).getTime();
  let yesterdayConverted = convertUnixTimeStamp(yesterday);

  let checkDate = new Date(Number(unixTimeStamp));
  let checkDateConverted = convertUnixTimeStamp(checkDate);

  return checkDateConverted === yesterdayConverted;
};

//hier könnten eventuell auch verbotene Begriffe herausgenommen werden
export const checkRegexComment = (comment) => {
  if (comment.length > 0) {
    return true;
  } else return false;
};

export const formatter = buildFormatter(germanStrings);

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideMenuCloser = (
  ref,
  toggleHook,
  localCacheVariable,
  setScroll,
  hookWithObject,
  object,
  position,
  timeout,
  setSlideIn,
  universityArray
) => {
  const client = useApolloClient();
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !toggleHook &&
      localCacheVariable
    ) {
      client.writeData({
        data: { [localCacheVariable]: false, scroll: "scroll" },
      });
    } else if (
      ref.current &&
      !ref.current.contains(event.target) &&
      toggleHook
    ) {
      try {
        toggleHook(false);
        setScroll("scroll");
      } catch {
        //console.log("error: no hook");
      }
    } else if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !toggleHook &&
      hookWithObject
    ) {
      try {
        if (timeout) {
          setSlideIn(false);
          setTimeout(() => {
            const newValue = [...object];
            newValue[position].bool = false;
            if (universityArray.length === newValue.length)
              newValue.push({ bool: false });
            hookWithObject(newValue);
          }, timeout);
        } else {
          const newValue = [...object];
          newValue[position].bool = false;
          hookWithObject(newValue);
        }
      } catch {
        //console.log("error: no hook with object");
      }
    } //else console.log("error: no condition met");
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideMenuCloserAnimation = (
  ref,
  toggleHook,
  setAnimation
) => {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      toggleHook();
      setAnimation(false);
    } //else console.log("error: no condition met");
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

export const useOutsideMenuCloserExceptImportantAreas = (ref, func) => {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    const headerRightWrapper = document.getElementById("header_right_wrapper");
    const chatContainer = document.getElementById("chat_container");
    const smallCourseNavContainer = document.getElementById(
      "small_course_nav_container"
    );
    const myCoursesNavContainer = document.getElementById(
      "my_courses_nav_container"
    );
    const searchCoursesNavContainer = document.getElementById(
      "search_courses_nav_container"
    );
    const aboButtonsContainer = document.getElementById(
      "abo_buttons_container"
    );

    if (
      headerRightWrapper &&
      chatContainer &&
      aboButtonsContainer &&
      !headerRightWrapper.contains(event.target) &&
      !chatContainer.contains(event.target) &&
      !aboButtonsContainer.contains(event.target) &&
      ((smallCourseNavContainer &&
        !smallCourseNavContainer.contains(event.target)) ||
        (myCoursesNavContainer &&
          !myCoursesNavContainer.contains(event.target)) ||
        (searchCoursesNavContainer &&
          !searchCoursesNavContainer.contains(event.target))) &&
      ref.current &&
      !ref.current.contains(event.target)
    ) {
      func(event);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

/**
 * Deprecated, in favour of useOutsideMenuCloserWithFunction
 *
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideMenuCloserWithFunction = (ref, func) => {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      func();
    } //else console.log("error: no condition met");
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

export const checkForApostroph = (string) => {
  if (string.substring(string.length - 1) === "s") {
    return string + "'";
  } else return string + "s";
};

export const printName = (firstName, lastName, username) => {
  if (firstName !== null && lastName !== null)
    return firstName + " " + lastName;
  else if (firstName !== null) return firstName;
  else return username;
};

export const defaultProfilePicture =
  "https://storage.googleapis.com/blag-profile-pictures/default-profile-picture.png";

export const useWindowEvent = (event, callback) => {
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [event, callback]);
};

export const useMatchMediaEvent = (mediaQuery, callback) => {
  //später eventuell schauen, ob navigation bottom berührt und so dynmisch eigene kurse in der liste anzeigen oder eben nicht
  //für Safari möglicherweise addListener
  useEffect(() => {
    const media = window.matchMedia(mediaQuery);
    if (media.matches) callback();
    try {
      media.addEventListener("change", callback);
      return () => media.removeEventListener("change", callback);
    } catch {
      //for safari
      try {
        media.addListener(callback);
        return () => media.removeListener(callback);
      } catch {}
    }
  }, ["change", callback]);
};

export const checkPriceInput = (value) => {
  //TODO maximal 300!!!
  return value.match(/^\d{1,3}?$/);
};

//eventuell sinnlos
export function useScroll() {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [bodyOffset, setBodyOffset] = useState(
    document.body.getBoundingClientRect()
  );
  const [scrollY, setScrollY] = useState(bodyOffset.top);
  const [scrollX, setScrollX] = useState(bodyOffset.left);
  const [scrollDirection, setScrollDirection] = useState();

  const listener = (e) => {
    setBodyOffset(document.body.getBoundingClientRect());
    setScrollY(-bodyOffset.top);
    setScrollX(bodyOffset.left);
    setScrollDirection(lastScrollTop > -bodyOffset.top ? "down" : "up");
    setLastScrollTop(-bodyOffset.top);
  };

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });

  return {
    scrollY,
    scrollX,
    scrollDirection,
  };
}

export function useFetchMoreOnScroll(fetchMore) {
  const listener = () => {
    const element = document.documentElement;
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 200)
      fetchMore();
  };

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });
}

export function handlePressEnter(event, func) {
  if (event.key === "Enter") {
    event.preventDefault();
    func();
  }
}

// TODO: Cleanup if not nesecarry or create hook
// export function trackIsRefOverflown(ref, setIsOverflown) {
//   window.addEventListener("resize", () => {
//     setIsOverflown(calculateIsRefOverflown(ref));
//   });
//
//   useEffect(() => {
//     setIsOverflown(calculateIsRefOverflown(ref));
//   }, [ref]);
// }

const calculateIsRefOverflown = (ref) => {
  if (ref && ref.current) {
    const { current } = ref;

    return current.scrollHeight > current.clientHeight;
  }
  return false;
};

//später löschen
// const { data: newTeacher } = useSubscription(TEACHERS_SUBSCRIPTION);
//
// if (newTeacher) {
//   console.log("newTeacher");
//   console.log(newTeacher);
// }
//
// const { data: newVideo } = useSubscription(VIDEOS_SUBSCRIPTION, {
//   onSubscriptionData: ({
//     subscriptionData: {
//       data: { newVideo }
//     }
//   }) => {
//     console.log("new video");
//     console.log(newVideo);
//   }
// });
//
// const { data: newLink } = useSubscription(LINK_SUBSCRIPTION, {
//   onSubscriptionData: ({
//     subscriptionData: {
//       data: { newLink }
//     }
//   }) => {
//     console.log("new link");
//     console.log(newLink);
//   }
// });
//
// const { data: newQuestion } = useSubscription(QUESTION_SUBSCRIPTION, {
//   onSubscriptionData: ({
//     subscriptionData: {
//       data: { newQuestion }
//     }
//   }) => {
//     console.log("newQuestion");
//     console.log(newQuestion);
//   }
// });
//
// const { data: newComment } = useSubscription(COMMENT_SUBSCRIPTION, {
//   onSubscriptionData: ({
//     subscriptionData: {
//       data: { newComment }
//     }
//   }) => {
//     console.log("newComment");
//     console.log(newComment);
//   }
// });
//
// const { data: newAnswer } = useSubscription(ANSWER_SUBSCRIPTION, {
//   onSubscriptionData: ({
//     subscriptionData: {
//       data: { newAnswer }
//     }
//   }) => {
//     console.log("newAnswer");
//     console.log(newAnswer);
//   }
// });
//
// const { data: newGroup } = useSubscription(GROUP_SUBSCRIPTION, {
//   onSubscriptionData: ({
//     subscriptionData: {
//       data: { newGroup }
//     }
//   }) => {
//     console.log("newGroup");
//     console.log(newGroup);
//   }
// });

// bei den unteren Variante printete später bei einem neuen Link auch noch die alten Fragen, die schonmal geschickt wurde raus.
// onSubscriptionData funktionierte besser

// if(newLink) {
//   console.log("newLink");
//   console.log(newLink);
// }

// if(newQuestion) {
//   console.log("newQuestion");
//   console.log(newQuestion);
// }

// const setCutPointOnWindowResize = () => useEffect(() => {
//   if (showSmallTeacherComponent && contentWrapperRef.current) {
//     if (contentWrapperRef.current.offsetWidth <= 520) setCutPoint(120);
//     else if (contentWrapperRef.current.offsetWidth < 675) setCutPoint(180);
//     else if (contentWrapperRef.current.offsetWidth >= 675) setCutPoint(250);
//     const handleResize = () => {
//       if (showSmallTeacherComponent && contentWrapperRef.current) {
//         console.log("width", contentWrapperRef.current.offsetWidth);
//         console.log("resize");
//         if (contentWrapperRef.current.offsetWidth <= 520) setCutPoint(120);
//         else if (contentWrapperRef.current.offsetWidth < 675)
//           setCutPoint(180);
//         else if (contentWrapperRef.current.offsetWidth >= 675)
//           setCutPoint(250);
//       }
//     };
//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.addEventListener("resize", handleResize);
//     };
//   }
// }, []);
