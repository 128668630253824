import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormattedMessage, useIntl } from "react-intl";
import { PrimarySpinner } from "../../spinner/primary-spinner";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { loginValidators } from "../../../services/login-validators";
import { showErrorToast } from "../../../toast/toast-service";
import { errorConstants } from "../../../error-constants";
import { useMutation } from "@apollo/client";
import { LOGIN_USER } from "../../../apollo-client/mutations";
import { handlePressEnter } from "../../../helpers";

export const LoginFields = () => {
  const [showPassword, setShowPassword] = useState(false);

  const [loginFormValues, setLoginFormValues] = useState({
    email: "",
    password: "",
  });
  const [loginFormValueErrors, setLoginFormValueErrors] = useState({
    email: false,
    password: false,
  });
  const [canLoginFormBeSent, setCanLoginFormBeSent] = useState(true);

  const [loginUser, { loading: loadingLoginUser }] = useMutation(LOGIN_USER);

  const intl = useIntl();

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const resetLoginFormErrors = () => {
    setLoginFormValueErrors({
      email: false,
      password: false,
    });
  };
  const handleEmailInputChange = (event) => {
    const emailInput = event.target.value;

    setLoginFormValues({
      ...loginFormValues,
      email: emailInput,
    });
    resetLoginFormErrors();
    setCanLoginFormBeSent(true);
  };

  const handlePasswordInputChange = (event) => {
    const passportInputText = event.target.value;

    setLoginFormValues({
      ...loginFormValues,
      password: passportInputText,
    });
    resetLoginFormErrors();
    setCanLoginFormBeSent(true);
  };

  const normalLogin = (e) => {
    // must be set for Button.onSubmit method, because otherwise it refreshes the page without waiting for the mutation to succeed
    e.preventDefault();

    let emailFieldError = false;

    const areLoginInputFieldsValid = () => !emailFieldError;

    try {
      loginValidators.validateEmail(loginFormValues.email, intl);
    } catch (err) {
      emailFieldError = err.message;
      emailRef.current.focus();
    }

    setLoginFormValueErrors({
      email: emailFieldError,
    });

    setCanLoginFormBeSent(areLoginInputFieldsValid());

    if (areLoginInputFieldsValid()) {
      loginUser({
        variables: {
          email: loginFormValues.email,
          password: loginFormValues.password,
        },
      })
        .then(({ data }) => {
          localStorage.setItem("token", data.login.jwt);
          window.location.reload();
        })
        .catch((error) => {
          const graphQLErrorMessage = error.graphQLErrors[0].message;
          const errorMessage = intl.formatMessage(
            {
              id: "login.error",
            },
            { error: graphQLErrorMessage }
          );
          showErrorToast(errorMessage);

          if (graphQLErrorMessage === errorConstants.wrongPassword) {
            setLoginFormValueErrors({
              ...loginFormValueErrors,
              password: errorMessage,
            });
            passwordRef.current.focus();
          } else if (graphQLErrorMessage === errorConstants.emailNotFound) {
            setLoginFormValueErrors({
              ...loginFormValueErrors,
              email: errorMessage,
            });
            emailRef.current.focus();
          } else if (graphQLErrorMessage === "userIsNotVerified") {
            setLoginFormValueErrors({
              ...loginFormValueErrors,
              email: errorMessage,
            });
            emailRef.current.focus();
          }
        });
    }
  };
  return (
    <InputFieldsWrapper>
      <LoginInputFields>
        <FormControl fullWidth={true}>
          <InputTextField
            label="E-mail"
            size="small"
            variant="outlined"
            inputRef={emailRef}
            type=""
            value={loginFormValues.email}
            error={loginFormValueErrors.email}
            helperText={loginFormValueErrors.email}
            onChange={handleEmailInputChange}
            onKeyDown={event => handlePressEnter(event, () => normalLogin(event))}
          />
          <InputTextField
            label={intl.formatMessage({
              id: "general.placeholder-password",
            })}
            variant="outlined"
            size="small"
            inputRef={passwordRef}
            value={loginFormValues.password}
            error={loginFormValueErrors.password}
            helperText={loginFormValueErrors.password}
            type={showPassword ? "text" : "password"}
            onChange={handlePasswordInputChange}
            onKeyDown={event => handlePressEnter(event, () => normalLogin(event))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    size={"small"}
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    onMouseDown={() => {}}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <StyledLink forgotpassword={"true"} to={"/forgot-password"}>
            <ForgotPassword>
              <FormattedMessage id={"general.forgot-password-button"} />
            </ForgotPassword>
          </StyledLink>
          <LoginButton
            type={"submit"}
            disabled={!canLoginFormBeSent}
            onClick={normalLogin}
            onSubmit={normalLogin}
          >
            {loadingLoginUser ? (
              <PrimarySpinner />
            ) : (
              <FormattedMessage id={"general.login-button"} />
            )}
          </LoginButton>
        </FormControl>
      </LoginInputFields>
    </InputFieldsWrapper>
  );
};

const InputFieldsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginInputFields = styled.div`
  && {
    margin-top: 10px;
    width: 80%;
    display: flex;
    justify-content: center;
  }
`;

const InputTextField = styled(TextField)`
  && {
    margin-top: 10px;
    font-size: 10px;
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  align-self: flex-end;
  margin-top: 5px;
  margin-bottom: 20px;
`;

const ForgotPassword = styled.div`
  font-weight: 500;
  font-size: 11px;
  color: #666666;
`;

const LoginButton = styled(Button)`
  && {
    text-transform: none;
    height: 30px;
    width: 93px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    background-color: ${(props) => props.theme.primaryAccentColor};
    color: #ffffff;
    
    `;
