import { FormattedMessage } from "react-intl";
import React from "react";

export const getNotificationTypeHeadline = (notificationType) => {
  switch (notificationType) {
    case "newTeaching":
    case "teachingRequest":
    case "teachingRequestAnswer":
      return <FormattedMessage id="general.private-tutoring" />;
    default:
      return false;
  }
};

export const wasPrevNotifSameType = (
  notificationType,
  prevNotificationType
) => {
  // Need to check props.id, cause FormattedMessage is returned
  return (
    getNotificationTypeHeadline(notificationType).props.id ===
    getNotificationTypeHeadline(prevNotificationType)?.props?.id
  );
};
