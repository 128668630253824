import React, { useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery } from "@apollo/client";
import { FormattedMessage, useIntl } from "react-intl";
import { ProfilePicture } from "../../../components/common/profile-picture";
import { IoMdThumbsDown, IoMdThumbsUp } from "react-icons/io";
import { Comment } from "./comment";
import { CommentInput } from "./comment-input";
import {
  ADD_COMMENT_FOR_TEACHER,
  DELETE_COMMENT_FOR_TEACHER,
  EDIT_COMMENT_FOR_TEACHER,
  TOGGLE_TEACHING_LIKE,
  TOGGLE_TEACHING_DISLIKE,
} from "../../../apollo-client/mutations";
import {
  GET_TEACHING,
  GET_TEACHING_COMMENTS,
  GET_USER_HEADER,
} from "../../../apollo-client/queries";
import { CancelButton, SendButton } from "./comment-buttons";
import { FetchMoreOnClick } from "./fetch-more-on-click";
import { PrimarySpinner } from "../../../components/spinner/primary-spinner";
import { CONTENT_TYPES } from "../../../constants";

export const TeacherDetailComments = ({ teaching }) => {
  const { _id, likeCount, dislikeCount, teacherName } = teaching;

  const [comment, setComment] = useState("");
  const [areCommentButtonsDisplayed, setAreCommentButtonsDisplayed] =
    useState(false);

  const intl = useIntl();

  const { data, loading, error, fetchMore } = useQuery(GET_TEACHING_COMMENTS, {
    variables: { _id, limit: 15 },
    // notifyOnNetworkStatusChange: true,
  });

  const { data: getUserData, loading: getUserLoading } =
    useQuery(GET_USER_HEADER);

  const [addComment] = useMutation(ADD_COMMENT_FOR_TEACHER, {
    refetchQueries: [GET_TEACHING_COMMENTS],
    onCompleted: () => {
      handleClean();
    },
  });

  const [deleteComment] = useMutation(DELETE_COMMENT_FOR_TEACHER, {
    refetchQueries: [GET_TEACHING_COMMENTS],
  });
  const [editComment] = useMutation(EDIT_COMMENT_FOR_TEACHER);

  const [toggleLike] = useMutation(TOGGLE_TEACHING_LIKE, {
    refetchQueries: [GET_TEACHING, GET_TEACHING_COMMENTS],
  });
  const [toggleDislike] = useMutation(TOGGLE_TEACHING_DISLIKE, {
    refetchQueries: [GET_TEACHING, GET_TEACHING_COMMENTS],
  });

  const handlePostComment = async () => {
    if (comment !== "")
      await addComment({
        variables: {
          contentId: _id,
          contentType: "teaching",
          comment: comment,
        },
      });
  };

  const handleDeleteComment = async (commentId) => {
    await deleteComment({
      variables: { _id: commentId },
    });
  };

  const handleLikeTeaching = async () => {
    await toggleLike({
      variables: {
        contentId: teaching._id,
        contentType: CONTENT_TYPES.TEACHING,
      },
    });
  };
  const handleDislikeTeaching = async () => {
    await toggleDislike({
      variables: {
        contentId: teaching._id,
        contentType: CONTENT_TYPES.TEACHING,
      },
    });
  };

  const handleClean = () => {
    setComment("");
    document.getElementById("comment_input" + _id).innerText = "";
    handleShowCommentButtons(false);
  };

  const handleShowCommentButtons = (boolean) => {
    setAreCommentButtonsDisplayed(boolean);
  };

  const handleFetchMoreComments = async () => {
    await fetchMore({
      variables: {
        limit: 15,
        skip: data.teachings.teachings[0].comments.comments.length,
      },
    });
  };

  return (
    <TeacherDetailsContainer>
      <ContentWrapper>
        <CenterWrapper>
          <ToggleCommentsThumbsWrapper>
            <CommentInputWrapper>
              <InputPictureWrapper>
                <ProfilePicture
                  src={getUserData && getUserData.user.profilePicture}
                  width={42}
                  height={42}
                  margin={"0 15px 0 0"}
                />
                <CommentInput
                  width={"100%"}
                  id={_id}
                  comment={comment}
                  placeholder={intl.formatMessage({ id: "comments.comment" })}
                  onKeyUp={() => {
                    setComment(
                      document.getElementById("comment_input" + _id).innerText
                    );
                  }}
                  postText={handlePostComment}
                  clean={handleClean}
                  onClick={() => handleShowCommentButtons(true)}
                />
              </InputPictureWrapper>
            </CommentInputWrapper>
            <ButtonThumbsWrapper>
              <CommentButtonWrapper isDisplayed={areCommentButtonsDisplayed}>
                <CancelButton onClick={handleClean} />
                <SendButton comment={comment} onClick={handlePostComment} />
              </CommentButtonWrapper>
              <ThumbsWrapper>
                <ThumbsUpWrapper isLiked={teaching.isLiked}>
                  <IoMdThumbsUp size={29} onClick={handleLikeTeaching} />
                </ThumbsUpWrapper>
                <LikeCounterText>{likeCount}</LikeCounterText>
                <ThumbsDownWrapper isDisliked={teaching.isDisliked}>
                  <IoMdThumbsDown size={29} onClick={handleDislikeTeaching} />
                </ThumbsDownWrapper>
                <LikeCounterText>{dislikeCount}</LikeCounterText>
              </ThumbsWrapper>
            </ButtonThumbsWrapper>
          </ToggleCommentsThumbsWrapper>

          <CommentsHeadline>
            <FormattedMessage id="comments.headline" />
          </CommentsHeadline>

          {/*
            isExpanded, und die Comments toggeln lassen. Evt die weiteren Kommentare sogar dann erst laden

           */}

          <div style={{ width: "100%" }}>
            {data &&
              !error &&
              data.teachings.teachings[0].comments.comments.map((comment) => (
                <Comment
                  key={comment._id}
                  teacherName={teacherName}
                  editMutation={editComment}
                  deleteComment={() => handleDeleteComment(comment._id)}
                  commentObject={comment}
                />
              ))}
            {loading && <StyledPrimarySpinner />}
            <StyledFetchMoreOnClick
              shouldShow={
                !loading &&
                data &&
                data.teachings &&
                data.teachings.teachings[0].comments.commentsCount !==
                  data.teachings.teachings[0].comments.comments.length
              }
              onClick={handleFetchMoreComments}
            />
          </div>
        </CenterWrapper>
      </ContentWrapper>
    </TeacherDetailsContainer>
  );
};

const TeacherDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  margin: 60px auto 15px auto;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  padding-bottom: 50px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    box-shadow: 0px 0px 0px;

    border-radius: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  margin-top: 10px;
`;

const CenterWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-left: 31px;

  @media only screen and (max-width: ${(props) =>
      props.theme.xsDevicesBreakpoint}) {
    margin-left: 0;
  }
`;

const ToggleCommentsThumbsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
`;

const ButtonThumbsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ThumbsWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ThumbsUpWrapper = styled.div`
  color: #4d4d4d;
  margin-right: 3px;
  opacity: ${(props) => (props.isLiked ? 1 : 0.5)};
`;

const ThumbsDownWrapper = styled.div`
  color: #4d4d4d;
  margin-right: 3px;
  margin-top: 12px;
  opacity: ${(props) => (props.isDisliked ? 1 : 0.5)};
`;

const LikeCounterText = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

const CommentsHeadline = styled.div`
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  color: #383838;
  margin-top: 15px;
  margin-bottom: 38px;
`;

const CommentInputWrapper = styled.div`
  width: 100%;
`;

const CommentButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  visibility: ${(props) => (props.isDisplayed ? "visible" : "hidden")};
`;

const InputPictureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledPrimarySpinner = styled(PrimarySpinner)`
  margin: 20px auto;
`;

const StyledFetchMoreOnClick = styled(FetchMoreOnClick)`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;
