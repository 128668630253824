import React from "react";
import styled from "styled-components";
import { convertUnixTimeStamp } from "../../helpers";
import PropTypes from "prop-types";

//font-size in component because it accepts number and string
const DateText = styled.div`
  font-size: ${(props) => props.theme.smallFontSize};
  color: ${(props) => props.color};
  font-weight: 500;
`;

export const Date = ({ unixTimeStamp, color }) => {
  return (
    <DateText color={color}>{convertUnixTimeStamp(unixTimeStamp)}</DateText>
  );
};

Date.defaultProps = {
  color: "rgba(0, 0, 0, 0.5)",
};

Date.propTypes = {
  unixTimeStamp: PropTypes.string.isRequired,
  family: PropTypes.string,
  color: PropTypes.string,
};
