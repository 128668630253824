import img_close_cross from "../../../img/closeCrossIcon.svg";
import { FormattedMessage } from "react-intl";
import React from "react";
import styled from "styled-components";
import { Button, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { ChooseLanguageDropdown } from "../../layout/header/choose-language-dropdown";
import { SocialButtons } from "./social-buttons";
import { LoginFields } from "./login-fields";
import { useRecoilState } from "recoil";
import { loginSidebarState } from "../../../store/atoms";

export const LoginSidebar = () => {
  const [isOpen, setIsOpen] = useRecoilState(loginSidebarState);

  return (
    <MenuBox isBurgerMenuOpen={isOpen}>
      <MenuBoxHeader>
        <MenuTitle>
          <FormattedMessage id="login-or-register" />
        </MenuTitle>
        <MenuCloseIcon src={img_close_cross} onClick={() => setIsOpen(false)} />
      </MenuBoxHeader>

      <LoginFields />

      <OrTextWrapper>
        <OrText>
          <FormattedMessage id="sign-up.or" />
        </OrText>
      </OrTextWrapper>

      <SocialButtons />

      <RegisterButtonWrapper>
        <StyledLink to={`/registration`} onClick={() => {}}>
          <RegisterButton>
            <FormattedMessage id={"general.register-button"} />
          </RegisterButton>
        </StyledLink>
      </RegisterButtonWrapper>

      <Filler />
      <Divider />

      <LinksWrapper>
        <ChooseLanguageDropdown />
        <HelpLink to="/help">
          <FormattedMessage id="menu.info-links.help" />
        </HelpLink>
      </LinksWrapper>
    </MenuBox>
  );
};

const LinksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
`;

const OrTextWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const OrText = styled.p`
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: ${(props) => props.theme.fourthColor};
`;

const HelpLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.fourthColor};
  text-decoration: none;
  margin-top: 20px;
`;

const Filler = styled.div`
  margin-top: 50px;
`;
const MenuBox = styled.div`
  position: fixed;
  height: 100%;
  width: 80%;
  top: 0;
  right: 0;
  transform: translateX(${(props) => (props.isBurgerMenuOpen ? 0 : "100%")});
  transition: transform 0.5s ease;
  background-color: #ffffff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  z-index: 11;

  @media only screen and (min-width: 1041px) {
    width: 40%;
  }
\` ;
`;

const MenuBoxHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
`;

const MenuTitle = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  color: ${(props) => props.theme.fourthColor};
`;

const MenuCloseIcon = styled("img")`
  width: 30px;
  margin: 20px 40px;
  cursor: pointer;
  user-select: none;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  align-self: center;
  margin: ${(props) =>
    props.forgotpassword ? "6px 10px 10px auto" : "0 0 10px 0"};
`;

const RegisterButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const RegisterButton = styled(Button)`
  && {
    width: 138px;
    height: 37px;
    text-transform: none;
    font-size: 16px;
    padding: 5px 15px;
    color: #f86b6b;
    outline: none;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #c7c7c7;
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 8px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      background-color: #ff8181;
      color: white;
    }
  }
`;
