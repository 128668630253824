import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

const StyledSendButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 25px;
  cursor: ${(props) => (props.comment === "" ? "default" : "pointer")};
  color: ${(props) =>
    props.comment === "" ? props.theme.greyTextColor : "#fff"};
  font-weight: 500;
  background: ${(props) =>
    props.comment === "" ? "white" : props.theme.primaryColor};
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  font-size: 15px;
  line-height: 20px;
  margin-left: 22px;
`;

const StyledCancelButton = styled.div`
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  line-height: 20px;
  background-color: transparent;
`;

export const SendButton = ({ comment, onClick }) => {
  const intl = useIntl();

  const commentText = intl.formatMessage({
    id: "comments.comment",
  });

  return (
    <StyledSendButton comment={comment} onClick={onClick}>
      {commentText}
    </StyledSendButton>
  );
};

SendButton.defaultProps = {};

SendButton.propTypes = {
  comment: PropTypes.string.isRequired,
};

export const CancelButton = ({ onClick }) => {
  const intl = useIntl();
  const cancelCommentText = intl.formatMessage({
    id: "comments.cancel",
  });
  return (
    <StyledCancelButton onClick={onClick}>
      {cancelCommentText}
    </StyledCancelButton>
  );
};

CancelButton.defaultProps = {};

CancelButton.propTypes = {};
