import { useRecoilState } from "recoil";
import { loggedInSidebarState } from "../../../store/atoms";
import { FormattedMessage } from "react-intl";
import img_close_cross from "../../../img/closeCrossIcon.svg";
import { Divider } from "@mui/material";
import { ChooseLanguageDropdown } from "../../layout/header/choose-language-dropdown";
import React from "react";
import styled from "styled-components";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { usePleaseLoginInfo } from "../../../hooks/use-please-login-info";

export const LoggedInSidebar = () => {
  const [isOpen, setIsOpen] = useRecoilState(loggedInSidebarState);
  const routeMatch = useRouteMatch();
  const client = useApolloClient();

  const { openPleaseLoginInfo } = usePleaseLoginInfo();

  const logout = async () => {
    localStorage.removeItem("token");
    await client.resetStore();
    window.location.reload();
  };

  return (
    <MenuBox isBurgerMenuOpen={isOpen}>
      <MenuBoxHeader>
        <MenuCloseIcon src={img_close_cross} onClick={() => setIsOpen(false)} />
      </MenuBoxHeader>
      <LinksWrapper>
        <MenuLink
          to={`/profile/${routeMatch.params.id}`}
          onClick={() => setIsOpen(false)}
        >
          <FormattedMessage id={"header.menu-edit-profile"} />
        </MenuLink>
        <MenuLink to={"/"} onClick={openPleaseLoginInfo}>
          <FormattedMessage id={"footer.about-us"} />
        </MenuLink>
        <MenuLink to={"/"} onClick={logout}>
          <FormattedMessage id={"header.menu-logout"} />
        </MenuLink>

        <Filler />
        <Divider />

        <ChooseLanguageDropdown />
        <HelpLink to="/help">
          <FormattedMessage id="menu.info-links.help" />
        </HelpLink>
      </LinksWrapper>
    </MenuBox>
  );
};
const LinksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MenuLink = styled(Link)`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${(props) => props.theme.fourthColor};
  text-decoration: none;
  margin-bottom: 20px;

  &:hover {
    color: ${(props) => props.theme.primaryAccentColor};
  }
`;

const HelpLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.fourthColor};
  text-decoration: none;
  margin-top: 20px;
`;

const Filler = styled.div`
  margin-top: 30px;
`;

const MenuBox = styled.div`
  position: fixed;
  height: 100%;
  width: 80%;
  top: 0;
  right: 0;
  transform: translateX(${(props) => (props.isBurgerMenuOpen ? 0 : "100%")});
  transition: transform 0.5s ease;
  background-color: #ffffff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  z-index: 11;

  @media only screen and (min-width: ${(props) =>
    props.theme.firstBreakpoint + 1}px) {
    width: 40%;
  }
\` ;
`;

const MenuCloseIcon = styled("img")`
  width: 30px;
  margin: 20px 40px;
  cursor: pointer;
  user-select: none;
`;

const MenuBoxHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 20px;
`;
