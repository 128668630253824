import { gql } from "@apollo/client";

export const GET_TEACHING = gql`
  query getTeaching($_id: String) {
    teachings(_id: $_id) {
      teachings {
        teacher {
          username
          profilePicture
        }
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query getUserNotifications($limit: Int, $skip: Int) {
    user {
      _id
      notificationsCount
      notifications(limit: $limit, skip: $skip) {
        _id
        contentId
        notificationType
        course {
          _id
          title
          university
        }
        teachingRequester {
          _id
          username
          profilePicture
        }
        wasSeen
        createdAt
      }
    }
  }
`;
