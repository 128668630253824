import { useState, useEffect, useCallback } from "react";

export const useOpenClose = (initialValue, enableReinitialize = false) => {
  const [value, set] = useState(initialValue);
  useEffect(() => {
    if (enableReinitialize) {
      set(initialValue);
    }
  }, [initialValue, enableReinitialize]);
  const open = useCallback(() => set(true), []);
  const close = useCallback(() => set(false), []);
  const toggle = useCallback(() => set((value) => !value), []);

  return {
    value,
    open,
    close,
    toggle,
    set,
  };
};
