import { toast } from "react-toastify";

export const showSuccessToast = (text, autoCloseTime = 1500) => {
  toast.success(text, {
    autoClose: autoCloseTime,
  });
};

export const showErrorToast = (text) => {
  toast.error(text, {
    autoClose: 5000,
  });
};

export const showInfoToast = (text) => {
  toast.info(text, {
    autoClose: 3000,
  });
};
