import React, { useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { useOutsideMenuCloserExceptImportantAreas } from "../../../helpers";

import { HEADER_TOP_WRAPPER_HEIGHT } from "../../../constants";
import { BooleanParam, useQueryParam } from "use-query-params";
import { SmallChatBar } from "./SmallChatBar/small-chat-bar";
import { MainChatWindow } from "./MainChatWindow/mainChatWindow";

export const Chat = ({ startChat }) => {
  const [chosenChat, setChosenChat] = useState({
    type: null,
    chatTitle: null,
    receiverId: null,
    chatId: null,
    profilePicture: null,
    unreadMessagesCount: null,
  });

  const [isChatOpen, setIsChatOpen] = useQueryParam("ischatopen", BooleanParam);
  const [searchContact, setSearchContact] = useState("");

  const closeChatRef = useRef(null);

  useOutsideMenuCloserExceptImportantAreas(closeChatRef, () => {
    if (!isChatOpen) setIsChatOpen(null);
    else setIsChatOpen(false);
  });

  return (
    <ChatContainer
      id={"chat_container"}
      ref={closeChatRef}
      isChatOpen={isChatOpen}
    >
      <SmallChatBar
        chosenChat={chosenChat}
        setChosenChat={setChosenChat}
        searchContact={searchContact}
        startChat={startChat}
      />
      <MainChatWindow
        setChosenChat={setChosenChat}
        chosenChat={chosenChat}
        setSearchContact={setSearchContact}
      />
    </ChatContainer>
  );
};

const slideInAnimation = keyframes`
  0% {
    transform: translateX(0px);
  }
  70% {
   transform: translateX(-330px);
  }
  100% {
   transform: translateX(-341px);
  }
`;

const slideOutAnimation = keyframes`
  0% {
    transform: translateX(-341px);
  }
  70% {
    transform: translateX(-10px);
  }
  100% {
   transform: translateX(0px);
  }
`;

const checkAnimation = (isChatOpen) => {
  if (isChatOpen === true) {
    return slideInAnimation;
  } else if (isChatOpen === false) {
    return slideOutAnimation;
  } else if (isChatOpen === null) {
    return null;
  }
};

const slideInMobileAnimation = keyframes`
  0% {
    transform: translateX(0px);
  }
  70% {
   transform: translateX(-80%);
  }
  100% {
   transform: translateX(-101%);
  }
`;

const slideOutMobileAnimation = keyframes`
  0% {
    transform: translateX(-101%);
  }
  70% {
    transform: translateX(-80%);
  }
  100% {
   transform: translateX(0px);
  }
`;

const checkMobileAnimation = (isChatOpen) => {
  if (isChatOpen === true) {
    return slideInMobileAnimation;
  } else if (isChatOpen === false) {
    return slideOutMobileAnimation;
  } else if (isChatOpen === null) {
    return null;
  }
};

const ChatContainer = styled.div`
  display: flex;
  width: 428px;
  height: calc(100% - (${HEADER_TOP_WRAPPER_HEIGHT} + 10px));
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  border-radius: 20px 0 0 0;
  background: white;
  position: fixed;
  bottom: 0;
  right: -339px;
  z-index: 10;

  animation: ${(props) => checkAnimation(props.isChatOpen)} 0.3s linear;
  animation-fill-mode: forwards;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    width: 100%;
    height: 100%;
    right: -101%;
    z-index: 15;
    animation: ${(props) => checkMobileAnimation(props.isChatOpen)} 0.3s linear;
    animation-fill-mode: forwards;
    border-radius: 0 0 0 0;
  }
`;

const MainChatWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
`;
