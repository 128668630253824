import { gql } from "@apollo/client";

export const BLOCK_CHAT = gql`
  mutation blockChat($chatId: String!, $block: Boolean!) {
    setBlockChat(chatId: $chatId, block: $block) {
      isBlockedBy {
        username
      }
    }
  }
`;
