import React from "react";
import { Header } from "../components/layout/header/header";

import styled from "styled-components";
import man_bites_into_lemon from "../img/man_bites_into_lemon.png";
import { useIntl } from "react-intl";
import { CopyrightAboutUs } from "./landing-page/copyright-about-us";
import { Footer } from "./landing-page/footer";

export const NotFoundPage = (props) => {
  const intl = useIntl();
  const notFoundText = intl.formatMessage({
    id: "page-not-found.text",
  });
  const failureCode = intl.formatMessage({
    id: "page-not-found.failure-code",
  });
  return (
    <Container>
      <MainContainer>
        <ImageTextWrapper>
          <Image src={man_bites_into_lemon} />
          <TextWrapper>
            <FirstPartWrapper>
              <Text>
                OH SHIT!
                <NotFoundText>{notFoundText}</NotFoundText>
              </Text>
            </FirstPartWrapper>
            <FailureCodeText>{failureCode}</FailureCodeText>
          </TextWrapper>
        </ImageTextWrapper>
      </MainContainer>
      <CopyrightAboutUs />
      <Header hideNavigation hideBurgerMenu props={props} />
      <Footer />
    </Container>
  );
};

const Container = styled.div``;

const MainContainer = styled.div`
  height: 100vh;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
`;

const imageTextWrapperHeight = "70%";

const ImageTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${imageTextWrapperHeight};
  align-self: flex-end;
`;

const Image = styled.img`
  align-self: flex-end;
  width: 100%;
`;

const TextWrapper = styled.div`
  position: absolute;
  height: ${imageTextWrapperHeight};
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FirstPartWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Text = styled.span`
  font-size: 40px;
`;

const NotFoundText = styled(Text)`
  font-size: 20px;
`;

const FailureCodeText = styled(Text)`
  font-size: 14px;
`;
