import React from "react";
import { useEffect } from "react";
import { SmallCourseNav } from "./small-course-nav/small-course-nav";
import MyCoursesNav from "./my-course-nav/my-courses-nav";
import SearchCoursesNav from "./search-course-nav/search-courses-nav";
import { usePleaseLoginInfo } from "../../../hooks/use-please-login-info";
import { BooleanParam, useQueryParam } from "use-query-params";

export default function CourseNav({
  showContentNav,
  makeGroupVisibleInCourses,
  setMakeGroupVisibleInCourses,
  visibleCourses,
  setVisibleCourses,
  props,
}) {
  const [isMyCoursesOpen, setIsMyCoursesOpen] = useQueryParam(
    "mycourseopen",
    BooleanParam
  );
  const [isSearchCoursesOpen, setIsSearchCoursesOpen] = useQueryParam(
    "coursesearchopen",
    BooleanParam
  );
  const [isChatOpen, setIsChatOpen] = useQueryParam("ischatopen", BooleanParam);

  const { openPleaseLoginInfo } = usePleaseLoginInfo();

  useEffect(() => {
    if (makeGroupVisibleInCourses) {
      toggleSearchNav();
    }
  }, [makeGroupVisibleInCourses]);

  const isLoggedIn = Boolean(localStorage.getItem("token"));

  const toggleMyCourses = () => {
    if (!isLoggedIn) {
      openPleaseLoginInfo();
      return;
    }

    setIsMyCoursesOpen(!isMyCoursesOpen);
  };
  const toggleSearchNav = () => {
    if (makeGroupVisibleInCourses) {
      setIsSearchCoursesOpen(!isSearchCoursesOpen);
    }
  };
  const toggleSearchCourses = () => {
    setIsSearchCoursesOpen(!isSearchCoursesOpen);
  };
  const toggleMobileChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  if (!isMyCoursesOpen && !isSearchCoursesOpen) {
    return (
      <SmallCourseNav
        showContentNav={showContentNav}
        toggleMyCourses={toggleMyCourses}
        toggleSearchCourses={toggleSearchCourses}
        toggleMobileChat={toggleMobileChat}
        props={props}
      />
    );
  }

  if (isMyCoursesOpen && !isSearchCoursesOpen) {
    return <MyCoursesNav toggle={toggleMyCourses} props={props} />;
  }

  if (isSearchCoursesOpen) {
    return (
      <SearchCoursesNav
        escapeToggle={toggleSearchCourses}
        props={props}
        makeGroupVisibleInCourses={makeGroupVisibleInCourses}
        visibleCourses={visibleCourses}
        setMakeGroupVisibleInCourses={setMakeGroupVisibleInCourses}
        setVisibleCourses={setVisibleCourses}
      />
    );
  }
}
