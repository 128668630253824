/*
  deprecated in favour of constants2.js
 */

export const CHAT_USERNAME_COLORS = [
  "#E0CC62",
  "#82DA6C",
  "#62DFC9",
  "#646FCE",
  "#C960CB",
];

export const CONTENT_AREAS = {
  TEACHERS: "teachers",
  GROUPS: "groups",
  CONTENT_VIDEOS: "content/videos",
  CONTENT_LINKS: "content/links",
  CONTENT_QUESTIONS: "content/questions",
};
export const CONTENT_TYPES = {
  CONTENT_VIDEO: "contentVideo",
  CONTENT_LINK: "contentLink",
  CONTENT_QUESTIONS: "contentQuestions",
  CONTENT_ANSWER: "contentAnswer",
  TEACHING: "teaching",
  COMMENT: "comment",
};

export const HEADER_TOP_WRAPPER_HEIGHT = "120px";
export const MOBILE_HEADER_TOP_WRAPPER_HEIGHT = "60px";

export const USER_EMAIL_SETTINGS = {
  SUMMARY: "SUMMARY",
  NEW_NOTIFICATIONS: "NEW_NOTIFICATIONS",
  NEW_CHAT_MESSAGE: "NEW_CHAT_MESSAGE",
};
