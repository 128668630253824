import React, { useState } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { cutString } from "../../../helpers";

const Text = styled.div`
  font-size: ${(props) => props.theme.mediumFontSize};
  color: ${(props) => props.theme.thirdColor};

  white-space: pre-line;
  word-break: break-word;
`;

const TextToggleButton = styled.div`
  cursor: pointer;
  font-size: 12px;
  color: #787878;
  margin-top: 5px;
`;

export const TextWithToggle = ({ text, cutPoint }) => {
  const [isTextExpanded, setIsTextExpanded] = useState(false);
  const intl = useIntl();

  const showMoreText = intl.formatMessage({
    id: "toggle-text-show-more",
  });

  const showLessText = intl.formatMessage({
    id: "toggle-text-show-less",
  });

  try {
    if (!isTextExpanded && text.length < cutPoint) {
      return <Text>{text}</Text>;
    } else if (!isTextExpanded && text.length > cutPoint) {
      return (
        <div>
          <Text>{cutString(text, cutPoint)}</Text>
          <TextToggleButton onClick={() => setIsTextExpanded(true)}>
            {showMoreText}
          </TextToggleButton>
        </div>
      );
    } else {
      return (
        <div>
          <Text>{text}</Text>
          <TextToggleButton onClick={() => setIsTextExpanded(false)}>
            {showLessText}
          </TextToggleButton>
        </div>
      );
    }
  } catch (error) {
    console.log("ERROR: ", "KEINE FRAGE GESTELLT!");
    return null;
  }
};
