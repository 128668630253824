import { ContentContainer } from "../../../components/layout/content-container";
import styled from "styled-components";
import { Header } from "../../../components/layout/header/header";
import React from "react";
import { FAQStudentsAccordionSection } from "./FAQStudentsAccordionSection";
import { FAQTeachersAccordionSection } from "./FAQTeachersAccordionSection";
import { MotivationSection } from "./MotivationSection";
import { InfoPagesLayout } from "../../../components/layout/info-pages-layout";

export const HelpPage = () => {
  return (
    <>
      <InfoPagesLayout title="Hilfe und FAQs">
        <MotivationSection />
        <FAQStudentsAccordionSection />
        <FAQTeachersAccordionSection />
      </InfoPagesLayout>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  @media only screen and (max-width: 980px) {
    width: 100%;
  }
`;
