import { gql } from "@apollo/client";

export const GET_USER_TEACHINGS = gql`
  query getUserTeachings {
    user {
      _id
      onHoliday
      teachings {
        teachings {
          _id
          course {
            _id
            title
            university
          }
          price
          acceptsRemote
          description
          isActive
          isBlocked
          isDeleted
          teacher {
            _id
            username
          }
        }
      }
      inactiveTeachings {
        teachings {
          _id
          course {
            _id
            title
            university
          }
          price
          acceptsRemote
          isActive
          isDeleted
          description
          teacher {
            _id
            username
          }
        }
      }
    }
  }
`;
