import { Button, Dialog, DialogActions } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { useIsMobile } from "../../hooks/state-checkers/use-is-mobile";

export const ConfirmationDialog = ({
  isOpen,
  toggle,
  action,
  title,
  content,
  disableButton,
  hideOkButton,
}) => {
  const isMobile = useIsMobile();

  return (
    <Dialog
      maxWidth="xs"
      fullScreen={isMobile}
      aria-labelledby="confirmation-dialog-title"
      open={isOpen}
      onClose={toggle}
      fullWidth={true}
      PaperProps={{
        style: {
          background: "#FFFFFF",
          borderRadius: "12px",
        },
      }}
    >
      <TitleWrapper>
        <Title>{title}</Title>
      </TitleWrapper>

      <ContentContainer>{content}</ContentContainer>
      <StyledDialogActions>
        <StyledButton autoFocus onClick={toggle} color="primary">
          <FormattedMessage id="general.cancel" />
        </StyledButton>
        {!hideOkButton && (
          <StyledButton
            onClick={action}
            disabled={disableButton}
            color="primary"
          >
            <FormattedMessage id="general.ok" />
          </StyledButton>
        )}
      </StyledDialogActions>
    </Dialog>
  );
};

const TitleWrapper = styled.div`
  text-align: center;
`;

const Title = styled.p`
  color: ${(props) => props.theme.fourthColor};
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
`;

const ContentContainer = styled.div`
  color: ${(props) => props.theme.fourthColor};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledDialogActions = styled(DialogActions)`
  margin-top: 10px;
  margin-bottom: 5px;
`;

const StyledButton = styled(Button)`
  && {
    background: ${(props) => props.theme.primaryColor};
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
  }
`;
