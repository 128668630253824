import React from "react";
import styled from "styled-components";

const Container = styled.div``;

const ShowMoreText = styled.div`
  cursor: pointer;
  color: #808080;

  &:hover {
    color: #262626;
  }
`;

export const FetchMoreOnClick = ({ className, onClick, shouldShow }) => {
  if (shouldShow)
    return (
      <Container className={className}>
        <ShowMoreText onClick={onClick}>Mehr anzeigen</ShowMoreText>
      </Container>
    );
  else return null;
};
