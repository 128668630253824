import { gql } from "@apollo/client";

export const GET_USER_EMAIL_SETTINGS = gql`
  query getEmailSettings {
    user {
      emailSettings
    }
  }
`;

export const CHANGE_USER_EMAIL_SETTINGS = gql`
  mutation EditUserProfile($emailSettings: [EmailSettings]) {
    editUserProfile(emailSettings: $emailSettings) {
      emailSettings
    }
  }
`;
