import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Input = styled.div`
  min-height: 20px;
  border: transparent;
  border-bottom: 1px solid #787878;
  background-color: transparent;
  outline: none;
  cursor: text;
  padding-bottom: 5px;
  word-break: break-word;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;

  &[contentEditable="true"]:empty:before {
    content: attr(placeholder);
    color: #787878;
    font-size: ${(props) => props.theme.mediumFontSize};
  }

  &[contentEditable]:focus {
    outline: 0px solid transparent;
  }
`;

export const CommentInput = ({
  id,
  comment,
  placeholder,
  width,
  height,
  onKeyUp,
  postText,
  clean,
  onClick,
}) => {
  return (
    <Input
      id={"comment_input" + id}
      contentEditable={true}
      placeholder={placeholder}
      style={{ width: width, height: height }}
      onKeyDown={(event) => {
        if (comment && postText)
          if (event.keyCode === 13 && !event.shiftKey && comment !== "") {
            event.preventDefault();
            postText();
            clean();
          }
      }}
      onKeyUp={onKeyUp}
      onClick={onClick}
    />
  );
};

CommentInput.defaultProps = {};

CommentInput.propTypes = {
  comment: PropTypes.string,
  placeholder: PropTypes.string,
  onKeyUp: PropTypes.func,
  postText: PropTypes.func,
  clean: PropTypes.func,
};
