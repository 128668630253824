import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { GoBell } from "react-icons/go";

const BellWrapper = styled.div`
  position: relative;
`;

const StyledGoBell = styled(GoBell)`
  padding: 10px;
  color: ${props => props.fillcolor};
  border: 0.5px solid ${props => props.fillcolor};
  border-radius: 100%;
  cursor: pointer;

  &:hover {
    color: ${props => props.hovercolor};
    border: 0.5px solid ${props => props.hovercolor};
  }
`;

const NotificationsCount = styled.div`
  font-family: Roboto;
  font-size: 0.7em;
  color: #ffffff;
  width: 20px;
  height: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid black;
  border-radius: 100%;
  top: -5px;
  right: -5px;
  cursor: default;
  background-color: #db3737;
`;

export const BellIcon = ({
  size,
  fillcolor,
  hovercolor,
  notificationsCount,
  className,
  onClick
}) => {
  return (
    <BellWrapper>
      <StyledGoBell
        className={className}
        size={size}
        fillcolor={fillcolor}
        hovercolor={hovercolor}
        onClick={onClick}
      />
      {notificationsCount > 0 && (
        <NotificationsCount>{notificationsCount}</NotificationsCount>
      )}
    </BellWrapper>
  );
};

BellIcon.defaultProps = {
  size: 20,
  fillcolor: "grey",
  hovercolor: "grey"
};

BellIcon.propTypes = {
  size: PropTypes.number,
  fillColor: PropTypes.string,
  hoverColor: PropTypes.string,
  onClick: PropTypes.func
};
