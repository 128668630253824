import styled from "styled-components";
import { ArrowLeft } from "../../../../common/arrow";
import { FormattedMessage } from "react-intl";
import React from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { showSuccessToast } from "../../../../../toast/toast-service";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USER_EMAIL_SETTINGS, CHANGE_USER_EMAIL_SETTINGS } from "./queries";
import { PrimarySpinner } from "../../../../spinner/primary-spinner";
import { USER_EMAIL_SETTINGS } from "../../../../../constants";
import _ from "lodash";

export const EmailSettingsBox = ({ setIsEmailMenuOpen }) => {
  const { data: userData, loading: loadingUserData } = useQuery(
    GET_USER_EMAIL_SETTINGS
  );

  const { SUMMARY, NEW_NOTIFICATIONS, NEW_CHAT_MESSAGE } = USER_EMAIL_SETTINGS;

  const [updateEmailsActivated] = useMutation(CHANGE_USER_EMAIL_SETTINGS, {
    refetchQueries: [GET_USER_EMAIL_SETTINGS],
    onCompleted: () => {
      showSuccessToast(
        <FormattedMessage id="notifications.emailSettings.toasts.update-successful" />
      );
    },
  });

  const changeEmailSettings = async (changedOptionKey) => {
    const isOptionAlreadySet =
      userData?.user?.emailSettings.includes(changedOptionKey);

    const newEmailSettings = isOptionAlreadySet
      ? _.without(userData?.user?.emailSettings, changedOptionKey)
      : userData?.user?.emailSettings.concat(changedOptionKey);

    await updateEmailsActivated({
      variables: {
        emailSettings: newEmailSettings,
      },
    });
  };

  const changeNoMailsSwitch = async () => {
    await updateEmailsActivated({
      variables: {
        emailSettings: [],
      },
    });
  };

  return (
    <Box>
      <HeadlineBox>
        <ArrowLeft onClick={() => setIsEmailMenuOpen(false)} />
        <Headline>
          <FormattedMessage id="notifications.emailSettings" />
        </Headline>
      </HeadlineBox>
      <SettingsBox>
        {loadingUserData && <PrimarySpinner />}
        {!loadingUserData && (
          <>
            <FormControlLabel
              control={
                <Switch
                  checked={
                    !!userData?.user?.emailSettings?.includes(NEW_NOTIFICATIONS)
                  }
                />
              }
              label={
                <OptionLabel>
                  <FormattedMessage id="notifications.emailSettings.on-new-notifications" />
                </OptionLabel>
              }
              onChange={() => changeEmailSettings(NEW_NOTIFICATIONS)}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={!!userData.user?.emailSettings?.includes(SUMMARY)}
                  onChange={async () => {
                    await changeEmailSettings(SUMMARY);
                  }}
                />
              }
              label={
                <OptionLabel>
                  <FormattedMessage
                    id={"notifications.emailSettings.summary-mail"}
                  />
                </OptionLabel>
              }
            />
            <FormControlLabel
              control={
                <Switch
                  checked={
                    !!userData.user?.emailSettings?.includes(NEW_CHAT_MESSAGE)
                  }
                  onChange={() => changeEmailSettings(NEW_CHAT_MESSAGE)}
                />
              }
              label={
                <OptionLabel>
                  <FormattedMessage
                    id={"notifications.emailSettings.on-new-chat-messages"}
                  />
                </OptionLabel>
              }
            />
            <FormControlLabel
              control={
                <Switch
                  checked={userData?.user?.emailSettings?.length === 0}
                  disabled={userData?.user?.emailSettings?.length === 0}
                />
              }
              label={
                <OptionLabel>
                  <FormattedMessage
                    id={"notifications.emailSettings.no-mails"}
                  />
                </OptionLabel>
              }
              onChange={() => changeNoMailsSwitch()}
            />
          </>
        )}
      </SettingsBox>
    </Box>
  );
};

const HeadlineBox = styled.div`
  display: flex;
  padding-left: 10px;
`;

const Headline = styled.div`
  margin: 15px;
  font-weight: 600;
  font-size: 19px;
  line-height: 26px;

  color: #383838;
`;

const Box = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 477px;
  height: 500px;
  margin-left: -407px;
  box-shadow: -1px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #fff;
  z-index: 5;

  @media only screen and (max-width: ${(props) =>
    props.theme.firstBreakpoint}px) {
    width: 100%;
    height: 100vh;
    top: 0;
    left:0;
    margin-left: 0px;
  ;
`;

const SettingsBox = styled.div`
  margin-top: 15px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;

const OptionLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  margin-left: 4px;
`;
