import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Redirect, useHistory } from "react-router-dom";
import { CONFIRM_REGISTRATION } from "../../apollo-client/mutations";
import { SpinningHubertus } from "../../components/spinner/spinning-hubertus";
import { showErrorToast } from "../../toast/toast-service";
import { getGraphQLErrorMessage } from "../../helpers/network-helpers";
import { StringParam, useQueryParam } from "use-query-params";
import { FormattedMessage } from "react-intl";

export const ActivateUserPage = () => {
  const history = useHistory();

  const [token] = useQueryParam("token", StringParam);

  const [confirmRegistration] = useMutation(CONFIRM_REGISTRATION, {
    onCompleted: (data) => {
      const jwt = data.confirmRegistration.jwt;
      if (jwt) {
        localStorage.setItem("token", jwt);
        history.push("/");
        window.location.reload();
      }
    },
    onError: (err) => {
      showErrorToast(getGraphQLErrorMessage(err));
      history.push("/");
    },
  });

  useEffect(async () => {
    await confirmRegistration({ variables: { token } });
  }, []);

  if (!token) {
    showErrorToast(
      <FormattedMessage id="landing-page.activate-user.no-token" />
    );
  }

  if (localStorage.getItem("token")) return <Redirect to={`/`} />;
  return <SpinningHubertus />;
};
