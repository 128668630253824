import { Stack, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import { QuestionMarkTooltip } from "../../../common/tooltip/question-mark-tooltip";
import React, { useState } from "react";
import { BlagButton } from "../../../common/BlagButton";
import { FormattedMessage } from "react-intl";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../toast/toast-service";
import { SUGGEST_NEW_COURSE } from "../../../layout/courseNav/search-course-nav/suggestNewCourseQueries";
import { useMutation } from "@apollo/client";

export const SuggestNewCourseBox = ({ selectedUniversityName }) => {
  const [newCourseTitel, setNewCourseTitel] = useState("");

  const [suggestNewCourse] = useMutation(SUGGEST_NEW_COURSE, {
    onCompleted: () => {
      setNewCourseTitel("");
      showSuccessToast(
        <FormattedMessage
          id={
            "courses-nav.search-course.suggest-new-course.send-button.success-toast"
          }
        />,
        10000
      );
    },
    onError: (error) => {
      console.error(error);
      showErrorToast(error.message);
    },
    variables: {
      title: newCourseTitel,
      university: selectedUniversityName,
    },
  });

  return (
    <Container>
      <Stack direction="row" justifyContent="space-between">
        <Typography>
          <FormattedMessage
            id={"courses-nav.search-course.suggest-new-course.motivation-text"}
          />
        </Typography>
        <QuestionMarkTooltip tooltipId="courses-nav.search-course.suggest-new-course.motivation-text.tooltip" />
      </Stack>
      <InputStack>
        <div>
          <Typography>
            <FormattedMessage
              id={
                "courses-nav.search-course.suggest-new-course.textbox.description-text"
              }
            />
          </Typography>
          <TextField
            style={{ marginTop: "30px" }}
            multiline
            label={
              <FormattedMessage id="courses-nav.search-course.suggest-new-course.textbox.placeholder" />
            }
            rows={4}
            fullWidth={true}
            value={newCourseTitel}
            onChange={(event) => {
              setNewCourseTitel(event.target.value);
            }}
          />
        </div>
        <SendCourseSuggestionButton
          disabled={newCourseTitel.length === 0}
          onClick={suggestNewCourse}
        >
          <FormattedMessage
            id={"courses-nav.search-course.suggest-new-course.send-button"}
          />
        </SendCourseSuggestionButton>
      </InputStack>
    </Container>
  );
};

const Container = styled.div`
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 30px;
  height: 100%;
  overflow: scroll;
`;

const InputStack = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  height: 100%;
  justify-content: space-between;
  box-sizing: border-box;
`;

const SendCourseSuggestionButton = styled(BlagButton)`
  margin-bottom: 50px;
  margin-top: 50px;
`;
