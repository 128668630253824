import { useHistory, useRouteMatch } from "react-router-dom";
import arrow_back from "../../../img/Arrow_Vector.svg";
import React from "react";
import styled from "styled-components";

export const MobileNavHeader = ({ text, isSelected, to }) => {
  const history = useHistory();
  const routeMatch = useRouteMatch();

  return (
    <NavLinkWrapper
      isSelected={isSelected}
      onClick={() => history.push(`/profile/${routeMatch.params.id}`)}
    >
      <ArrowBack src={arrow_back} onClick={() => {}} />
      <NavLinkText isSelected={isSelected}>{text}</NavLinkText>
    </NavLinkWrapper>
  );
};

const NavLinkWrapper = styled.div`
  display: flex;
  width: 80%;
  min-width: 200px;
  cursor: pointer;
  align-items: center;

  @media only screen and (min-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    display: none;
  }
`;

const ArrowBack = styled("img")`
  margin-right: 15px;
  color: #383838;
  margin-left: 10px;
`;

const NavLinkText = styled.div`
  font-style: normal;
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-weight: 600;
  font-size: 27px;
  line-height: 51px;

  color: #383838;
`;
