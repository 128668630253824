import React from "react";
import styled, { keyframes } from "styled-components";
import hubertus from "../../img/mustache_male_icon.png";

const BaseAnimation = styled.div`
  animation: rotate 1.5s linear infinite;
`;

const Rotate = keyframes`  
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;
const RotateAnimation = styled(BaseAnimation)`
  animation-name: ${Rotate};
  height: 25%;
  width: 25%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto auto;
`;

const Hubertus = styled.img`
  height: ${(props) => (props.small ? "24px" : "120px")};
  width: ${(props) => (props.small ? "24px" : "120px")};
`;

export const SpinningHubertus = React.memo((props) => {
  return (
    <RotateAnimation>
      <Hubertus src={hubertus} small={props.small} />
    </RotateAnimation>
  );
});
