import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import motivationImagePng from "./Tutoring_Motivation_General.png";

export const GeneralStartTutoringMotivation = () => {
  return (
    <Container>
      <SubscriberImageWrapper>
        <MotivationImage src={motivationImagePng} />
      </SubscriberImageWrapper>
      <TextWrapper>
        <GeneralStartTutoringMotivationText>
          <FormattedMessage
            id={"home-page.start-tutoring-motivation.motivation"}
          />
        </GeneralStartTutoringMotivationText>
      </TextWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 130%;
  font-weight: bold;
  font-size: 26px;
  line-height: 50px;
  background: #faeff0;
  border-radius: 20px 60px 60px 20px;
  //margin-top: 60px;

  display: flex;
  align-items: center;
  margin-bottom: 50px;
  justify-self: flex-start;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    width: 90%;
    border-radius: 20px 40px 40px 20px;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 60px;
  padding-right: 60px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const SubscriberImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MotivationImage = styled.img`
  width: 300px;
  height: 258px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    width: 120px;
    height: 120px;
  }
`;

const GeneralStartTutoringMotivationText = styled.div`
  text-align: center;

  font-weight: bold;
  font-size: 26px;
  line-height: 50px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
`;
