import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { Header } from "../../components/layout/header/header";
import { GET_USER } from "../../apollo-client/queries";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ArrowBackIcon } from "../../components/icons/icons-svg/arrow-back-icon";
import { EditProfileFields } from "./edit-profile-page/edit-profile-fields";
import { YourTutoring } from "./profile-page-teachings/your-tutoring";
import { ChangePictureDialog } from "./edit-profile-page/change-picture-dialog";
import { useIsMobile } from "../../hooks/state-checkers/use-is-mobile";
import { LoggedInSidebar } from "../../components/sidebars/logged-in-sidebar/logged-in-sidebar";
import { getSelectedArea } from "../../services/url-helpers";

//mit container um thumbnail mit min height usw und in thumbnail width und height auf 100% hat sich das dem Bild automatisch angepasst! gut oder sclecht?
export const ProfilePage = (props) => {
  const { data } = useQuery(GET_USER);

  const routeMatch = useRouteMatch();
  // const handlePrintContent = () => {
  //   switch (getSelectedArea(props.match.path)) {
  //     case "profile/my-content":
  //       return <YourContent />;
  //     case "profile/my-teachings":
  //       return <YourTutoring />;
  //     case "profile/my-groups":
  //       return <YourGroups />;
  //     default:
  //       return <YourTutoring />;
  //   }
  // };

  const profileSubdomain = routeMatch.path.split("/");

  return (
    <div>
      <Container>
        <LeftBar subdomainSelected={profileSubdomain[3]}>
          {data && (
            <Fragment>
              <LeftBarHeader>
                <FormattedMessage id="header.menu-edit-profile" />
              </LeftBarHeader>
              <NavLinksWrapper>
                <NavLink
                  text={<FormattedMessage id="courses-nav.tuition-offered" />}
                  isSelected={true}
                  to="my-teachings"
                />
                <NavLink
                  text={
                    <FormattedMessage id="your-content-nav-bar.your-study-groups" />
                  }
                  to="my-groups"
                />
                <NavLink
                  text={<FormattedMessage id={"edit-user-data-profile"} />}
                  to="edit"
                />
              </NavLinksWrapper>
            </Fragment>
          )}
        </LeftBar>
        {!profileSubdomain[3] && (
          <WebOnlyElement>
            <YourTutoring />
          </WebOnlyElement>
        )}
        {profileSubdomain[3] && (
          <ContentContainer>
            {profileSubdomain[3] === "my-teachings" && <YourTutoring />}
            {profileSubdomain[3] === ("edit" || "edit/change-pw") && (
              <EditProfileFields />
            )}
            {profileSubdomain[3] === "edit/picture-change" && (
              <ChangePictureDialog user={data.user} />
            )}
            <ChangePictureDialog />
          </ContentContainer>
        )}
      </Container>
      <Header error={false} loading={false} props={props} />
      <LoggedInSidebar />
    </div>
  );
};

const NavLink = ({ text, to }) => {
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const selectedArea = getSelectedArea(routeMatch.path);

  const isMobile = useIsMobile();

  const isSelected =
    !isMobile &&
    (`profile/${to}` === selectedArea ||
      // we show the teachings site as default when no subdomain is selected
      (selectedArea === "profile" && to === "my-teachings"));

  return (
    <NavLinkWrapper
      isSelected={isSelected}
      onClick={() => history.push(`/profile/${routeMatch.params.id}/${to}`)}
    >
      <NavLinkText isSelected={isSelected}>{text}</NavLinkText>
      <ArrowBackIcon
        color={isSelected ? "#C23D3E" : "#383838"}
        width={isSelected ? 30 : 20}
        height={isSelected ? 30 : 20}
      />
    </NavLinkWrapper>
  );
};

const NavLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
`;

const NavLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: ${(props) => (props.isSelected ? () => "90%" : "80%")};
  min-width: 200px;
  cursor: pointer;
`;

const NavLinkText = styled.div`
  font-style: normal;
  font-weight: ${(props) => (props.isSelected ? "bold" : 600)};
  font-size: ${(props) => (props.isSelected ? "27px" : "24px")};
  line-height: 33px;
  display: flex;
  align-items: center;

  color: #383838;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 120px;
`;

const WebOnlyElement = styled.div`
  width: 100%;
  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    display: none;
  }
`;

const LeftBar = styled.div`
  margin-left: 20px;
  min-width: 370px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    width: 100%;
    display: ${(props) => (props.subdomainSelected ? "none" : "block")};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LeftBarHeader = styled.div`
  color: #383838;
  font-weight: 600;
  font-size: 37px;
  line-height: 51px;
`;
