import styled from "styled-components";

export const MotivationSection = () => {
  return (
    <MotivationContent>
      <Title>Einfach und Effektiv mit Blagoom</Title>
      <Subsection>Suche perfekte Lehrer*innen</Subsection>
      <p>
        Wähle deine Universität aus, suche deine Kurse in der Kurssuche und sehe
        direkt welche Nachhilfelehrer*innen zu dir passen. Dabei soll dir
        Beschreibung, Preis, Bewertung, Kommentare anderer und die Angabe, ob
        Onlineunterricht angeboten wird helfen.
      </p>
      <Subsection>Besprecht die Details</Subsection>
      <p>
        Schreibe über unseren Chat, ganz einfach mit Lehrer*innen über alle
        notwendigen Details. Planung des Unterrichts, Fähigkeiten der Lehrenden
        usw.
      </p>
      <Subsection>Und dann geht’s schon los</Subsection>
      <p>
        Dann kannst du direkt loslegen! Keine weiteren nervigen Hürden. Und wenn
        es dir gefällt, hilft dir unsere Website vielleicht auch noch in anderen
        Kursen. Wir würden uns freuen!
      </p>
    </MotivationContent>
  );
};

const Title = styled.p`
  font-weight: 700;
  font-size: 37px;
`;

const Subsection = styled.p`
  font-weight: 600;
  font-size: 24px;
`;

const MotivationContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
`;
