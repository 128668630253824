import React from "react";
import styled from "styled-components";
import { useIsListPage } from "../../hooks/state-checkers/use-is-list-page";

//TOGGLE EXTRA WINDOW GERADE WAHRSCHEINLICH UNNÖTIG!!! TOGGLE GERADE DIREKT REPORT UND EDIT!!! SO GEHEN GERADE JEDOCH DIE ANIMATIONEN NICHT!!! (close kann wahrscheinlich weg)
export const ContentContainer = (props) => {
  const isListPage = useIsListPage();

  return <Container isListPage={isListPage}>{props.children}</Container>;
};

const Container = styled.div`
  position: absolute;
  bottom: 0;
  height: calc(100% - 230px);
  left: 20%;
  right: 15%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media only screen and (max-width: ${(props) =>
    props.theme.secondBreakpoint}px) {
    left: 0px;
    right: 0px;
    width: 100%;
    height: calc(100% - 220px);
    top: ${(props) => (props.isListPage ? "" : "120px")}
`;
