import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";

export const PopUpDescription = ({
  globalUniqueId,
  description,
  onKeyUp,
  className
}) => {
  const intl = useIntl();

  const descriptionPlaceholder = intl.formatMessage({
    id: "teaching-description-placeholder"
  });

  return (
    <EditableDiv
      id={globalUniqueId}
      contentEditable={true}
      placeholder={descriptionPlaceholder}
      onKeyUp={onKeyUp}
      className={className}
    >
      {description}
    </EditableDiv>
  );
};

//TODO padding einfügen und box-sizing: border-box
const EditableDiv = styled.div`
  padding: 5px;
  box-sizing: border-box;
  min-height: 120px;
  max-height: 120px;
  min-width: 100%;
  max-width: 100%;
  font-size: 13px;
  border: transparent;
  background-color: white;
  overflow-y: scroll;
  border-radius: 3px;
  box-shadow: 0.52px 0.52px 0 0 #9c9c9c, -0.52px -0.52px 0 0 #9c9c9c;

  &[contentEditable="true"]:empty:before {
    content: attr(placeholder);
    color: #9c9c9c;
    cursor: text;
  }

  &[contentEditable]:focus {
    outline: 0 solid transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: transparent;
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: transparent;
      background-color: lightgrey;
    }
  }
`;
