import React, { useLayoutEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { UniversityDropdown } from "../university-dropdown";
import { TutoringCourse } from "./tutoring-course";
import { GET_USER_TEACHINGS } from "../../../apollo-client/queries/teaching-queries";
import { PrimarySpinner } from "../../../components/spinner/primary-spinner";
import { NoResults } from "../no-results";
import { QuestionMarkTooltip } from "../../../components/common/tooltip/question-mark-tooltip";
import { Switch } from "../../../components/common/switch";
import { FormattedMessage } from "react-intl";
import { VacationActivationDialog } from "./vacation-activation-dialog";
import { useOpenClose } from "../../../hooks/use-open-close";
import { VacationDeactivationDialog } from "./vacation-deactivation-dialog";
import { MobileNavHeader } from "../edit-profile-page/mobile-nav-header";

export const YourTutoring = () => {
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [universities, setUniversities] = useState([]);

  const {
    value: isActivateVacationDialogOpen,
    toggle: toggleVacActivationDialog,
  } = useOpenClose(false);
  const {
    value: isDeactivateVacationDialogOpen,
    toggle: toggleVacDeactivationDialog,
  } = useOpenClose(false);

  const {
    data: dataTeachings,
    loading: loadingTeachings,
    error: errorTeachings,
  } = useQuery(GET_USER_TEACHINGS, {
    fetchPolicy: "cache-and-network",
  });

  const toggleVacation = () => {
    dataTeachings && !dataTeachings.user.onHoliday
      ? toggleVacActivationDialog()
      : toggleVacDeactivationDialog();
  };

  //maybe useCallback is better
  /*
    Finds universities from the user's teachings and sets the first university as the selected one
   */
  const handleSetUniversities = () => {
    if (dataTeachings) {
      const activeAndUnactiveUserteachings = [
        ...dataTeachings.user.teachings.teachings,
        ...dataTeachings.user.inactiveTeachings.teachings.filter(
          (teaching) => !teaching.isDeleted
        ),
      ];

      let universityArray = [];
      activeAndUnactiveUserteachings.forEach((teaching) => {
        if (!universityArray.includes(teaching.course.university))
          universityArray.push(teaching.course.university);
      });
      setSelectedUniversity(universityArray[0]);
      setUniversities(universityArray);
    }
  };

  useLayoutEffect(() => {
    handleSetUniversities();
  }, [dataTeachings]);

  if (loadingTeachings) {
    return (
      <SpinnerWrapper>
        <StyledPrimarySpinner />
      </SpinnerWrapper>
    );
  }

  if (!loadingTeachings && !errorTeachings && universities.length === 0) {
    return <NoResults />;
  }

  return (
    <Container>
      {!loadingTeachings && !errorTeachings && universities.length > 0 && (
        <Wrapper>
          <MobileNavHeader text={"Angebotene Nachhilfe"} />
          <UniversityDropdownVacationSwitchWrapper>
            <UniversityDropdown
              selectedUniversityState={{
                selectedUniversity,
                setSelectedUniversity,
              }}
              universities={universities}
            />
            <MobileNoShow>
              <VacationWrapper>
                <VacationSwitchQuestionMarkWrapper>
                  <Switch
                    checked={dataTeachings && dataTeachings.user.onHoliday}
                    toggler={toggleVacation}
                  />
                  <VacationText onClick={toggleVacation}>
                    <FormattedMessage id="your-tutoring.vacation-mode" />
                  </VacationText>
                  <QuestionMarkTooltip tooltipId="your-tutoring.vacation-mode.tooltip" />
                </VacationSwitchQuestionMarkWrapper>
                {dataTeachings && dataTeachings.user.onHoliday && (
                  <OnVacationText>
                    <FormattedMessage
                      id={"your-tutoring.vacation-mode-chat.info"}
                    />
                  </OnVacationText>
                )}
              </VacationWrapper>
            </MobileNoShow>
          </UniversityDropdownVacationSwitchWrapper>
          <TeachingsContainer
            blur={dataTeachings && dataTeachings.user.onHoliday}
          >
            {dataTeachings.user.teachings.teachings
              .filter(
                (teaching) => teaching.course.university === selectedUniversity
              )
              .map((teaching, i) => (
                <TutoringCourse
                  key={teaching.course.title + "_" + i}
                  title={teaching.course.title}
                  teaching={teaching}
                  user={dataTeachings.user}
                />
              ))}
            {dataTeachings.user.inactiveTeachings.teachings
              .filter(
                (teaching) => teaching.course.university === selectedUniversity
              )
              .filter((teaching) => !teaching.isDeleted)
              .map((teaching, i) => (
                <TutoringCourse
                  key={teaching.course.title + "_" + i}
                  title={teaching.course.title}
                  teaching={teaching}
                  user={dataTeachings.user}
                />
              ))}
          </TeachingsContainer>
          <VacationActivationDialog
            isDialogOpen={isActivateVacationDialogOpen}
            toggleDialog={toggleVacActivationDialog}
          />
          <VacationDeactivationDialog
            isDialogOpen={isDeactivateVacationDialogOpen}
            toggleDialog={toggleVacDeactivationDialog}
          />
        </Wrapper>
      )}
    </Container>
  );
};

/*
  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    display: none;
  }
 */

const MobileNoShow = styled.div`
  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div``;

const UniversityDropdownVacationSwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 100px;
  margin-top: 7px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    margin-top: 12px;
    margin-right: 0px;
    justify-content: center;
  }
`;

const VacationSwitchQuestionMarkWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const VacationText = styled.div`
  margin-left: 5px;
  margin-right: 20px;
  cursor: pointer;
`;

const SpinnerWrapper = styled.div`
  width: 75%;
`;

const StyledPrimarySpinner = styled(PrimarySpinner)`
  margin: 50px auto;
`;

const TeachingsContainer = styled.div`
  margin-top: 90px;
  opacity: ${(props) => (props.blur ? 0.5 : 1)};

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

const VacationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const OnVacationText = styled.div`
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  align-items: center;
  text-align: center;

  color: #f19191;
`;
