import React, { useState, useRef } from "react";
import styled from "styled-components";
import { IoMdMore, IoIosFlag } from "react-icons/io";
import settings from "../../../img/edit_icon.svg";
import PropTypes from "prop-types";
import { useOutsideMenuCloserWithFunction } from "../../../helpers";
import { FormattedMessage } from "react-intl";
import { useRecoilState } from "recoil/dist";
import { reportState } from "../../../store/atoms";
import { ConfirmationDialog } from "../../../components/dialogs/confirmation-dialog";
import { useOpenClose } from "../../../hooks/use-open-close";

const Container = styled.div`
  position: relative;
`;

const SettingsImage = styled("img")`
  height: 20px;
  width: 20px;
  z-index: 1;
`;

const StyledIoMdMore = styled(IoMdMore)`
  color: grey;

  &:hover {
    color: black;
  }
`;

const SettingsBox = styled.div`
  position: absolute;
  min-width: 130px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  margin-left: ${(props) => (props.mirrorSite ? "-100px" : 0)};

  background: #ffffff;
  box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
`;

const Clickable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  &:hover {
    color: #f86b6b;
  }
`;

const Text = styled.div`
  margin-right: 25px;
`;

const FlagIcon = styled(IoIosFlag)`
  width: 20px;
  height: 20px;
`;

export const TeacherSettings = ({
  toggleEdit,
  deleteMutation,
  isContributor,
  pointIcon,
  pointIconSize,
  imageWidth,
  imageHeight,
  mirrorSite,
  openOnTop,
  reportId,
  contentType,
  disableDelete,
  className,
}) => {
  const [showBox, setShowBox] = useState(false);
  const [report, setReport] = useRecoilState(reportState);

  const wrapperRef = useRef(null);
  useOutsideMenuCloserWithFunction(wrapperRef, () => setShowBox(false));

  const handleDeactivateMutation = () => {
    deleteMutation();
    setShowBox(false);
  };

  const { value: isDeactivateDialogOpen, toggle: toggleDeactivateDialogOpen } =
    useOpenClose(false);

  return (
    <Container ref={wrapperRef} className={className}>
      <ConfirmationDialog
        title={
          <FormattedMessage id="teacher.details.profile.deactivate-dialog.title" />
        }
        content={
          <FormattedMessage
            id={"teacher.details.profile.deactivate-dialog.description"}
          />
        }
        isOpen={isDeactivateDialogOpen}
        toggle={toggleDeactivateDialogOpen}
        action={handleDeactivateMutation}
      />
      {!pointIcon ? (
        <SettingsImage
          onClick={() => setShowBox(!showBox)}
          src={settings}
          style={{ width: imageWidth, height: imageHeight, cursor: "pointer" }}
        />
      ) : (
        <StyledIoMdMore
          size={pointIconSize}
          onClick={() => setShowBox(!showBox)}
          style={{ cursor: "pointer" }}
        />
      )}
      {showBox && (
        <SettingsBox
          mirrorSite={mirrorSite}
          openOnTop={openOnTop}
          isContributor={isContributor}
        >
          {isContributor && (
            <div>
              <Clickable
                onClick={() => {
                  toggleEdit();
                  setShowBox(false);
                }}
              >
                <Text>
                  <FormattedMessage id={"general.edit"} />
                </Text>
              </Clickable>
              {!disableDelete && (
                <Clickable onClick={toggleDeactivateDialogOpen}>
                  <Text>
                    <FormattedMessage
                      id={"teacher.details.profile.deactivate"}
                    />
                  </Text>
                </Clickable>
              )}
            </div>
          )}
          {!isContributor && (
            <Clickable
              onClick={() =>
                setReport({
                  isReportOpen: true,
                  contentId: reportId,
                  contentType: contentType,
                })
              }
            >
              <Text>
                <FormattedMessage id={"general.report"} />
              </Text>
              <FlagIcon />
            </Clickable>
          )}
        </SettingsBox>
      )}
    </Container>
  );
};

TeacherSettings.defaultProps = {
  imageWidth: "20px",
  imageHeight: "20px",
};

TeacherSettings.propTypes = {
  pointIcon: PropTypes.bool,
  pointIconSize: PropTypes.number,
  isContributor: PropTypes.bool.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  mirrorSite: PropTypes.bool,
  reportId: PropTypes.string,
  contentType: PropTypes.string,
};
