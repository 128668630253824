import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { PrimarySpinner } from "../../../../spinner/primary-spinner";
import { useApolloClient, useLazyQuery, useSubscription } from "@apollo/client";
import {
  GET_MESSAGES,
  GET_USER_CHATS,
} from "../../../../../apollo-client/queries";
import { MessageWithTime } from "./messageWithTime";
import {
  MESSAGE_SUBSCRIPTION,
  NEW_MESSAGE_STATE_SUBSCRIPTION,
} from "../../../../../apollo-client/subscriptions";
import { FormattedMessage } from "react-intl";
import { QuestionMarkTooltip } from "../../../../common/tooltip/question-mark-tooltip";

const messagesLimit = 30;

export const MessageContainer = ({ chosenChat, postingMessage }) => {
  const scrollRef = useRef(null);
  const endOfMessagesRef = useRef(null);

  const client = useApolloClient();

  const [fixedDate, setFixedDate] = useState(null);
  const [toggleFixedDate, setToggleFixedDate] = useState(true);
  const [fetchingOlderMessages, setFetchingOlderMessages] = useState(false);

  const scrollToBottom = (behavior = "auto") => {
    endOfMessagesRef.current?.scrollIntoView({ behavior });
  };

  const [
    getMessages,
    {
      data: dataMessages,
      loading: loadingMessages,
      error: errorMessages,
      fetchMore: fetchMoreMessages,
    },
  ] = useLazyQuery(GET_MESSAGES, {
    variables: {
      _id: chosenChat.chatId,
      limit: messagesLimit,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const chatMessagesData = dataMessages?.user?.chats?.chats?.[0];
      if (chatMessagesData?.messages.length <= messagesLimit) {
        scrollToBottom();
      }
      // nicht runterscrollen, wenn man oben rumschaut
    },
  });

  const chatMessagesData = dataMessages?.user?.chats?.chats?.[0];

  // load messages when selecting a new chat
  useEffect(async () => {
    await getMessages();
  }, [chosenChat]);

  // scroll to bottom when posting new message
  useEffect(() => {
    scrollToBottom("smooth");
  }, [postingMessage]);

  const fetchOlderMessagesOnScroll = async (event) => {
    if (
      scrollRef.current.scrollTop === 0 &&
      chatMessagesData?.messages.length < chatMessagesData?.messagesCount
    ) {
      const oldScrollHeight = scrollRef.current.scrollHeight;

      setFetchingOlderMessages(true);

      await fetchMoreMessages({
        variables: {
          limit: messagesLimit,
          skip: chatMessagesData.messages.length,
        },
      });

      setFetchingOlderMessages(false);

      scrollRef.current.scrollTop =
        scrollRef.current.scrollHeight - oldScrollHeight;
    }
  };

  useSubscription(MESSAGE_SUBSCRIPTION, {
    onSubscriptionData: async () => {
      await client.refetchQueries({ include: [GET_MESSAGES, GET_USER_CHATS] });
    },
  });

  useSubscription(NEW_MESSAGE_STATE_SUBSCRIPTION);

  return (
    <Container
      ref={scrollRef}
      onScroll={(event) => fetchOlderMessagesOnScroll(event)}
    >
      {/*{dataMessages && toggleFixedDate && <Time fixed>{fixedDate}</Time>}*/}
      {fetchingOlderMessages && <StyledPrimarySpinner />}
      {dataMessages &&
        chatMessagesData &&
        chatMessagesData._id === chosenChat.chatId &&
        chatMessagesData.messages.map(
          (messageObject, messageIndex, messages) => {
            return (
              <MessageWithTime
                key={messageIndex}
                messageObject={messageObject}
                dataMessages={dataMessages}
                messageIndex={messageIndex}
                messages={messages}
                chatType={chosenChat.type}
                isFirstUnseenMessage={
                  !messageObject?.wasReadByMe &&
                  messages[messageIndex - 1]?.wasReadByMe
                }
              />
            );
          }
        )}
      {/*Dies sollte der Message Preview werden, aber schweeer.. alle Attribute zu*/}
      {/*liefern*/}
      {/*{postingMessage !== "" && (*/}
      {/*  <MessageWithTime*/}
      {/*    messageObject={{*/}
      {/*      author: {*/}
      {/*        username: "Dave2",*/}
      {/*      },*/}
      {/*      createdAt: new Date().getTime(),*/}
      {/*      isAuthor: true,*/}
      {/*      message: postingMessage,*/}
      {/*      wasDelivered: false,*/}
      {/*      wasRead: false,*/}
      {/*      wasReadByMe: true,*/}
      {/*    }}*/}
      {/*    dataMessages={dataMessages}*/}
      {/*    messageIndex={chatMessagesData?.messages.length}*/}
      {/*    messages={[...chatMessagesData?.messages]}*/}
      {/*    // firstNewMessageId={firstNewMessageId}*/}
      {/*    chatType={chosenChat.type}*/}
      {/*    postingMessage={!!postingMessage}*/}
      {/*  />*/}
      {/*)}*/}
      <div ref={endOfMessagesRef} />
      {chosenChat?.isBlockedBy?.length > 0 && (
        <Row>
          <IsBlockedText>
            <FormattedMessage id={"chat.isBlocked"} />
          </IsBlockedText>
          <QuestionMarkTooltip tooltipId={"chat.vacation.hint"} />
        </Row>
      )}
      {loadingMessages && <StyledPrimarySpinner />}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 35px 0 50px 0;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: transparent;
      border-radius: 0px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      -webkit-box-shadow: transparent;
      background-color: ${(props) => props.theme.scrollBarColor};
    }
  }
`;

const StyledPrimarySpinner = styled(PrimarySpinner)`
  min-height: 20px;
  min-width: 20px;
  align-self: center;
`;

const IsBlockedText = styled.h3`
  color: ${(props) => props.theme.mainColor};
  //align-self: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;

  //align-self: center;
`;
