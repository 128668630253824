import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const SwitchLabel = styled.div`
  position: relative;
  display: inline-block;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const RoundSlider = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #efd2d2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    //height - 2*left
    width: ${props => props.height - 2 * props.left}px;
    height: ${props => props.height - 2 * props.left}px;
    left: ${props => props.left}px;
    bottom: 50%;
    //-beforeHeight/2
    margin-bottom: ${props => -((props.height - 2 * props.left) / 2)}px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const Input = styled.input.attrs({ type: "checkbox" })`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${RoundSlider} {
    background-color: #f86b6b;
  }

  &:focus + ${RoundSlider} {
    box-shadow: 0 0 1px #f86b6b;
  }

  &:checked + ${RoundSlider}:before {
    //width - beforeWidth - 2*left
    -webkit-transform: ${props =>
      "translateX(" +
      (props.width - (props.height - 2 * props.left) - 2 * props.left + "px)")};
    -ms-transform: ${props =>
      "translateX(" +
      (props.width - (props.height - 2 * props.left) - 2 * props.left + "px)")};
    transform: ${props =>
      "translateX(" +
      (props.width - (props.height - 2 * props.left) - 2 * props.left + "px)")};
  }
`;

export const Switch = ({ checked, toggler, width, height, left }) => {
  return (
    <SwitchLabel width={width} height={height} onClick={toggler}>
      <Input
        checked={checked}
        id={"check"}
        width={width}
        height={height}
        left={left}
        readOnly
      />
      <RoundSlider width={width} height={height} left={left} />
    </SwitchLabel>
  );
};

Switch.defaultProps = {
  width: 40,
  height: 20,
  left: 4
};

Switch.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  left: PropTypes.number
};
