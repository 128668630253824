import React from "react";
import { ConfirmationDialog } from "../../../../dialogs/confirmation-dialog";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import {
  DELETE_CHAT,
  MARK_CHAT_MESSAGES_AS_READ,
} from "../../../../../apollo-client/mutations";
import { GET_USER_CHATS } from "../../../../../apollo-client/queries";
import { showSuccessToast } from "../../../../../toast/toast-service";

export const ChatDeleteDialog = ({
  isOpen,
  toggle,
  chosenChat,
  setChosenChat,
}) => {
  const [deleteCh] = useMutation(DELETE_CHAT, {
    variables: { id: chosenChat.chatId },
    refetchQueries: [GET_USER_CHATS, "getUserChats"],
  });

  const [markChatMessagesAsRead] = useMutation(MARK_CHAT_MESSAGES_AS_READ);

  const markMessagesAsRead = async (chosenChatId) => {
    await markChatMessagesAsRead({
      variables: {
        id: chosenChatId || chosenChat.chatId,
      },
    });
  };

  const deleteChat = async () => {
    const data = await deleteCh();
    showSuccessToast(<FormattedMessage id="chat.delete-chat.successful" />);

    const newChosenChat = data?.data?.deleteChat?.chats?.chats?.[0];

    if (!newChosenChat) {
      setChosenChat({});
    } else {
      setChosenChat({
        ...newChosenChat,
        chatTitle: newChosenChat.title,
        chatId: newChosenChat._id,
        profilePicture:
          newChosenChat.picture ||
          newChosenChat.otherPrivateChatMember.profilePicture,
        isOnVacation: newChosenChat.otherPrivateChatMember.profilePicture,
      });

      await markMessagesAsRead(newChosenChat._id);
    }

    toggle();
  };

  return (
    <ConfirmationDialog
      title={<FormattedMessage id="chat.delete-chat" />}
      isOpen={isOpen}
      toggle={toggle}
      content={<FormattedMessage id="chat.delete-chat.text" />}
      action={deleteChat}
    />
  );
};
