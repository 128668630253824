import { Header } from "../../components/layout/header/header";
import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { MainLayout } from "../../components/layout/main-layout";
import { Footer } from "../landing-page/footer";
import { ContentContainer } from "../../components/layout/content-container";
import { InfoPagesLayout } from "../../components/layout/info-pages-layout";

export const ImprintPage = () => {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <InfoPagesLayout title={"Impressum"}>
        <Section>Impressum</Section>
        <p>
          Die Website blagoom.com wird betrieben von: Blagoom GbR
          vertretungsberechtigte Gesellschafter: Manuel Walzog, David Schmitd
          und Jonas Gebauer Werrastraße 13, 12059 Berlin Telefon: E-Mail:
          Steuernummer: Redaktionell Verantwortliche: Manuel Walzog, David
          Schmidt und Jonas Gebauer Werrastraße 13, 12059 Berlin Link auf die
          Plattform der EU-Kommission zur Online-Streitbeilegung:
          https://ec.europa.eu/consumers/odr Zur Teilnahme an einem
          Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind
          wir nicht verpflichtet und grundsätzlich nicht bereit. Weitere
          Informationen unter: www.abmahnung.de/os-plattform/
        </p>

        <Section>Datenschutzerklärung</Section>
        <SubSection>
          1. Schutz und Sicherheit von personenbezogenen Daten
        </SubSection>
        <p>
          Um die Dienste auf den Webseiten von Blagoom in Anspruch zu nehmen,
          ist es notwendig, einige personenbezogene Daten zu erfassen. Blagoom
          geht mit dem Thema Sicherheit verantwortungsvoll um und verpflichtet
          sich deshalb, die gesetzlichen Bestimmungen zum Schutz
          personenbezogener Daten unbedingt einzuhalten. In diesem Sinne werden
          personenbezogene Daten lediglich zur Wahrung berechtigter eigener
          Geschäftsinteressen im Hinblick auf die Beratung und Betreuung von
          Nutzern erhoben, verarbeitet und genutzt. Blagoom unternimmt alle
          Anstrengungen, um die Daten, die Nutzer uns anvertrauen, vor
          unberechtigtem Zugriff zu schützen. Durch das sichere
          Übertragungsverfahren von Nutzerdaten im Rahmen einer
          SSL-Verschlüsselung wird große Datensicherheit gewährleistet.
          Darüberhinaus ermächtigen Sie uns, ihren Namen, ihr Profilbild und
          damit verbundene Inhalte mit kommerziellen, gesponserten Inhalten zu
          verwenden, die wir verbreiten oder verbessern. Die Daten, die Sie uns
          anvertrauen, sind bei uns sicher. Bei Blagoom findet kein Verkauf,
          Tausch oder sonstiger unautorisierter Gebrauch von persönlichen Daten
          und Informationen statt. Wir stellen sicher, dass Ihre persönlichen
          Daten nicht unautorisiert an andere Unternehmen oder Organisationen
          weitergegeben werden.
        </p>
        <SubSection>
          2. Erhebung notwendiger personenbezogener Daten durch Blagoom
        </SubSection>
        <p>
          Zur Erbringung der Dienstleistung von Blagoom ist es notwendig, dass
          wir die uns anvertrauten personenbezogenen Daten unter Beachtung der
          Datenschutzbedingungen erheben, speichern, verarbeiten und nutzen.
          Personenbezogene Daten sind Angaben zu Ihrer Identität wie Name,
          Vorname, Adresse, E-Mail-Adresse, Mobiltelefonnummer,
          Festnetz-Telefonnummer (wenn vorhanden), Geschlecht, Geburtsdatum,
          IP-Adresse. Zur Inanspruchnahme der von Blagoom angebotenen Dienste
          verwenden wir die personenbezogenen Daten, die Sie uns aktiv mitteilen
          (z.B. bei der Registrierung Ihrer Profildaten). Darüber hinaus werden
          bei Ihrem Besuch auf unserer Webseite sowie im Rahmen Ihrer
          Anmeldungen gegebenenfalls weitere Daten erhoben (z.B. IP-Adresse,
          Datum, Uhrzeit des jeweiligen Zugriffs, Name des von Ihnen verwendeten
          Browsers und Betriebssystems). Um Sie bei der Suche nach einem
          geeigneten Lehrer zu unterstützen und für den Fall, dass mehrere
          ausgewählte Lehrer auf Ihre Anfrage nicht antworten, behalten wir uns
          vor, Ihre Unterrichtsanfrage an weitere Lehrer weiterzuleiten. Die
          Erhebung, Speicherung, Verarbeitung und Nutzung dieser Daten erfolgt
          ausschließlich zur Erfüllung der durch den Nutzer nachgefragten
          Dienste und für interne Zwecke wie den Betrieb, die Sicherheit und die
          Optimierung der Webseiten von Blagoom. Du kannst die Nutzung Ihrer
          Daten jederzeit widerrufen bzw. künftigen Verwendungen Ihrer Daten
          widersprechen (Art. 21 Abs. 2 DSGVO). Schicke uns hierzu bitte eine
          E-Mail an: hallo (at) blagoom Punkt com.
        </p>

        <SubSection>3. Weitere Datennutzung</SubSection>
        <SubSection>3.1 Verwendung von Facebook Social Plugins</SubSection>
        <p>
          Der Internetauftritt von Blagoom verwendet Social Plugins des sozialen
          Netzwerks facebook.com, das von der Facebook Inc., 1601 Willow Road,
          Menlo Park, California, 94025, USA betrieben wird (im folgenden
          "Facebook"). Die Plugins sind mit einem Facebook-Logo oder dem Zusatz
          "Facebook Social Plugin" gekennzeichnet. Sobald Sie eine Webseite
          unseres Internetauftritts aufrufen, die ein solches Plugin enthält,
          wird durch Ihren Browser eine direkte Verbindung mit Facebook
          aufgebaut. Facebook übermittelt den Inhalt des Plugins direkt an Ihren
          Browser und wird von diesem auf unserer Webseite eingebunden. Dabei
          erhält Facebook die Information, dass Sie eine entsprechende Seite
          unseres Internetangebots aufgerufen haben. Sofern Sie zeitgleich bei
          Facebook eingeloggt sind, kann Facebook Sie so authentifizieren und
          diesen Besuch Ihren Facebook-Benutzerkonto zuordnen. Wenn Sie darüber
          hinaus mit dem Plugin interagieren, also beispielsweise den "Gefällt
          mir”-Button drücken, sendet Ihr Browser die entsprechende Information
          an Facebook und Facebook speichert diese Information. Die so
          erhaltenen Informationen können wir für die Anzeige von Facebook Ads
          nutzen. Alle weiteren Informationen zu Zweck und Umfang der
          Datenerhebung, die weitere Verarbeitung, Nutzung und Speicherung der
          Daten durch Facebook sowie Ihre diesbezüglichen Rechte und
          Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre entnehmen Sie
          bitte den Datenschutzhinweisen von Facebook. Wenn Sie nicht möchten,
          dass Facebook über unseren Internetauftritt Daten über Sie sammelt,
          empfehlen wir Ihnen, den Facebook-Button nicht anzuklicken, und
          insbesondere empfehlen wir Ihnen, dass Sie sich vor Ihrem Besuch
          unseres Internetauftritts bei Facebook ausloggen. Falls Sie keine
          Datenerfassung via Custom Audience wünschen, können Sie Custom
          Audiences hier deaktivieren.
        </p>
        <SubSection>3.2 Verwendung des Social Plugins von Twitter</SubSection>
        <p>
          Weiterhin verwendet Blagoom Social Plugins („Plugins“) des sozialen
          Netzwerks twitter.com, betrieben durch die Twitter Inc., 795 Folsom
          St., Suite 600, San Francisco, CA 94107, USA. Die Plugins sind mit
          einem Twitter-Logo gekennzeichnet. Wenn Du eine Blagoom-Webseite
          aufrufst, die ein solches Plugin enthält, baut Ihr Browser eine
          direkte Verbindung mit den Twitter-Servern auf. Twitter übermittelt
          den Inhalt des Plugins direkt an Ihren Browser, der ihn in die
          Webseite einbindet. Dadurch erhält Twitter die Information, dass Sie
          die entsprechende Blagoom-Seite aufgerufen haben. Wenn Sie gerade
          zeitgleich bei Twitter eingeloggt sind, kann Twitter den Besuch Ihres
          Twitter-Konto zuordnen. Wenn Sie mit den Twitter-Plugins interagieren
          (z.B. einen Tweet zu einem Artikel postest), wird die entsprechende
          Information von Ihrem Browser direkt an Twitter übermittelt und dort
          gespeichert. Zweck und Umfang der Datenerhebung und die weitere
          Verarbeitung und Nutzung der Daten durch Twitter sowie Ihre
          diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer
          Privatsphäre können Sie den Twitter-Datenschutzbestimmungen entnehmen.
          Wenn Sie nicht möchten, dass Twitter auf diese Art Daten über Sie
          sammelt, empfehlen wir Ihnen, sich vor Ihrem Blagoom-Besuch bei
          Twitter auszuloggen.
        </p>
        <SubSection>3.3 Verwendung von Facebook Connect</SubSection>
        <p>
          Der Internetauftritt von Blagoom bietet Dir die Möglichkeit, Dich auf
          der Webseite mit Deinen bestehenden Facebook-Profildaten anzumelden.
          Hierzu verwenden wir den Dienst “Facebook Connect”, ein Angebot der
          Facebook Inc., 1601 Willow Road, Menlo Park, California, 94025, USA
          („Facebook“). Sofern Sie diese Funktion nutzen möchten, werden Sie
          zunächst auf Facebook weitergeleitet. Dort werden Sie aufgefordert,
          sich mit Ihrem Benutzernamen und Passwort anzumelden. Wir nehmen von
          Ihren Anmeldedaten selbstverständlich keine Kenntnis. Sind Sie im
          selben Browser bereits bei Facebook angemeldet, wird dieser Schritt
          übersprungen. Im Anschluss werden - entsprechend Ihrer
          Privatsphäre-Einstellungen auf Facebook - Ihre E-Mail-Adresse und Ihre
          öffentlichen Profilinformationen (insbesondere Name, Profilbild,
          Geburtsdatum, Geschlecht, Sprache und Land) an uns übermittelt, wenn
          Sie den Vorgang mit dem Button „Mit Facebook einloggen“ bestätigen. Es
          findet somit eine dauerhafte Verknüpfung zwischen Ihrem Benutzerkonto
          auf Blagoom und Ihrem Account bei Facebook statt. Weitere
          Informationen zu Facebook Connect und den Einstellungsmöglichkeiten
          zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den
          Datenschutzhinweisen von Facebook. Besonderer Hinweis zu Ihrem Recht
          auf Berichtigung & Löschung ihrer via Facebook Connect übertragenen
          personenbezogenen Daten: Wenn Sie die Dienste von Blagoom nicht mehr
          nutzen und ihren Blagoom-Account komplett löschen möchten,
          kontaktieren Sie uns bitte per E-Mail und wir löschen ihre bei uns
          gespeicherten Daten. Wenn Sie Blagoom weiterhin nutzen, jedoch Ihren
          Blagoom-Account dauerhaft von Ihrem Facebook-Account trennen möchten,
          kontaktieren Sie uns bitte ebenfalls per E-Mail und teilen uns mit,
          welche E-Mail-Adresse zukünftig in Ihrem Benutzerkonto hinterlegt sein
          soll.
        </p>
        <SubSection>
          3.4 Veröffentlichung bei Google und in anderen öffentlichen
          Suchmaschinen sowie auf Webseiten Dritter
        </SubSection>
        <p>
          Als “Nachhilfelehrer” auf Blagoom willigst Du ein, dass ausgewählte
          Informationen, die in Ihren Anzeigen für Nachhilfe enthalten sind
          (Foto, Vorname, Stadt, Bundesland, Beschreibung der Nachhilfe,
          Fach/Themengebiet, Stundenlohn) mit externen Suchmaschinen oder auch
          mit externen Webseiten geteilt werden. Um Ihre Möglichkeiten zum
          Finden von neuen Studenten noch weiter zu verbessern, können daher
          ausgewählte Informationen in den Suchergebnissen Dritter (z.B. Google)
          und auf den externen Webseiten Dritter angezeigt werden, die diese
          Informationen der Öffentlichkeit zugänglich machen und auf Deinen
          Eintrag bei Superprof verlinken dürfen. Aus diesem Grund ist es
          unerlässlich, dass Du unsere entsprechenden Hinweise befolgst und in
          Deinen Anzeigentexten keinerlei private Kontaktdaten wie
          Telefonnummer, Nachname, Adresse oder Deine E-Mail-Adresse angibst.
        </p>
        <SubSection>4. Einsatz von Cookies</SubSection>
        <p>
          Für einen Teil unserer Dienstleistungen ist es erforderlich, dass wir
          sogenannte Cookies einsetzen. Ein Cookie ist eine Textdatei, die
          entweder temporär ("Sitzungscookie") oder dauerhaft ("permanenter"
          Cookie) auf der Festplatte gespeichert wird. Cookies werden nicht dazu
          eingesetzt, um Programme auszuführen oder Viren auf Deinen Computer zu
          laden. Hauptzweck von Cookies ist vielmehr, ein speziell auf Dich
          zugeschnittenes Angebot bereitzustellen und die Nutzung unseres
          Service so zeitsparend wie möglich zu gestalten. Die meisten Browser
          sind standardmäßig so eingestellt, dass sie Cookies akzeptieren. Du
          kannst jedoch Deinen Browser so verändern, dass dieser Cookies ablehnt
          oder vorher eine Bestätigung von Dir verlangt. Wenn Du Cookies
          ablehnst, können nicht alle unsere Angebote für Dich störungsfrei
          funktionieren. Wir verwenden "Cookies", um Deine Online-Erfahrung und
          Online-Zeit individuell auszugestalten und zu optimieren. Wir
          verwenden überwiegend “Sitzungscookies", die mit Beenden des Browsers
          gelöscht werden. Sitzungscookies werden hierbei zur
          Login-Authentifizierung und zum Load-Balancing (Ausgleich der
          System-Belastung) verwendet. Wir setzen selbst Cookies, um
          Nutzungsdaten von Dir zu erheben, zu verarbeiten und nutzen zu können.
          Der Einsatz dieser Cookies dient dazu, Blagoom nutzerfreundlich und
          nutzerbezogen sowie effektiv und sicher zu gestalten. Blagoom
          verwendet unterschiedliche Marketingmaßnahmen, um Nutzer auf unsere
          Produkte aufmerksam zu machen. Hierbei kommen u.a. Maßnahmen wie
          Suchmaschinenmarketing (SEO), diverse Partnerprogramme
          (Affiliate-Marketing) und sog. Remarketing-Kampagnen zum Einsatz. Zur
          Steuerung und Optimierung dieser Kampagnen setzen wir Cookies und
          unterschiedliche Tracking-Technologien ein. Insbesondere Remarketing
          ermöglicht es uns, auf Webseiten in Partnernetzwerken gezielt jene
          Nutzer mit interessenbezogener Werbung anzusprechen, die sich bereits
          für unser Angebot interessiert haben. Die Einblendung der Werbemittel
          erfolgt beim Retargeting auf der Basis einer Cookie-basierten Analyse
          des vorherigen Nutzerverhaltens.
        </p>
        <SubSection>5. Google Analytics</SubSection>
        <p>
          Diese Webseite nutzt den Dienst "Google Analytics“, welcher von der
          Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA)
          angeboten wird, zur Analyse der Websitebenutzung durch Nutzer. Der
          Dienst verwendet "Cookies“ – Textdateien, welche auf Deinem Endgerät
          gespeichert werden. Die durch die Cookies gesammelten Informationen
          werden im Regelfall an einen Google-Server in den USA gesandt und dort
          gespeichert. Auf dieser Website greift die IP-Anonymisierung. Die
          IP-Adresse der Nutzer wird innerhalb der Mitgliedsstaaten der EU und
          des Europäischen Wirtschaftsraum gekürzt. Durch diese Kürzung entfällt
          der Personenbezug Deiner IP-Adresse. Im Auftrag des Betreibers dieser
          Webseite wird Google diese Informationen nutzen, um Deine Nutzung der
          Webseite auszuwerten, um Reports über die Webseitenaktivitäten
          zusammenzustellen und um weitere mit der Webseitennutzung und der
          Internetnutzung verbundene Dienstleistungen gegenüber dem
          Webseitenbetreiber zu erbringen. Du hast die Möglichkeit, die
          Speicherung des Cookies auf Deinem Gerät zu verhindern, indem Du in
          Deinem Browser entsprechende Einstellungen vornimmst. Es ist nicht
          gewährleistet, dass Du auf alle Funktionen dieser Webseite ohne
          Einschränkungen zugreifen kannst, wenn Dein Browser keine Cookies
          zulässt. Weiterhin kannst Du durch ein Browser-Plugin verhindern, dass
          die durch Cookies gesammelten Informationen (inklusive Deiner
          IP-Adresse) an die Google Inc. gesendet und von der Google Inc.
          genutzt werden. Folgender Link führt Dich zu dem entsprechenden
          Plugin: https://tools.google.com/dlpage/gaoptout?hl=de Hier findest Du
          weitere Informationen zur Datennutzung durch die Google
          https://support.google.com/analytics/answer/6004245?hl=de Alternativ
          verhinderst Du mit einem Klick auf diesen Link, dass Google Analytics
          innerhalb dieser Website Daten über Dich erfasst. Mit dem Klick auf
          obigen Link lädst Du ein „Opt-Out-Cookie“ herunter. Dein Browser muss
          die Speicherung von Cookies also hierzu grundsätzlich erlauben.
          Löschst Du Deine Cookies regelmäßig, ist ein erneuter Klick auf den
          Link bei jedem Besuch dieser Website vonnöten.
        </p>
        <SubSection>
          6. Recht auf Auskunft, Berichtigung, Löschung und Einschränkung der
          Verarbeitung von Daten
        </SubSection>
        <p>
          Du hast das Recht, die zu Deiner Person gespeicherten persönlichen
          Daten und Einstellungen Deines Nutzerkontos auf Blagoom jederzeit über
          Dein Profil einzusehen und diese zu modifizieren, zu ergänzen oder zu
          löschen. Du bist ebenfalls jederzeit berechtigt, Deine Zustimmung zur
          Erhebung und Speicherung von weiteren freiwilligen Daten durch den
          Betreiber zu widerrufen. Hierzu genügt eine E-Mail an uns (hallo (at)
          blagoom Punkt com), in der Du uns Deinen konkreten Wunsch (Auskunft,
          Berichtigung, Löschung oder Einschränkung der Verarbeitung Deiner
          Daten) mitteilst. Im Falle des Widerrufs der Speicherung und
          Verarbeitung von personenbezogenen Daten können wir für Dich keine
          Dienste mehr erbringen.
        </p>
        <SubSection>
          7. Keine Haftung für Webseiten von Kooperationspartnern
        </SubSection>
        <p>
          Auf unseren Webseiten verweisen wir auf verschiedene
          Kooperationspartner, die ihrerseits Websites und Dienste anbieten.
          Diese haben in der Regel eigene Datenschutzerklärungen. Diese
          Datenschutzerklärungen stehen nicht mit uns in Zusammenhang und wir
          übernehmen daher keine Haftung. Bitte informieren Dich selbstständig
          über die jeweiligen Datenschutzerklärungen. Der Datenschutz bei
          Datenübertragungen im Internet kann nach dem derzeitigen Stand der
          Technik noch nicht umfassend gewährleistet werden. Du trägst insofern
          für die Sicherheit der von Dir ins Internet übermittelten Daten selbst
          Sorge. Wir behalten uns vor, diese Datenschutzerklärung zu ändern. Die
          aktuelle Fassung der Datenschutzerklärung ist stets unter dieser URL
          abrufbar.
        </p>
      </InfoPagesLayout>
    </>
  );
};

const Section = styled.p`
  font-weight: 600;
  font-size: 26px;
`;

const SubSection = styled.p`
  font-weight: 500;
  font-size: 24px;
`;
