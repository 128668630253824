import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  height: 60px;
  //position: absolute;
  //bottom: -60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #cacaca;
`;

const FooterLeftWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const FooterText = styled.div`
  color: white;
  margin-left: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;

  &:hover {
    color: #f86b6b;
  }
`;

export const Footer = (props) => {
  const history = useHistory();

  return (
    <Container>
      <FooterLeftWrapper>
        <FooterText onClick={() => history.push(`/agb`)}>
          <FormattedMessage id={"footer.general-terms"} />
        </FooterText>
        <FooterText onClick={() => history.push(`/imprint`)}>
          <FormattedMessage id={"footer.data-protection"} />
        </FooterText>
        <FooterText onClick={() => history.push(`/imprint`)}>
          <FormattedMessage id={"footer.imprint"} />
        </FooterText>
      </FooterLeftWrapper>
    </Container>
  );
};
