import React from "react";
import PropTypes from "prop-types";

export const ExpandIcon = ({ width, height, color, className, onClick }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 27 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L13.5 14L26 1" stroke={color} strokeWidth="2" />
    </svg>
  );
};

ExpandIcon.defaultProps = {
  width: "27",
  height: "16",
  color: "#666666"
};

ExpandIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};
