import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { IoIosRedo, IoIosUndo } from "react-icons/io";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import getCroppedImg from "./crop-image";
import { useMutation } from "@apollo/client";
import { UPLOAD_FILE } from "../../apollo-client/mutations";
import { useIntl } from "react-intl";
import { showErrorToast } from "../../toast/toast-service";
import { Button } from "@mui/material";

export const EditProfilePhoto = (props) => {
  const intl = useIntl();
  const addNewPictureText = intl.formatMessage({
    id: "upload-image-component.add-new-picture",
  });
  const addPictureText = intl.formatMessage({
    id: "upload-image-component.add-picture",
  });
  const errorText = intl.formatMessage({
    id: "upload-image-component.error",
  });
  const previewText = intl.formatMessage({
    id: "upload-image-component.preview",
  });
  const backText = intl.formatMessage({
    id: "general.back",
  });
  const saveText = intl.formatMessage({
    id: "general.save",
  });

  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
    setShowPreviewButton(true);
    setShowUsername(false);
    props.setIsEditPhotoInProgress(true);

    const fileItemReader = new FileReader();
    fileItemReader.addEventListener(
      "load",
      () => {
        console.log("RESULT", fileItemReader.result);
        setImgSrc(fileItemReader.result);
      },
      false
    );
    fileItemReader.readAsDataURL(acceptedFiles[0]);
    console.log("ONDROP", acceptedFiles, imgSrc);
  };

  //TODO maxSize
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({ onDrop, accept: "image/jpeg, image/png", multiple: false });

  const [files, setFiles] = useState([]);
  const [imgSrc, setImgSrc] = useState(null);

  const [showPreviewButton, setShowPreviewButton] = useState(false);
  const [showUserName, setShowUsername] = useState(true);

  //crop states
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imgSrc,
        croppedAreaPixels,
        rotation
      );
      console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const resetSelectedImage = () => {
    setFiles([]);
    setRotation(0);
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    setCroppedAreaPixels(null);
    setCroppedImage(null);
    setShowUsername(true);
  };

  const [uploadProfilePicture, { loading: isUploading }] = useMutation(
    UPLOAD_FILE,
    {
      variables: { file: croppedImage },
      onError: (error) => {
        showErrorToast(error.message);
        cancelImageSelection();
      },
      onCompleted: () => {
        resetSelectedImage();
      },
    }
  );

  //TODO isDragReject erkennt nicht png
  //TODO ProfilePicture gegen allgemeine ProfilePicture Komponente austauschen
  //TODO isDragReject => auch bei zu großer Dateigrüße isDragRefect = true??? => wenn ja, Text ändern

  const saveImage = async () => {
    //hier uploaden
    props.setIsEditPhotoInProgress(false);
    await uploadProfilePicture();
  };

  const goBackToPreview = () => {
    onClose();
    setShowPreviewButton(true);
    props.setIsEditPhotoInProgress(false);
  };

  const cancelImageSelection = () => {
    setFiles([]);
    setRotation(0);
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    setCroppedAreaPixels(null);
    setCroppedImage(null);
    setShowPreviewButton(false);
    setShowUsername(true);
  };

  return (
    <Container>
      {files.length === 0 && croppedImage === null && (
        <ProfilePictureHoverTextWrapper
          {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
        >
          <ProfilePicture
            src={
              props.userPicture ||
              "https://storage.googleapis.com/blag-profile-pictures/default-profile-picture.png"
            }
            style={{ width: 300, height: 300 }}
          />
          <HoverText>
            <input {...getInputProps()} />
            {!isDragActive &&
              !isDragAccept &&
              !isDragReject &&
              addNewPictureText}
            {isDragAccept && addPictureText}
            {isDragReject && errorText}
          </HoverText>
        </ProfilePictureHoverTextWrapper>
      )}
      {files.length === 1 && croppedImage === null && (
        <ProfilePictureHoverTextWrapper>
          <Cropper
            image={imgSrc}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={1}
            restrictPosition={true}
            cropShape={"round"}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </ProfilePictureHoverTextWrapper>
      )}
      {
        //preview
        croppedImage && (
          <ProfilePictureHoverTextWrapper>
            <ProfilePicture
              src={croppedImage}
              style={{ width: 300, height: 300 }}
            />
          </ProfilePictureHoverTextWrapper>
        )
      }
      {files.length > 0 && (
        <ButtonContainer>
          {showPreviewButton && (
            <IconWrapper>
              <IoIosUndo
                size={32}
                onClick={() => {
                  if (rotation - 90 === -360) setRotation(0);
                  else setRotation(rotation - 90);
                  console.log(rotation);
                }}
              />
              <IoIosRedo
                size={32}
                style={{ marginLeft: 10 }}
                onClick={() => {
                  if (rotation + 90 === 360) setRotation(0);
                  else setRotation(rotation + 90);
                  console.log(rotation);
                }}
              />
            </IconWrapper>
          )}
          {showPreviewButton ? (
            <ButtonWrapper>
              <StyledButton onClick={cancelImageSelection}>
                {backText}
              </StyledButton>
              <StyledButton
                preview
                onClick={async () => {
                  await showCroppedImage();
                  setShowPreviewButton(false);
                }}
              >
                {previewText}
              </StyledButton>
            </ButtonWrapper>
          ) : (
            <ButtonWrapper>
              <StyledButton onClick={goBackToPreview}>{backText}</StyledButton>
              <StyledButton disabled={isUploading} preview onClick={saveImage}>
                {saveText}
              </StyledButton>
            </ButtonWrapper>
          )}
        </ButtonContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HoverText = styled.div`
  position: absolute;
  display: ${(props) =>
    props.isDragAccept || props.isDragReject || props.isDragActive
      ? "flex"
      : "none"};
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 100px;
  outline: none;
  cursor: pointer;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
`;

const ProfilePictureHoverTextWrapper = styled.div`
  outline: none;
  position: relative;
  height: 300px;
  width: 300px;
  

  &:hover ${HoverText} {
    display: flex;
  }
  
  &${(props) => !props.isDragActive} ${HoverText} {
    display: flex;
`;

const ProfilePicture = styled("img")`
  border-radius: 100px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;

const StyledButton = styled(Button)`
  && {
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
