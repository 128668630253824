import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { GET_TEACHING } from "../../apollo-client/queries";
import { PrimarySpinner } from "../../components/spinner/primary-spinner";
import { useRecoilState } from "recoil/dist";
import { TeacherDetailProfile } from "./teacher-detail-profile/teacher-detail-profile";
import { TeacherDetailComments } from "./teacher-detail-comments/teacher-detail-comments";
import { isTeachingRequestState } from "../../store/atoms";
import { useIsMobile } from "../../hooks/state-checkers/use-is-mobile";
import { Line } from "../../components/common/line";
import { ContentContainer } from "../../components/layout/content-container";
import { MainLayout } from "../../components/layout/main-layout";
import { usePleaseLoginInfo } from "../../hooks/use-please-login-info";
import { NotFoundPage } from "../not-found-page";
import { Redirect } from "react-router-dom";
import { showErrorToast } from "../../toast/toast-service";
import { FormattedMessage } from "react-intl";

export const TeacherDetailPage = (props) => {
  const [startTeacherChat, setStartTeacherChat] = useState({
    toggleChat: false,
    type: null,
    chatTitle: null,
    receiverId: null,
    chatId: null,
    profilePicture: null,
    unreadMessagesCount: null,
  });
  const [, setIsTeachingRequest] = useRecoilState(isTeachingRequestState);
  const { data, error, loading } = useQuery(GET_TEACHING, {
    variables: {
      _id: props.match.params.teachingId,
      course: props.match.params.id,
      isActive: true,
    },
  });

  const isMobile = useIsMobile();
  const { openPleaseLoginInfo, isLoggedIn } = usePleaseLoginInfo();

  let course = [];
  if (!loading && data) {
    course = data.courses.courses[0];
  }

  const handleStartTeacherChat = () => {
    if (!isLoggedIn) {
      openPleaseLoginInfo();
      return;
    }

    setIsTeachingRequest(true);
    setStartTeacherChat({
      toggleChat: true,
      type: "private",
      chatTitle: course.teachings.teachings[0].teacher.username,
      receiverId: course.teachings.teachings[0].teacher._id,
      chatId: null,
      profilePicture: course.teachings.teachings[0].teacher.profilePicture,
      unreadMessagesCount: 0,
    });
  };

  /*
  const containerRef = useRef(null);
  useOutsideMenuCloserExceptImportantAreas(containerRef, () => {
    props.history.push(`/courses/${props.match.params.id}/teachers`);
  });
  */

  const teaching = data && data.courses.courses[0].teachings.teachings[0];
  // TODO first name last name kann wahrscheinlich gelöscht werden (glaube es existiert nur noch user name)

  if (!loading && !teaching) {
    showErrorToast(
      <FormattedMessage id={"teacher.details.error-message.not-found"} />
    );
    return <Redirect to={`/courses/${course._id}/teachers`} />;
  }

  return (
    <MainLayout
      title={course.title}
      university={course.university}
      course={course}
      loading={loading}
      error={error}
      startChat={startTeacherChat}
      subscriberCount={course.teachings?.subscriberCount}
      props={props}
    >
      <ContentContainer>
        {isMobile && <Line fullWidth={true} />}
        <DetailsContainer>
          {loading && (
            // loading überarbeiten
            <PrimarySpinnerWrapper>
              <StyledPrimarySpinner />
            </PrimarySpinnerWrapper>
          )}
          {!loading && !error && (
            <Fragment>
              <TeacherDetailProfile
                teaching={teaching}
                courseId={data.courses.courses[0]._id}
                courseTitle={data.courses.courses[0].title}
                handleStartTeacherChat={handleStartTeacherChat}
                additionalTeacherTeachings={teaching?.teacher?.teachings?.teachings.filter(
                  (teaching) => teaching._id !== teaching._id
                )}
                props={props}
              />
              {isMobile && <Line />}
              <TeacherDetailComments teaching={teaching} />
              <Placeholder />
            </Fragment>
          )}
        </DetailsContainer>
      </ContentContainer>
    </MainLayout>
  );
};

const DetailsContainer = styled.div`
  height: 100%;
  width: ${(props) => props.theme.detailPageWidth};
  min-width: ${(props) => props.theme.detailPageMinWidth};
  min-height: ${(props) => props.theme.detailPageMinHeight};
  margin: 1px auto 0 auto;

  @media only screen and (max-width: ${(props) =>
      props.theme.xsDevicesBreakpoint}) {
    width: 100%;
    min-width: auto;
  }
`;

const PrimarySpinnerWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPrimarySpinner = styled(PrimarySpinner)`
  width: 100px;
  height: 100px;
  margin: auto;
`;

// chrome only uses margin-bottom in this case (margin-bottom teacher detail comments is 15px)
const Placeholder = styled.div`
  height: 1px;
`;
