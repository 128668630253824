import React from "react";
import PropTypes from "prop-types";

export const ArrowBackIcon = ({
  width,
  height,
  color,
  fill,
  className,
  onClick
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 22"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M1 21L14 11L1 1" stroke={color} strokeWidth="2" />
    </svg>
  );
};

ArrowBackIcon.defaultProps = {
  width: "22",
  height: "22",
  color: "black",
  fill: "none"
};

ArrowBackIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string
};
