import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import { BellIcon } from "../../../icons/bell-icon";

import { CLEAR_NOTIFICATIONS_COUNT } from "../../../../apollo-client/mutations";

import { NotificationsBox } from "./notification-box/notifications-box";
import { EmailSettingsBox } from "./email-box/email-settings-box";
import { useOutsideEvent } from "../../../../helpers/useOutsideEvent";

export const NotificationsComponent = ({ getUserData, notificationsCount }) => {
  const [isNotificationsMenuOpen, setIsNotificationsMenuOpen] = useState(false);
  const [isEmailMenuOpen, setIsEmailMenuOpen] = useState(false);

  const [clearNotificationsCount] = useMutation(CLEAR_NOTIFICATIONS_COUNT, {
    optimisticResponse: {
      __typename: "Mutation",
      clearNotificationsCount: {
        _id: getUserData.user._id,
        newNotificationsCount: 0,
        __typename: "User",
      },
    },
  });

  const handleBellClick = async () => {
    if (!isNotificationsMenuOpen) {
      await clearNotificationsCount();
    }
    setIsNotificationsMenuOpen(!isNotificationsMenuOpen);

    if (isEmailMenuOpen) {
      setIsEmailMenuOpen(false);
    }
  };

  const ref = useOutsideEvent(() => {
    setIsNotificationsMenuOpen(false);
    setIsEmailMenuOpen(false);
  });

  return (
    <Container ref={ref}>
      <StyledBellIcon
        notificationsCount={notificationsCount}
        onClick={handleBellClick}
        size={17}
      />
      {isNotificationsMenuOpen && (
        <NotificationsBox
          setIsNotificationsMenuOpen={setIsNotificationsMenuOpen}
          setIsEmailMenuOpen={setIsEmailMenuOpen}
        />
      )}
      {isEmailMenuOpen && (
        <EmailSettingsBox setIsEmailMenuOpen={setIsEmailMenuOpen} />
      )}
    </Container>
  );
};

const Container = styled.div`
  @media only screen and (max-width: ${(props) =>
    props.theme.firstBreakpoint}px) {
    margin-top: 17px;
  ;
`;

const StyledBellIcon = styled(BellIcon).attrs((props) => ({}))`
  margin-left: 15px;
`;
