import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { IoIosCheckmark, IoIosDoneAll, IoMdSnow } from "react-icons/io";
import { getTimeFromUnixTimeStamp } from "../../../../../helpers";

export const Message = ({
  id,

  authorColor,
  chatType,
  postingMessage,
  lastMessage,
  errorPostMessage,
  messageObject,
}) => {
  // console.log("message");
  // console.log(message);

  // console.log("messageObject2");
  // console.log(messageObject);

  const { author, isAuthor, message, createdAt, wasDelivered, wasRead } =
    messageObject;

  const authorName = !isAuthor ? author.username : "";

  return (
    <MessageContainer id={id} isMyMessage={isAuthor}>
      {!isAuthor &&
        chatType === "group" &&
        lastMessage &&
        lastMessage.author.username !== authorName && (
          <MessageUsername authorColor={authorColor}>
            {authorName}
          </MessageUsername>
        )}

      <MessageText>
        {message}
        {isAuthor && postingMessage && !wasDelivered && !wasRead && (
          <StyledIoMdSnow size={18} />
        )}
        {isAuthor && !postingMessage && !wasDelivered && !wasRead && (
          <StyledIoIosCheckmark size={18} />
        )}
        {isAuthor && !postingMessage && wasDelivered && !wasRead && (
          <StyledIoIosDoneAll size={18} />
        )}
        {isAuthor && !postingMessage && wasDelivered && wasRead && (
          <StyledIoIosDoneAll color={"green"} size={18} />
        )}
        <Time>{getTimeFromUnixTimeStamp(createdAt)}</Time>
      </MessageText>
    </MessageContainer>
  );
};

Message.propTypes = {
  // message: PropTypes.string.isRequired,
  // timestamp: PropTypes.string.isRequired,
  // isAuthor: PropTypes.bool.isRequired,
  // wasDelivered: PropTypes.bool.isRequired,
  // wasRead: PropTypes.bool.isRequired,
};

const MessageContainer = styled.div`
  min-width: 50px;
  max-width: 70%;
  align-self: ${(props) => (props.isMyMessage ? "flex-end" : "flex-start")};
  padding: 10px 15px;
  border: 1px solid transparent;
  border-radius: ${(props) =>
    props.isMyMessage ? "30px 0px 30px 30px" : "0px 30px 30px 30px"};
  margin: 10px 10px 0 10px;
  background: ${(props) => (props.isMyMessage ? "#FFBCBD" : "#F0F0F0")};
  word-break: break-word;
`;

const MessageUsername = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.authorColor};
`;

const MessageText = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #535353;
  white-space: pre-line;
`;

const Time = styled.span`
  font-weight: normal;
  font-size: 11px;
  color: #535353;
  margin-top: 3px;
  margin-left: 10px;
  float: right;
`;

const StyledIoMdSnow = styled(IoMdSnow)`
  margin-left: 5px;
  float: right;
`;

const StyledIoIosCheckmark = styled(IoIosCheckmark)`
  margin-left: 5px;
  float: right;
`;

const StyledIoIosDoneAll = styled(IoIosDoneAll)`
  margin-left: 5px;
  float: right;
`;
