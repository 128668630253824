import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PopUpDescription } from "./pop-up-description";
import { teachingValidators } from "../teaching-validators";
import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { EDIT_TEACHING } from "../../../apollo-client/mutations";
import Dialog from "@mui/material/Dialog";
import { FormattedMessage, useIntl } from "react-intl";
import { useIsMobile } from "../../../hooks/state-checkers/use-is-mobile";
import { ArrowBackIcon } from "../../../components/icons/icons-svg/arrow-back-icon";
import { theme } from "../../../theme";

export const EditTeachingDialog = ({ teaching, isDialogOpen, closeDialog }) => {
  const intl = useIntl();
  const [formValues, setFormValues] = useState({
    price: teaching.price,
    acceptsRemote: teaching.acceptsRemote,
    description: teaching.description,
  });

  const [formValueErrors, setFormValueErrors] = useState({
    price: false,
    acceptsRemote: false,
    description: false,
  });

  const isMobile = useIsMobile();

  const handleAcceptsRemoteSwitchChange = (event) => {
    setFormValues({ ...formValues, acceptsRemote: event.target.checked });
  };

  const [editTeaching] = useMutation(EDIT_TEACHING);

  const handleEditTeaching = async () => {
    await editTeaching({
      variables: {
        id: teaching._id,
        price: parseInt(formValues.price),
        description: formValues.description,
        acceptsRemote: formValues.acceptsRemote,
      },
    });

    closeDialog();
  };

  const handlePriceFieldChange = (event) => {
    const newPriceValue = event.target.value;
    setFormValues({ ...formValues, price: newPriceValue });

    try {
      teachingValidators.validatePrice(newPriceValue, intl);
      setFormValueErrors({ ...formValueErrors, price: false });
    } catch (err) {
      setFormValueErrors({ ...formValueErrors, price: err.message });
    }
  };

  const handleCloseDialog = () => {
    closeDialog();

    setFormValues({
      price: teaching.price,
      acceptsRemote: teaching.acceptsRemote,
      description: teaching.description,
    });
  };

  const acceptRemoteLabel = intl.formatMessage({
    id: "teacher.general.accept-remote-label",
  });

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => handleCloseDialog()}
      fullWidth={true}
      fullScreen={isMobile}
      maxWidth={"md"}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          background: "#FFFFFF",
          borderRadius: isMobile ? "0px" : "12px",
          width: isMobile ? null : "60%",
        },
      }}
    >
      <EditTeacherDialogBox>
        <LeftSideRightSideContainer>
          <LeftSideWrapper>
            <PriceInput>
              <FormControl fullWidth>
                <TextField
                  value={formValues.price}
                  error={formValueErrors.price}
                  label={<FormattedMessage id={"teacher.general.price"} />}
                  helperText={formValueErrors.price}
                  onChange={handlePriceFieldChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </PriceInput>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Switch
                    checked={formValues.acceptsRemote}
                    color={"primary"}
                    onChange={handleAcceptsRemoteSwitchChange}
                  />
                }
                label={acceptRemoteLabel}
              />
            </FormControl>
          </LeftSideWrapper>

          <RightSideWrapper>
            <UsernameIconWrapper>
              {isMobile && (
                <ArrowBackContainer onClick={closeDialog}>
                  <ArrowBackIcon color={"#383838"} width={20} height={20} />
                </ArrowBackContainer>
              )}
              <Username>{teaching.teacher.username}</Username>
            </UsernameIconWrapper>
            <StyledPopUpDescription
              globalUniqueId="editable_div_add_teacher_description"
              onKeyUp={() => {
                setFormValues({
                  ...formValues,
                  description: document.getElementById(
                    "editable_div_add_teacher_description"
                  ).innerText,
                });
              }}
              description={teaching.description}
            />
          </RightSideWrapper>
        </LeftSideRightSideContainer>
        <Footer>
          <EditTeachingButton onClick={async () => await handleEditTeaching()}>
            Bearbeiten abschließen
          </EditTeachingButton>
        </Footer>
      </EditTeacherDialogBox>
    </Dialog>
  );
};

const EditTeacherDialogBox = styled.div`
  z-index: 3;
  display: flex;
  flex-direction: column;
  opacity: 1;

  width: 100%;
  height: 258px;
  padding: 20px;
  box-sizing: border-box;

  background: #ffffff;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
  border-radius: 12px;

  overflow-y: scroll;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    justify-content: space-between;
    height: 100%;
    padding: 40px;
  }
`;

const LeftSideRightSideContainer = styled.div`
  display: flex;
  width: 100%;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    flex-direction: column-reverse;
  }
`;

const ArrowBackContainer = styled.div`
  transform: rotate(180deg);
  cursor: pointer;
`;

const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const PriceInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
`;

const RightSideWrapper = styled.div`
  width: 100%;
  padding-right: 20px;
`;

const UsernameIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Username = styled.div`
  margin-left: 10px;
  margin-bottom: 5px;
  font-weight: 600;
  color: #545454;
  font-size: 22px;
  line-height: 30px;
  margin-top: 10px;
`;

const StyledPopUpDescription = styled(PopUpDescription)`
  margin-top: 8px;
`;

const Footer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    justify-content: center;
  }
`;

const EditTeachingButton = styled.div`
  width: 240px;
  align-self: flex-end;
  background: ${theme.mainColor};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  padding: 5px;
  text-align: center;
  cursor: pointer;

  font-weight: 600;
  font-size: 17px;
  color: white;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    padding: 7px;
  }
`;

EditTeachingDialog.propTypes = {
  teaching: PropTypes.object.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};
