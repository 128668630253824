import React, { useRef, useState } from "react";
import styled from "styled-components";
import Uni_Icon from "../../img_new/Uni_Icon.svg";
import { IoMdArrowDropdown } from "react-icons/io";
import { useOutsideMenuCloserWithFunction } from "../../helpers";

const UniversityDropdownContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 400px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    width: 80%;
  }
`;

const ImageNameWrapper = styled.div`
  display: flex;
`;

const UniversityImage = styled("img")`
  height: 25px;
  width: 25px;
  margin-left: 10px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    height: 20px;
    width: 20px;
    margin-top: 3px;
  }
`;

const UniversityName = styled.div`
  font-size: 21px;
  margin-left: 7px;
  margin-right: 5px;
  color: #383838;
  font-weight: 500;
  line-height: 29px;
  display: flex;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    font-size: 15px;
  }
`;

const UniversityDropdownBox = styled.div`
  position: absolute;
  width: 100%;
  max-height: 120px;
  right: 5px;
  top: 35px;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgrey;
  border-radius: 7px;
  background-color: white;
  overflow-y: scroll;
  z-index: 8;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: transparent;
      border-radius: 0px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      -webkit-box-shadow: transparent;
      background-color: black;
    }
  }
`;

const UniversityDropdownItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 10px;
  }
`;

const UniversityDropdownItemImage = styled("img")`
  height: 16px;
  width: 16px;
  margin-left: 10px;
`;

const UniversityDropdownItemText = styled.div`
  font-weight: 500;
  color: #383838;
  font-size: 1em;
  margin-top: 5px;
  margin-left: 5px;
`;

export const UniversityDropdown = ({
  selectedUniversityState,
  universities,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { selectedUniversity, setSelectedUniversity } = selectedUniversityState;

  const containerRef = useRef(null);
  useOutsideMenuCloserWithFunction(containerRef, () =>
    setIsDropdownOpen(false)
  );

  return (
    <UniversityDropdownContainer
      ref={containerRef}
      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
    >
      <ImageNameWrapper>
        <UniversityImage src={Uni_Icon} />
        <UniversityName>{selectedUniversity}</UniversityName>
      </ImageNameWrapper>
      <IoMdArrowDropdown
        size={35}
        color={"#A5A5A5"}
        style={{ marginRight: 5 }}
      />
      {isDropdownOpen && (
        <UniversityDropdownBox>
          {universities.map((university, i) => (
            <UniversityDropdownItem
              key={university + "_item_" + i}
              onClick={() => setSelectedUniversity(university)}
            >
              <UniversityDropdownItemImage
                key={university + "_image_" + i}
                src={Uni_Icon}
              />
              <UniversityDropdownItemText key={university + "_text_" + i}>
                {university}
              </UniversityDropdownItemText>
            </UniversityDropdownItem>
          ))}
        </UniversityDropdownBox>
      )}
    </UniversityDropdownContainer>
  );
};
