import styled, { ThemeContext } from "styled-components";
import { Header } from "./header/header";
import React, { useContext } from "react";
import { Footer } from "../../pages/landing-page/footer";

export const InfoPagesLayout = ({ title, children }) => {
  const themeContext = useContext(ThemeContext);
  return (
    <>
      <Header
        title={title}
        hideNavigation
        hideBurgerMenu
        color={themeContext.brightStuffColor}
        backgroundColor={"white"}
      />

      <Container>
        <ContentContainer>{children}</ContentContainer>
        <Footer />
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media only screen and (max-width: 980px) {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  width: 75%;
  margin-top: 200px;

  @media only screen and (max-width: ${(props) =>
    props.theme.secondBreakpoint}px) {
    width: 100%;
    
`;
