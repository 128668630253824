import React, { useState, useRef, useEffect, Fragment } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import styled from "styled-components";
import magnifying_glass from "../../../../img/search.png";
import menuCloseIcon from "../../../icons/menu-close-icon.svg";
import {
  GET_COURSES_SEARCHING,
  GET_UNIVERSITIES,
} from "../../../../apollo-client/queries";
import SearchCourse from "./search-course";
import { FormattedMessage, useIntl } from "react-intl";
import { PrimarySpinner } from "../../../spinner/primary-spinner";
import MyCourseSearchCourseHeadline from "../my-course-nav/my-course-search-course-headline";
import search_courses_icon from "../../../../img_new/Search_Courses_Icon.svg";
import UniIcon from "../../../../img_new/Uni_Icon.svg";
import { useStore } from "../../../../store/store";
import { ArrowDown, ArrowLeft } from "../../../common/arrow";
import { useIsMobile } from "../../../../hooks/state-checkers/use-is-mobile";
import { useOutsideEvent } from "../../../../helpers/useOutsideEvent";
import { BlagButton } from "../../../common/BlagButton";
import { SuggestNewCourseBox } from "./SuggestNewCourseBox";

export default function SearchCoursesNav({
  escapeToggle,
  makeGroupVisibleInCourses,
  visibleCourses,
  setMakeGroupVisibleInCourses,
  setVisibleCourses,
}) {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const searchCourseHeadline = intl.formatMessage({
    id: "courses-nav.search-course",
  });
  const yourCoursePlaceholder = intl.formatMessage({
    id: "courses-nav.your-course-placeholder",
  });

  const selectedUniversity = useStore((state) => state.sCourseNavSelectedUni);
  const queryFilter = useStore((state) => state.sCourseNavSelectedUni);
  const searchText = useStore((state) => state.ScourseNavSearchText);

  const [isSuggestCourseModeOpen, setIsSuggestCourseModeOpen] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const {
    data: dataUniversities,
    error: errorUniversities,
    loading: loadingUniversities,
  } = useQuery(GET_UNIVERSITIES);

  const [getCoursesSearching, { data: courseData, error, loading, fetchMore }] =
    useLazyQuery(GET_COURSES_SEARCHING, {
      variables: { title: searchText, university: queryFilter.name },
      notifyOnNetworkStatusChange: true,
    });

  const ref = useOutsideEvent(escapeToggle);

  const handlePrintUniversityFilterOptions = (university, i) => {
    if (selectedUniversity.name === "") {
      return (
        <Fragment key={"image_wrapper_" + i}>
          <ImageUniversityCancelWrapper
            onClick={() => {
              setDisabled(false);
              useStore.setState({
                sCourseNavSelectedUni: university,
                ScourseNavQueryFilter: university,
              });
              if (
                courseData &&
                university.name !== courseData.courses.courses[0].university
              )
                setIsFirstRender(true);
            }}
            noTopShadow={selectedUniversity.name === ""}
          >
            <UniversityDividerWrapper>
              <IconTextWrapper>
                <UniversityImage src={UniIcon} />
                <UniversityText key={"university_" + i}>
                  {university.name}
                </UniversityText>
              </IconTextWrapper>
              <Divider />
            </UniversityDividerWrapper>
          </ImageUniversityCancelWrapper>
        </Fragment>
      );
    } else return null;
  };

  //remove university from filter
  const handleRemoveFilter = () => {
    // setSelectedUni({ name: "" });
    useStore.setState({ sCourseNavSelectedUni: { name: "" } });
    setIsSuggestCourseModeOpen(false);

    setDisabled(true);
  };

  const scrollCoursesRef = useRef(null);
  const handleFetchMoreCourses = () => {
    const { current } = scrollCoursesRef;
    if (
      !loading &&
      courseData.courses.courses.length < courseData.courses.coursesCount &&
      current.scrollTop + current.clientHeight >= current.scrollHeight
    ) {
      fetchMore({
        variables: {
          limit: 15,
          skip: courseData.courses.courses.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            fetchMoreResult.courses.courses.length === 0
          ) {
            return prev;
          } else {
            return {
              courses: {
                ...prev.courses,
                courses: [
                  ...prev.courses.courses,
                  ...fetchMoreResult.courses.courses,
                ],
              },
            };
          }
        },
      });
    }
  };

  //USEEFFECT
  useEffect(async () => {
    if (!courseData && !loading) {
      await getCoursesSearching();
    }

    if (courseData) setIsFirstRender(false);
  }, [courseData]);

  const closeCourseNav = () => {
    escapeToggle();
    if (setMakeGroupVisibleInCourses) setMakeGroupVisibleInCourses(false);
  };

  return (
    <Container id={"search_courses_nav_container"} ref={ref}>
      <Header>
        {isMobile && <ArrowLeft onClick={closeCourseNav} />}
        <MyCourseSearchCourseHeadline
          imgSrc={search_courses_icon}
          headlineText={searchCourseHeadline}
          backgroundColor={"#C23D3E"}
        />
        <RightHeaderWrapper>
          {!isMobile && <ArrowDown onClick={closeCourseNav} />}
        </RightHeaderWrapper>
      </Header>

      {selectedUniversity.name !== "" && (
        <UniversityContainer>
          <ImageUniversityCancelWrapper
            onClick={() => handleRemoveFilter()}
            noShadow={true}
          >
            <IconTextWrapper>
              <UniversityImage src={UniIcon} />
              <UniversityText>{selectedUniversity.name}</UniversityText>
            </IconTextWrapper>
            <Cancel>
              <img src={menuCloseIcon} alt={"menu close icon"} />
            </Cancel>
          </ImageUniversityCancelWrapper>
        </UniversityContainer>
      )}

      {isSuggestCourseModeOpen && (
        <SuggestNewCourseBox selectedUniversityName={selectedUniversity.name} />
      )}
      {!isSuggestCourseModeOpen && selectedUniversity.name !== "" && (
        <>
          <SearchBarWrapper>
            <MagnifyingGlass src={magnifying_glass} />
            <SearchInput
              placeholder={yourCoursePlaceholder}
              disabled={disabled}
              onChange={(event) => {
                useStore.setState({
                  ScourseNavSearchText: event.target.value,
                });
              }}
              // safari still suggest to autofill with password
              type={"text"}
              autoComplete={"off"}
              name="notASearchField"
            />
          </SearchBarWrapper>

          <CourseContainer
            ref={scrollCoursesRef}
            onScroll={handleFetchMoreCourses}
          >
            {isFirstRender && loading && (
              <FirstRenderSpinnerWrapper>
                <StyledFirstRenderPrimarySpinner />
              </FirstRenderSpinnerWrapper>
            )}
            {!error &&
              !isFirstRender &&
              courseData?.courses?.courses?.map((course) => (
                <SearchCourse
                  courseData={courseData}
                  course={course}
                  key={course._id}
                  makeGroupVisibleInCourses={makeGroupVisibleInCourses}
                  visibleCourses={visibleCourses}
                  setVisibleCourses={setVisibleCourses}
                  selectedUniversity={selectedUniversity}
                  closeCourseNav={closeCourseNav}
                />
              ))}
            {!isFirstRender && loading && !(selectedUniversity.name === "") && (
              <SpinnerWrapper>
                <StyledPrimarySpinner />
              </SpinnerWrapper>
            )}
          </CourseContainer>

          <SuggestNewCourseContainer>
            <BlagButton onClick={() => setIsSuggestCourseModeOpen(true)}>
              <FormattedMessage id="courses-nav.search-course.suggest-new-course" />{" "}
            </BlagButton>
          </SuggestNewCourseContainer>
        </>
      )}

      <UniversityContainer>
        {!loadingUniversities &&
          !errorUniversities &&
          dataUniversities.universities.map((university, i) => {
            return handlePrintUniversityFilterOptions(university, i);
          })}
      </UniversityContainer>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  width: 50%;
  min-width: 530px;
  max-width: 700px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: -1px 4px 12px rgba(0, 0, 0, 0.5);
  z-index: 13;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    min-width: 100%;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
`;

const RightHeaderWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: 15px;
`;

const SearchBarWrapper = styled.div`
  margin-left: 40px;
  margin-top: 12px;
  margin-bottom: 20px;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  padding-bottom: 5px;
`;

const MagnifyingGlass = styled("img")`
  height: 16px;
  width: 16px;
  opacity: 0.6;
`;

const SearchInput = styled.input`
  width: 100%;
  border: transparent;
  background-color: transparent;
  margin-left: 5px;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  box-shadow: 0 0.5px 0 0 ${(props) => props.theme.thirdColor};

  &::placeholder {
    color: ${(props) => props.theme.thirdColor};
  }
`;

const UniversityDividerWrapper = styled.div`
  width: 100%;
`;

const UniversityContainer = styled.div`
  margin-top: 10px;
  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    margin-top: 0;
  }
`;

const UniversityImage = styled("img")`
  height: 30px;
  width: 30px;
  //fill: none;
`;

// width: "30",
//   height: "30",
//   color: "#666666",
//   fill: "none",

const ImageUniversityCancelWrapper = styled.div`
  width: 70%;
  height: 60px;
  margin-left: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
        

  &:last-child {        
    box-shadow: none;
  }

  &:first-child {
    margin-top: 20px;
 
    }}
`;

const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UniversityText = styled.div`
  margin-left: 15px;
  //margin-top: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
`;

const Divider = styled.div`
  border: 0.5px solid #666666;
  opacity: 0.2;
`;

const Cancel = styled.div`
  margin-top: 10px;
  margin-right: 10px;
  font-size: 18px;
`;

const CourseContainer = styled.div`
  margin-bottom: 20px;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent;
  }
  &:hover {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: transparent;
      border-radius: 0px;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      -webkit-box-shadow: transparent;
      background-color: #7f7f7f;
    }
  }
`;

const SuggestNewCourseContainer = styled.div`
  position: fixed;
  bottom: 0;
  margin-left: 50px;
  margin-bottom: 30px;
`;

const FirstRenderSpinnerWrapper = styled.div`
  height: 60px;
`;

const StyledFirstRenderPrimarySpinner = styled(PrimarySpinner)`
  height: 50px;
  width: 50px;
  margin: 0 auto;
`;

const SpinnerWrapper = styled.div`
  margin-left: 50px;
  width: 80%;
`;

const StyledPrimarySpinner = styled(PrimarySpinner)`
  height: 20px;
  width: 20px;
  margin: 10px auto;
`;
