import React from "react";
import styled from "styled-components";
import { IoMdThumbsDown, IoMdThumbsUp } from "react-icons/io";
import { ProfilePicture } from "../../components/common/profile-picture";
import accepts_remote_icon from "../../img/remote.svg";
import { AvgResponseTimeComponent } from "./avg-response-time-component";
import { theme } from "../../theme";
import { Tooltip } from "../../components/common/tooltip/tooltip";

export const TeacherSmallComponent = ({
  showSmallTeacherComponent,
  teaching,
  hideLine,
}) => {
  return (
    <Container>
      <SmallTeacherContainer>
        <LeftSideWrapper>
          <ProfilePicture src={teaching.teacher.profilePicture || undefined} />
          <PriceText>{teaching.price} €/h</PriceText>
        </LeftSideWrapper>

        <MainWrapper>
          <NameTagsWrapper>
            <Name>{teaching.teacher.username}</Name>
            <TagsWrapper>
              {teaching.acceptsRemote && (
                <Tooltip tooltipId="common.tooltip.acccepts-remote.hint">
                  <AcceptsRemoteIcon src={accepts_remote_icon} />
                </Tooltip>
              )}
              <AvgResponseTimeComponent
                avgResponseTime={teaching.teacher.avgResponseTime}
              />
            </TagsWrapper>
          </NameTagsWrapper>

          <DescriptionWrapper
            smallComponent={showSmallTeacherComponent}
            borderVisibility={"transparent"}
          >
            <SmallTeacherDescription>
              {teaching.description}
            </SmallTeacherDescription>
          </DescriptionWrapper>

          <ToggleCommentsThumbsWrapper>
            <ThumbsWrapper smallComponent={showSmallTeacherComponent}>
              <StyledIoMdThumbsUp size={24} />
              <LikeCounterText>{teaching.likeCount}</LikeCounterText>
              <StyledIoMdThumbsDown size={24} style={{ marginTop: 12 }} />
              <LikeCounterText>{teaching.dislikeCount}</LikeCounterText>
            </ThumbsWrapper>
          </ToggleCommentsThumbsWrapper>
        </MainWrapper>
      </SmallTeacherContainer>
      <Line hideLine={hideLine} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  min-width: 500px;
  max-width: 1000px;
  box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.35);
  border-radius: 20px;
  background-color: white;
  margin: 0 auto 30px auto;

  @media only screen and (max-width: 980px) {
    max-width: 100%;
    min-width: 100%;

    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0;

    box-shadow: none;
  }
`;

const SmallTeacherContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  height: 155px;
  margin: 15px 0;
  position: relative;

  @media only screen and (max-width: 980px) {
    width: 85%;
    height: 120px;
  }
`;

const Line = styled.div`
  width: 90%;
  border-bottom: #acacac 1px solid;
  display: ${(props) => (props.hideLine ? "none" : "block")};

  @media only screen and (min-width: ${(props) =>
      props.theme.secondBreakpoint + 1}px) {
    display: none;
  }
`;

const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 980px) {
    width: 30%;
    justify-content: flex-start;
  }
`;

const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 20px;

  @media only screen and (max-width: 980px) {
    width: 70%;
    justify-content: flex-start;
  }
`;

const NameTagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -5px;
  width: 100%;
`;

const Name = styled.div`
  min-width: 60%;
  color: ${(props) => theme.mainTextColor};
  font-weight: 600;
  font-size: 19px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  margin-right: 100px;
`;

const AcceptsRemoteIcon = styled("img")`
  height: 22px;
  width: 22px;
  margin-left: 20px;
  margin-right: 10px;
`;

const DescriptionWrapper = styled.div`
  margin-top: 20px;
  border: ${(props) =>
    props.smallComponent ? "none" : "1px solid " + props.borderVisibility};
  min-height: ${(props) => (props.smallComponent ? "auto" : "200px")};

  @media only screen and (max-width: 980px) {
    margin-top: 0px;
  }
`;

const SmallTeacherDescription = styled.div`
  max-height: ${(props) => (props.smallComponent ? "56px" : "auto")};
  overflow: hidden;
  line-height: ${(props) => (props.smallComponent ? "normal" : "25px")};
  font-weight: 500;
  font-size: 14px;
  word-break: break-word;
  white-space: pre-line;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  @media only screen and (max-width: 980px) {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #666666;
  }
`;

const ToggleCommentsThumbsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => (props.smallComponent ? 0 : "20px")};

  @media only screen and (max-width: 980px) {
    margin-top: 0px;
    background-color: lightyellow;
  }
`;

const ThumbsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  position: absolute;
  bottom: 0px;
  right: 0;
`;

const StyledIoMdThumbsUp = styled(IoMdThumbsUp)`
  color: #4d4d4d;
  margin-right: 3px;
`;

const StyledIoMdThumbsDown = styled(IoMdThumbsDown)`
  color: #4d4d4d;
  margin-right: 3px;
`;

const LikeCounterText = styled.div`
  color: ${theme.mainTextColor};
  font-size: 12px;
  margin-right: 8px;
  font-weight: 600;
  margin-top: 3px;
`;

const PriceText = styled.div`
  font-weight: 500;
  font-size: 26px;
  line-height: 41px;
  color: ${theme.mainTextColor};

  @media only screen and (max-width: 980px) {
    font-weight: 600;
    font-size: 22px;
  }
`;
