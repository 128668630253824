import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getBasicPath } from "../../../../helpers";
import { useIsMobile } from "../../../../hooks/state-checkers/use-is-mobile";

export default function SearchCourse({
  courseData,
  course,
  makeGroupVisibleInCourses,
  visibleCourses,
  setVisibleCourses,
  selectedUniversity,
  closeCourseNav,
}) {
  const [selected, setSelected] = useState(false);
  const location = useLocation();

  const isMobile = useIsMobile();

  useEffect(() => {
    if (
      visibleCourses &&
      visibleCourses.length > 0 &&
      visibleCourses.find((c) => c._id === course._id)
    )
      setSelected(true);
    else setSelected(false);
  }, [visibleCourses, selectedUniversity, courseData]);

  const handleVisibleCourses = () =>
    setVisibleCourses((prev) => {
      let index = null;
      //remove
      if (
        prev.find((c, i) => {
          index = i;
          return c._id === course._id;
        })
      ) {
        if (index !== 0)
          return [...prev.slice(0, index), ...prev.slice(index + 1)];
        else return [...prev];
      }
      //add
      else return [...prev, { _id: course._id, title: course.title }];
    });

  if (!makeGroupVisibleInCourses)
    return (
      <CourseWrapper
        key={"course_wrapper" + course._id}
        selected={selected && makeGroupVisibleInCourses}
      >
        <StyledLink
          title={course.title}
          key={"link_" + course._id}
          to={`/courses/${course._id}/${getBasicPath(location.pathname)}${isMobile ? "" : "?coursesearchopen=1"}`}
          style={{ textDecoration: "none" }}
        >
          <CourseText key={"course_text_" + course._id}>
            {course.title}
          </CourseText>
        </StyledLink>
      </CourseWrapper>
    );
  else
    return (
      <CourseWrapper
          key={"course_wrapper" + course._id}
        selected={selected && makeGroupVisibleInCourses}
        onClick={handleVisibleCourses}
        staticVisible={course._id === visibleCourses[0]._id}
      >
        <CourseTextCancelWrapper>
          <CourseText key={"course_text_" + course._id}>
            {course.title}
          </CourseText>
          <Cancel>x</Cancel>
        </CourseTextCancelWrapper>
      </CourseWrapper>
    );
}

const Cancel = styled.div`
  visibility: hidden;
  margin-right: 15px;
  cursor: pointer;
`;

const CourseWrapper = styled.div`
  position: relative;
  margin-bottom: 10px;
  margin-left: 50px;
  background: ${(props) => (props.selected ? "#DECFA2" : "white")};
  cursor: pointer;
  width: 75%;
  border-radius: 10px;

  &:hover {
    background: ${(props) => (props.selected ? "#DECFA2" : "#E4E6E9")};
  }

  &:hover ${Cancel} {
    visibility: ${(props) =>
      props.selected && !props.staticVisible ? "visible" : "hidden"};
  }
`;

const StyledLink = styled(Link)`
  overflow: hidden;
  color: ${(props) => props.theme.mainTextColor};
`;

const CourseTextCancelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CourseText = styled.div`
  width: 100%;
  padding: 10px;

  margin-top: 5px;
  margin-bottom: 5px;

  font-weight: 500;
  font-size: 18px;
  line-height: 25px;

  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
