import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PopUpDescription } from "./pop-up-description";
import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { BECOME_TEACHER } from "../../../apollo-client/mutations";
import Dialog from "@mui/material/Dialog";
import { FormattedMessage, useIntl } from "react-intl";
import { GET_TEACHINGS } from "../../../apollo-client/queries";
import { teachingValidators } from "../teaching-validators";
import { showErrorToast } from "../../../toast/toast-service";
import { useIsMobile } from "../../../hooks/state-checkers/use-is-mobile";
import { ArrowBackIcon } from "../../../components/icons/icons-svg/arrow-back-icon";
import { theme } from "../../../theme";
import { QuestionMarkTooltip } from "../../../components/common/tooltip/question-mark-tooltip";
import { GET_USER_TEACHINGS } from "../../../apollo-client/queries/teaching-queries";

export const BecomeTeacherDialog = ({
  isDialogOpen,
  closeDialog,
  user,
  courseId,
  inactiveTeaching,
}) => {
  const intl = useIntl();

  const defaultTeachingConfig = {
    price: 0,
    acceptsRemote: true,
    description: "",
    teacher: {
      username: user && user.username,
    },
    ...inactiveTeaching,
  };

  const [formValues, setFormValues] = useState({
    price: defaultTeachingConfig.price,
    acceptsRemote: defaultTeachingConfig.acceptsRemote,
    description: defaultTeachingConfig.description,
  });

  const [formValueErrors, setFormValueErrors] = useState({
    price: false,
    acceptsRemote: false,
    description: false,
  });

  const handleAcceptsRemoteSwitchChange = (event) => {
    setFormValues({ ...formValues, acceptsRemote: event.target.checked });
  };

  const [becomeTeacherMutation] = useMutation(BECOME_TEACHER, {
    variables: {
      course: courseId,
      price: parseInt(formValues.price),
      description: formValues.description,
    },
    refetchQueries: [GET_TEACHINGS, "teachings", GET_USER_TEACHINGS],
  });

  const handleBecomeTeacher = async () => {
    try {
      await becomeTeacherMutation();
      closeDialog();
    } catch (err) {
      showErrorToast(err.message);
    }
  };

  const handleCloseDialog = () => {
    closeDialog();
  };

  const acceptRemoteLabel = intl.formatMessage({
    id: "teacher.general.accept-remote-label",
  });

  const handlePriceFieldChange = (event) => {
    const newPriceValue = event.target.value;
    setFormValues({ ...formValues, price: newPriceValue });

    try {
      teachingValidators.validatePrice(newPriceValue, intl);
      setFormValueErrors({ ...formValueErrors, price: false });
    } catch (err) {
      setFormValueErrors({ ...formValueErrors, price: err.message });
    }
  };

  const isMobile = useIsMobile();

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => handleCloseDialog()}
      fullWidth={true}
      fullScreen={isMobile}
      maxWidth={"md"}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          background: "#FFFFFF",
          borderRadius: isMobile ? "0px" : "12px",
          width: isMobile ? null : "60%",
        },
      }}
    >
      <EditTeacherDialogBox>
        <LeftSideRightSideContainer>
          <LeftSideWrapper>
            <PriceInput>
              <FormControl fullWidth>
                <TextField
                  value={formValues.price}
                  error={formValueErrors.price}
                  label={<FormattedMessage id={"teacher.general.price"} />}
                  helperText={formValueErrors.price}
                  onChange={handlePriceFieldChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </PriceInput>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Switch
                    checked={formValues.acceptsRemote}
                    color={"primary"}
                    onChange={handleAcceptsRemoteSwitchChange}
                  />
                }
                label={acceptRemoteLabel}
              />
            </FormControl>
          </LeftSideWrapper>

          <RightSideWrapper>
            <NameHeader>
              <UsernameIconWrapper>
                {isMobile && (
                  <ArrowBackContainer onClick={closeDialog}>
                    <ArrowBackIcon color={"#383838"} width={20} height={20} />
                  </ArrowBackContainer>
                )}
                <Username>{defaultTeachingConfig.teacher.username}</Username>
              </UsernameIconWrapper>
              <QuestionMarkTooltip tooltipId="teacher.add-new-teacher-hint" />
            </NameHeader>
            <StyledPopUpDescription
              globalUniqueId="editable_div_add_teacher_description"
              onKeyUp={() => {
                setFormValues({
                  ...formValues,
                  description: document.getElementById(
                    "editable_div_add_teacher_description"
                  ).innerText,
                });
              }}
              description={defaultTeachingConfig.description}
            />
          </RightSideWrapper>
        </LeftSideRightSideContainer>
        <Footer>
          <BecomeTeachingButton
            onClick={async () => await handleBecomeTeacher()}
          >
            <FormattedMessage id={"teacher.add-new-teacher"} />
          </BecomeTeachingButton>
        </Footer>
      </EditTeacherDialogBox>
    </Dialog>
  );
};

const NameHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ArrowBackContainer = styled.div`
  transform: rotate(180deg);
  cursor: pointer;
`;

const UsernameIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EditTeacherDialogBox = styled.div`
  z-index: 3;
  display: flex;
  flex-direction: column;
  opacity: 1;

  width: 100%;
  height: 258px;
  padding: 20px;
  box-sizing: border-box;

  border-radius: 12px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    justify-content: space-between;
    height: 100%;
    padding: 40px;
  }
`;

const LeftSideRightSideContainer = styled.div`
  display: flex;
  width: 100%;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    flex-direction: column-reverse;
  }
`;

const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const PriceInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
`;

const RightSideWrapper = styled.div`
  width: 100%;
  padding-right: 20px;
`;

const Username = styled.div`
  margin-left: 10px;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  margin-top: 10px;
`;

const StyledPopUpDescription = styled(PopUpDescription)`
  margin-top: 8px;
`;

const Footer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    justify-content: center;
  }
`;

const BecomeTeachingButton = styled.div`
  width: 240px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  align-self: flex-end;
  background: ${theme.mainColor};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  padding: 5px;
  text-align: center;
  cursor: pointer;

  font-weight: 600;
  font-size: 17px;
  color: white;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    padding: 7px;
  }
`;

BecomeTeacherDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  courseId: PropTypes.string.isRequired,
};
