import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import styled from "styled-components";
import { Header } from "../../components/layout/header/header";
import { GET_USER_EDIT } from "../../apollo-client/queries";
import avatar1 from "../../img/user-avatars/avatar1.svg";
import avatar2 from "../../img/user-avatars/Avatar2.svg";
import avatar3 from "../../img/user-avatars/Avatar3.svg";
import avatar4 from "../../img/user-avatars/Avatar4.svg";
import avatar5 from "../../img/user-avatars/Avatar5.svg";
import avatar6 from "../../img/user-avatars/Avatar6.svg";
import avatar7 from "../../img/user-avatars/Avatar7.svg";
import avatar8 from "../../img/user-avatars/Avatar8.svg";
import avatar9 from "../../img/user-avatars/Avatar9.svg";

import { EDIT_USER_NAME, EDIT_USER_PHOTO } from "../../apollo-client/mutations";
import { ProfilePhotoWithName } from "./profile-photo-with-name";
import { showErrorToast, showSuccessToast } from "../../toast/toast-service";
import { FormattedMessage } from "react-intl";
import { MobileNavHeader } from "../profile-page/edit-profile-page/mobile-nav-header";

export const AvatarsPage = (props) => {
  //QUERIES
  const { data, error, loading } = useQuery(GET_USER_EDIT);

  const [newUsername, setNewUsername] = useState(data && data.user.username);

  //MUTATIONS
  const [changeName] = useMutation(EDIT_USER_NAME, {
    update(cache, addContentLinkResponse) {
      setNewUsername("");
      document.getElementById("editable_div_new_username").innerText = "";
    },
  });

  const [setUserAvatarMutation] = useMutation(EDIT_USER_PHOTO, {});

  const setUserAvatar = async (avatarId) => {
    try {
      await setUserAvatarMutation({
        variables: {
          avatarId,
        },
      });
      showSuccessToast(
        <FormattedMessage id={"edit-user-data-profile-updated-successfully"} />
      );
    } catch (err) {
      showErrorToast(
        <FormattedMessage id={"edit-user-data-profile-updated-failed"} />
      );
    }
  };

  return (
    <div>
      {!loading && data && (
        <Container>
          <EditProfilePhotoWrapper style={{ marginLeft: 20 }}>
            <ProfilePhotoWithName hideName={true} hidePhoto={true} />
          </EditProfilePhotoWrapper>
          <MobileNavHeader text="Avatare" />
          <ContentWrapper>
            <AvatarsWrapper>
              <Avatar src={avatar1} onClick={() => setUserAvatar(1)} />
              <Avatar src={avatar2} onClick={() => setUserAvatar(2)} />
              <Avatar src={avatar3} onClick={() => setUserAvatar(3)} />
              <Avatar src={avatar4} onClick={() => setUserAvatar(4)} />
              <Avatar src={avatar5} onClick={() => setUserAvatar(5)} />
              <Avatar src={avatar6} onClick={() => setUserAvatar(6)} />
              <Avatar src={avatar7} onClick={() => setUserAvatar(7)} />
              <Avatar src={avatar8} onClick={() => setUserAvatar(8)} />
              <Avatar src={avatar9} onClick={() => setUserAvatar(9)} />
            </AvatarsWrapper>
          </ContentWrapper>
        </Container>
      )}
      <Header error={false} loading={false} props={props} />
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 15%;
  margin-top: 120px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    flex-direction: column;
    margin-top: 100px;
    margin-right: 0%;
  }
`;

const EditProfilePhotoWrapper = styled.div`
  margin-left: 20px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 70px;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    margin-top: 0px;
  }
`;

const AvatarsWrapper = styled.div`
  width: 700px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 50px;
  grid-row-gap: 20px;
  justify-items: center;
  align-items: center;

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    width: 100%;
    margin-top: 30px;
    grid-template-columns: auto auto auto;
  }
`;

const Avatar = styled.img`
  width: 90px;
  height: 90px;
`;
