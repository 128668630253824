import create from 'zustand'

export const useStore = create(() => ({
    myCourseNavSelection: {
        university: "",
        courses: []
    },
    sCourseNavSelectedUni: { name: "" },
    ScourseNavQueryFilter: {name: ""},
    ScourseNavSearchText: ""


    // increasePopulation: () => set(state => ({ bears: state.bears + 1 })),
    // removeAllBears: () => set({ bears: 0 })
}))
