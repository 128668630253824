import arrow_back from "../../img/Arrow_Vector.svg";
import React from "react";
import styled from "styled-components";

export const ArrowDown = ({ onClick }) => {
  return <ArrowDownImage src={arrow_back} onClick={onClick} />;
};

export const ArrowLeft = ({ onClick }) => {
  return <ArrowLeftImage src={arrow_back} onClick={onClick} />;
};

const ArrowDownImage = styled("img")`
  transform: rotate(-90deg);
  transition: transform 0.3s;
  cursor: pointer;
`;

const ArrowLeftImage = styled("img")`
  transform: rotate(0deg);
  transition: transform 0.3s;
  cursor: pointer;
`;
