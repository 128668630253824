import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Redirect } from "react-router-dom";
import { Header } from "../../../components/layout/header/header";
import { gql, useMutation } from "@apollo/client";
import { RESET_USER_PASSWORD } from "../../../apollo-client/mutations";
import { PrimarySpinner } from "../../../components/spinner/primary-spinner";
import { Footer } from "../../landing-page/footer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(190.74deg, #c23d3e -8%, #ffffff 53.5%);
  height: 100vh;
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  width: 50%;
  text-align: center;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #656565;
`;

const Subtitle = styled.div`
  width: 32%;
  margin-top: 20px;
  text-align: center;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #656565;
`;

const Form = styled.form`
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MailInput = styled.input`
  box-sizing: border-box;
  width: 30%;
  height: 40px;
  padding: 0 30px;
  border: 0.5px solid lightgrey;
  border-radius: 6px;
  font-family: Work Sans;
  font-size: 14px;
`;

const Button = styled.button`
  height: 40px;
  width: 30%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0.5px solid #656565;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  background-color: ${(props) =>
    props.backgroundColor ? "mediumspringgreen" : "#c23d3e"};
  font-size: 14px;
  font-family: Work Sans;
  color: white;
`;

export const ForgotPasswordPage = (props) => {
  let input;

  const [resetUserPassword, { data, loading, error }] =
    useMutation(RESET_USER_PASSWORD);
  const resetPassword = () => {
    resetUserPassword({ variables: { email: input.value } });
  };

  if (!localStorage.getItem("token"))
    return (
      <Container>
        <MainContentWrapper>
          <Title>Hast du dein Passwort vergessen?</Title>
          {!data && !error && (
            <Subtitle>
              Gib deine E-Mail-Adresse ein und wir senden dir einen Link zum
              zurücksetzen deines Passworts.
            </Subtitle>
          )}
          {data && (
            <Subtitle>
              Dir wurde eine E-Mail gesendet. Bitte schaue in deinem Postfach
              oder gegebenfalls in deinem Spam-Ordner nach.
            </Subtitle>
          )}
          {error && <Subtitle>Ein Fehler is aufgetreten.</Subtitle>}
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              resetPassword();
            }}
          >
            <MailInput
              type={"email"}
              name={"email"}
              placeholder={"E-Mail-Adresse"}
              ref={(node) => {
                input = node;
              }}
            />
            {!error && !data && (
              <Button type={"submit"}>
                {loading ? <PrimarySpinner /> : "Link zum zurücksetzen"}
              </Button>
            )}
            {data && (
              <Button type={"submit"} backgroundColor={data} disabled>
                Link wurde gesendet
              </Button>
            )}
            {error && (
              <Button type={"submit"} backgroundColor={data}>
                Erneut versuchen
              </Button>
            )}
          </Form>
        </MainContentWrapper>
        <Header
          hideNavigation
          backgroundColor={"none"}
          hideBurgerMenu
          props={props}
        />
        <Footer />
      </Container>
    );
  else return <Redirect to={`/courses/5c1eb802c5faf5136fec6d9d/teachers`} />;
};
