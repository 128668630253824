import React from "react";
import styled from "styled-components";

const Headline = styled.div`
  height: 59px;
  display: flex;
  align-items: center;
  margin-top: 7px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    margin-left: 15px;
    margin-top: 0;
  }
`;

const MyCoursesIcon = styled.img`
  width: 39px;
  height: 39px;
`;

const MyCoursesText = styled.div`
  color: white;
  font-weight: 600;
  font-size: 34px;
  line-height: 46px;
  display: flex;
  align-items: flex-end;
  margin-top: 3px;
  margin-left: 15px;
  color: #383838;
`;

export default function MyCourseSearchCourseHeadline({
  backgroundColor,
  imgSrc,
  headlineText,
}) {
  return (
    <Headline backgroundColor={backgroundColor}>
      <MyCoursesIcon src={imgSrc} />
      <MyCoursesText>{headlineText}</MyCoursesText>
    </Headline>
  );
}
