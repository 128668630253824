import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { IoMdMore } from "react-icons/io";
import TimeAgo from "react-timeago";
import {
  formatter,
  useOutsideMenuCloserWithFunction,
} from "../../../../../helpers";
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_NOTIFICATIONS,
  MARK_NOTIFICATIONS_AS_SEEN,
} from "../../../../../apollo-client/mutations";
import { Link } from "react-router-dom";
import { GET_NOTIFICATIONS } from "../../../../../apollo-client/queries";
import { GET_TEACHING } from "./queries";
import {
  getNotificationTypeHeadline,
  wasPrevNotifSameType,
} from "./notification-helpers";

export const NotificationComponent = ({
  notification,
  setIsNotificationsMenuOpen,
  skip,
  prevNotification,
}) => {
  const {
    _id,
    contentId,
    notificationType,
    wasSeen,
    teachingRequester,
    createdAt,
  } = notification;

  const { _id: courseId, courseTitle, courseUniversity } = notification.course;

  const [isDeleteMenuOpen, setIsDeleteMenuOpen] = useState(false);
  const [link, setLink] = useState("");
  const menuRef = useRef(null);
  useOutsideMenuCloserWithFunction(menuRef, () => setIsDeleteMenuOpen(false));

  const [markNotificationsAsSeen] = useMutation(MARK_NOTIFICATIONS_AS_SEEN);

  const handleMarkNotification = () => {
    markNotificationsAsSeen({ variables: { ids: [_id] } });
    setIsNotificationsMenuOpen(false);
  };

  const intl = useIntl();
  //alternative wäre: limit auf auf array länge setzen (wie skip) und skip löschen (Code wäre schmaler, aber mehr Daten vom Backend)
  const [deleteNotification] = useMutation(DELETE_NOTIFICATIONS, {
    variables: { ids: [_id], limit: 1, skip: skip },
    update: (proxy, { data: { deleteNotifications } }) => {
      const dataGetNotifications = proxy.readQuery({
        query: GET_NOTIFICATIONS,
        variables: { limit: 15 },
      });
      proxy.writeQuery({
        query: GET_NOTIFICATIONS,
        variables: { limit: 15 },
        data: {
          user: {
            ...deleteNotifications,
            notifications: [
              ...dataGetNotifications.user.notifications.filter(
                (notification) => notification._id !== _id
              ),
              ...deleteNotifications.notifications,
            ],
          },
        },
      });
    },
  });

  const deleteNotificationMutation = () => {
    setIsDeleteMenuOpen(false);
    deleteNotification();
  };

  useEffect(() => {
    setNotificationIconAndLink();
  }, [_id]);

  const { data: teachingsData } = useQuery(GET_TEACHING, {
    variables: {
      _id: contentId,
    },
  });

  const teaching = teachingsData?.teachings?.teachings?.[0]?.teacher;

  //TODO check if event.stopPropagation is nonsense
  const handleMenuIconClick = (event) => {
    event.stopPropagation();
    setIsDeleteMenuOpen(!isDeleteMenuOpen);
  };

  const handleDeleteWrapperClick = (event) => {
    event.stopPropagation();
  };

  // Das Link resolven anpassen, sobald Chat neu eingebaut und verstanden wurde
  const setNotificationIconAndLink = () => {
    switch (notificationType) {
      case "newTeaching":
        setLink(`/courses/${courseId}/teachers/${contentId}`);
        break;
      case "teachingRequest":
        break;
      case "teachingRequestAnswer":
        break;
    }
  };

  return (
    <Container>
      <StyledLink to={link} onClick={handleMarkNotification}>
        <IconContainer>
          <WasNotSeenContainer>
            {!wasSeen && <WasNotSeenMarker />}
          </WasNotSeenContainer>
          <StyledProfilePicture
            src={teaching?.profilePicture || teachingRequester?.profilePicture}
          />
        </IconContainer>
        <TextWrapper>
          {!wasPrevNotifSameType(
            notificationType,
            prevNotification?.notificationType
          ) && (
            <Headline>{getNotificationTypeHeadline(notificationType)}</Headline>
          )}
          <NotificationText>
            <FormattedMessage
              id={"notification.notificationText"}
              values={{
                headline: notificationType,
                username: teachingRequester?.username || teaching?.username,
              }}
            />
            <NotificationText>{notification.course.title} </NotificationText>
            <NotificationText>
              {notification.course.university}{" "}
            </NotificationText>
          </NotificationText>
          <Course>{courseTitle}</Course>
          <University>{courseUniversity}</University>
          <Time>
            <TimeAgo date={Number(createdAt)} formatter={formatter} />
          </Time>
          <Line />
        </TextWrapper>
      </StyledLink>
      <MenuWrapper ref={menuRef}>
        <StyledMenuIcon
          size={24}
          visibility={isDeleteMenuOpen ? "visible" : "hidden"}
          onClick={handleMenuIconClick}
        />
        {isDeleteMenuOpen && (
          <DeleteTextWrapper onClick={handleDeleteWrapperClick}>
            <DeleteText onClick={deleteNotificationMutation}>
              {intl.formatMessage({ id: "general.delete" })}
            </DeleteText>
          </DeleteTextWrapper>
        )}
      </MenuWrapper>
    </Container>
  );
};

const StyledMenuIcon = styled(IoMdMore)`
  margin-right: 10px;
  visibility: ${(props) => props.visibility};
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  // border-bottom: 1px solid black;
  margin-top: 15px;
  margin-left: 10px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover ${StyledMenuIcon} {
    visibility: visible;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  width: 100%;
  text-decoration: none;
  color: black;
`;

const StyledProfilePicture = styled.img`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 100%;
`;

const IconContainer = styled.div`
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 100%;
`;

const WasNotSeenContainer = styled.div`
  width: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 4px;
`;
const WasNotSeenMarker = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #f86b6b;
`;

const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: 10px;
`;

const Headline = styled.div`
  color: #e87975;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
`;

const NotificationText = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin-top: 3px;
  margin-bottom: 0;
`;

const Course = styled.div``;

const University = styled.div`
  font-size: 14px;
`;

const Time = styled.div`
  margin: 3px 0 5px 0;
  color: ${(props) => props.theme.zweitFarbe};
  font-weight: 400;
  font-size: 11px;
`;

const MenuWrapper = styled.div`
  position: relative;
  height: fit-content;
`;

const DeleteTextWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100px;
  margin-left: -90px;
  box-sizing: border-box;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.4);
  border-radius: 7px;
  cursor: pointer;
  background-color: white;
`;

const DeleteText = styled.div``;

const Line = styled.div`
  width: 60%;
  border-bottom: 0.5px solid #a7a6a6;
`;
