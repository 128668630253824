import { createTheme, adaptV4Theme } from "@mui/material/styles";

export const theme = {
  mainColor: "#E87975",
  zweitFarbe: "#666666",
  mainTextColor: "#383838",
  hoverColor: "#f86b6b",

  // old defined ones

  buttonDisabledColor: "#cccccc",

  primary: "#EFD2D2",
  primaryColor: "#c23d3e",
  primaryAccentColor: "#F86B6B",

  fourthColor: "#545454",

  secondaryColor: "#001B3C",
  headerTitleColor: "#383838",

  thirdColor: "#383838",

  secondaryHeadlineColor: "#443e3e",

  chatFontColor: "#383838",
  scrollBarColor: "lightgrey",

  greyTextColor: "#787878",
  abrilFatfaceLetterSpacing: "0.5px",
  questionColor: "#7899BF",
  placeholderColor: "lightgrey",
  detailPageContainerBoxShadow: "0px 2px 6px rgba(0, 0, 0, 0.3)",
  detailPageContainerBorderRadius: "8px 8px 0 0",

  listHeaderFontSize: "22px",
  detailPageHeaderFontSize: "26px",
  smallHeaderFontSize: "20px",
  mediumFontSize: "14px",
  smallFontSize: "12px",

  headerFontWeight: "500",
  searchBarContainerHeight: "50px",
  detailPageWidth: "85%",
  detailPageMinWidth: "550px",
  detailPageMinHeight: "100%",
  inputBorderColor: "#9c9c9c",
  inputErrorBorderColor: "red",

  brightStuffColor: "#e0e0e0",

  hoverBrightStuffColor: "#fff",
  beforeHoverTextColor: "#808080",
  hoverTextColor: "#262626",

  firstBreakpoint: 1040,
  secondBreakpoint: 980,
  xsDevicesBreakpoint: "575px",
};

export const muiTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: "#E87975",
      },
    },
    overrides: {
      MuiDialogActions: {
        root: {
          display: "flex",
          justifyContent: "center",
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: "black",
        },
        arrow: {
          "&::before": {
            backgroundColor: "black",
          },
        },
      },
    },
  })
);
