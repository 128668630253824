import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { IoMdThumbsDown, IoMdThumbsUp } from "react-icons/io";
import { cutString } from "../../../helpers";
const Container = styled.div`
  width: 100%;
`;

const AdditionalTeachingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledLink = styled(Link)`
  margin-top: 20px;
  color: black;
  text-decoration: none;
  border-radius: 2px;

  &:last-child {
    margin-bottom: 30px;
  }
`;

const AdditionalCourseWrapper = styled.div`
  width: 100%;
  min-height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AdditionalCourseTitle = styled.div`
  margin-left: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #555555;
`;

const AdditionalRightSideWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AdditionalThumbsUpWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
`;

const AdditionalThumbsUpIcon = styled(IoMdThumbsUp)`
  height: 22px;
  width: 22px;
  margin-top: -5px;
  margin-right: 3px;
  color: #666;
`;

const AdditionalThumbsUpText = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 15px;
  color: #e0e0e0;
  font-size: 7px;
  line-height: 9px;
  right: 4px;
`;

const AdditionalThumbsDownWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AdditionalThumbsDownIcon = styled(IoMdThumbsDown)`
  height: 22px;
  width: 22px;
  margin-bottom: -4px;
  color: #666666;
`;

const AdditionalThumbsDownText = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 15px;
  color: #e0e0e0;
  font-size: 7px;
  line-height: 9px;
  right: 5px;
`;

const AdditionalPrice = styled.div`
  width: 55px;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;

  margin-left: 20px;
  color: #383838;
`;

export const AdditionalTeacherTeachings = ({
  courseTitle,
  additionalTeacherTeachings,
}) => {
  //TODO eventuell fetchMore einbauen

  return (
    <Container>
      <AdditionalTeachingWrapper>
        {additionalTeacherTeachings
          .filter((teaching) => teaching.course.title !== courseTitle)
          .map((teaching) => {
            console.log("teaching", teaching);

            return (
              <StyledLink
                key={"AdditionalLink_" + teaching._id}
                to={`/courses/${teaching.course._id}/teachers/${teaching._id}`}
              >
                <AdditionalCourseWrapper
                  key={"AdditionalCourseWrapper_" + teaching._id}
                >
                  <AdditionalCourseTitle
                    key={"AdditionalCourseTitle_" + teaching._id}
                  >
                    {cutString(teaching.course.title, 70)}
                  </AdditionalCourseTitle>
                  <AdditionalRightSideWrapper
                    key={"AdditionalRightSideWrapper_" + teaching._id}
                  >
                    <AdditionalThumbsUpWrapper
                      key={"AdditionalThumbsUpWrapper_" + teaching._id}
                    >
                      <AdditionalThumbsUpIcon
                        key={"AdditionalThumbsUp_" + teaching._id}
                        color={"#666666"}
                      />
                      <AdditionalThumbsUpText
                        key={"AdditionalThumbsUpText_" + teaching._id}
                      >
                        {teaching.likeCount}
                      </AdditionalThumbsUpText>
                    </AdditionalThumbsUpWrapper>
                    <AdditionalThumbsDownWrapper
                      key={"AdditionalThumbsDownWrapper_" + teaching._id}
                    >
                      <AdditionalThumbsDownIcon
                        key={"AdditionalThumbsDown_" + teaching._id}
                        color={"#666666"}
                      />
                      <AdditionalThumbsDownText
                        key={"AdditionalThumbsDownText_" + teaching._id}
                      >
                        {teaching.dislikeCount}
                      </AdditionalThumbsDownText>
                    </AdditionalThumbsDownWrapper>
                    <AdditionalPrice key={"AdditionalPrice_" + teaching._id}>
                      {teaching.price}€/h
                    </AdditionalPrice>
                  </AdditionalRightSideWrapper>
                </AdditionalCourseWrapper>
              </StyledLink>
            );
          })}
      </AdditionalTeachingWrapper>
    </Container>
  );
};
